import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'uikit/Container/Container';
import i18nContext from '../../i18n-context';
import { ReactComponent as LatestIcon } from 'assets/latest-transactions.svg';

export const EmptyTransactionsList = (props) => {
  const i18n = useContext(i18nContext);
  return (
    <div className='empty-transactions'>
      <LatestIcon className='empty-transactions-icon' />
      <div className='empty-transactions-wrapper'>
        <h2 className='empty-transactions-header'>{i18n.getMessage('latestTransactions.emptyTransactions.header')}</h2>
        <p className='empty-transactions-text'>{i18n.getMessage('latestTransactions.emptyTransactions.description')}</p>
      </div>
    </div>
  );
};

EmptyTransactionsList.propTypes = {};
