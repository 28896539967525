import request from 'superagent';
import noCache from 'superagent-no-cache';

import {
  getRefreshToken,
  getAccessToken,
  saveTokenInfo,
  logoutActiveSessionWithoutToken,
  setConfirmationActionType,
  setSecureUserPhone,
  setLastUserActivityDateTime,
  resetLastUserActivityDateTime
} from './authUtils';
import { errorMapper } from './Error';

const requestAgent = request.agent().use(noCache);

export const errorState = {
  code: null,
  message: null
};

const {
  REACT_APP_API_URL_PATH_PREFIX,
  REACT_APP_API_HOST_WITH_PORT,
  REACT_APP_OAUTH_PATH,
  REACT_APP_OAUTH_URL_PATH_PREFIX,
  REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
  REACT_APP_MESSAGER_API_URL_PATH_PREFIX
} = process.env;

const TRANSPORT_DELAY = 20; // max seconds client waits for server response.
let isRefreshingToken = false;

/*
 "refreshTokenAwaiter" is a method that is used to create an artificial delay for any server calls if a request
 for refreshing the access token has been sent and a response with a new access token is expected.
 The delay step in this method is 100ms.
 The maximum number of iterations is 200, which is equal to the value of TRANSPORT_DELAY = 20sec.
 This method is handled using the "isRefreshingToken" variable.
 */
const refreshTokenAwaiter = () => {
  return new Promise((resolve) => {
    let count = 0;
    const interval = setInterval(() => {
      if (!isRefreshingToken) {
        clearInterval(interval);
        resolve();

        return;
      }
      count++;
      if (count > 200) {
        // 200 * 100ms (timeout) = 20 sec (TRANSPORT_DELAY)
        clearInterval(interval);
        resolve(errorMapper('invalid_refresh_token'));
      }
    }, 100);
  });
};

// Common method! We need to use it for all API-calls
export const api = async (args) => {
  const {
    path: urlPathPostfix,
    method = 'post',
    query = {},
    data = {},
    pathHost = REACT_APP_API_HOST_WITH_PORT,
    pathPrefix = REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth = true,
    isRequestWithBasicAuth = false,
    securityCode,
    returnFullResponse = false,
    responseType = null,
    captcha = null
  } = args;

  if (isRefreshingToken) {
    await refreshTokenAwaiter();
  }

  // eslint-disable-next-line no-console
  console.debug(
    '### API',
    method.toUpperCase(),
    'REQUEST',
    urlPathPostfix,
    ...((method === 'post' && ['\n', data]) ||
      (method === 'get' && query && Object.keys(query).length && ['\n', query]) ||
      [])
  );

  const req = requestAgent[method](pathHost + pathPrefix + urlPathPostfix);

  if (isRequestWithAuth) {
    req.auth(getAccessToken(), { type: 'bearer' });
  }

  if (isRequestWithBasicAuth) {
    req.auth('web', '');
  }

  if (responseType) {
    req.responseType(responseType);
  }

  if (query && Object.keys(query).length !== 0) {
    req.query(query);
  }

  if (method === 'post') {
    req.send(data);
  }

  if (securityCode) {
    req.set('Confirmation-Code', securityCode);
  }

  if (captcha) {
    req.set('Captcha', captcha);
  }

  setLastUserActivityDateTime();

  try {
    const res = await req; // XXX: `body` is `null` for 204 response.
    // eslint-disable-next-line no-console
    console.log('RES api:', res);
    // eslint-disable-next-line no-console
    console.debug('### API', method.toUpperCase(), 'RESPONSE', res?.body);
    return returnFullResponse ? res : res.body;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.debug('### API', method.toUpperCase(), 'ERROR', error);
    return errorMapper(error);
  }
};

/* █████████████████████ *\
 * ███ OAUTH-related ███ *
\* █████████████████████ */

let tokenTimeoutId;

const tokenize = (data) => async () => {
  if (isRefreshingToken) return;

  try {
    isRefreshingToken = true;

    const {
      body: {
        access_token: accessToken,
        refresh_token: refreshToken,
        token_type: tokenType,
        expires_in: expiresIn, // access_token life-span in seconds.
        confirmation_action_type: confirmationActionType,
        two_factor_auth: isGoogle2FAEnabled,
        phone
      }
    } = await requestAgent
      .post(REACT_APP_OAUTH_PATH + '/token')
      .type('form')
      .auth('web', '')
      .send(data);
    saveTokenInfo({ accessToken, refreshToken, tokenType, accessExpiresOn: Date.now() + expiresIn * 1000 });
    setTokenRefresh(expiresIn);
    if (confirmationActionType) {
      setConfirmationActionType(confirmationActionType);
    }
    if (phone) {
      setSecureUserPhone(phone);
    }

    return { confirmationActionType, phone, isGoogle2FAEnabled };
  } catch (error) {
    return errorMapper(error);
  } finally {
    isRefreshingToken = false;
  }
};

/**
 * @param {number} expiresIn - number of seconds after which access_token expires.
 */
export const setTokenRefresh = (expiresIn) => {
  clearTimeout(tokenTimeoutId);
  tokenTimeoutId = setTimeout(
    tokenize({
      grant_type: 'refresh_token',
      refresh_token: getRefreshToken()
    }),
    (expiresIn - TRANSPORT_DELAY) * 1000
  );
};

export const loginUser = ({ phone, password }) =>
  tokenize({
    phone,
    password,
    grant_type: 'password'
  })();

export const loginPin = (code) =>
  tokenize({
    code,
    grant_type: 'confirmation_code',
    access_token: getAccessToken()
  })();

export const invalidateToken = () =>
  api({
    path: '/revoke',
    pathHost: REACT_APP_OAUTH_PATH,
    pathPrefix: '',
    isRequestWithAuth: false,
    isRequestWithBasicAuth: true,
    query: { token: getAccessToken() }
  });

export const runLogout = async () => {
  await invalidateToken();
  resetLastUserActivityDateTime();
  clearTimeout(tokenTimeoutId);
  logoutActiveSessionWithoutToken();
};

export const enableGoogle2FARequest = async () => {
  const res = await api({
    path: '/user/2fa/enable',
    method: 'get',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX
  });

  return { ...res };
};

export const disableGoogle2FARequest = async () => {
  await api({
    path: '/user/2fa/disable',
    method: 'get',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX
  });
};

export const confirmGoogle2FARequest = async (data) => {
  await api({
    path: '/user/2fa/enable/confirm',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    data
  });
};

export const getGoogle2FAForce = async () => {
  const res = await api({
    path: '/confirmation/force/2fa',
    method: 'get',
    pathPrefix: REACT_APP_OAUTH_URL_PATH_PREFIX
  });

  return { ...res };
};

export const getSmsCodeForce = async () =>
  await api({
    path: '/confirmation/force/phone',
    method: 'get',
    pathPrefix: REACT_APP_OAUTH_URL_PATH_PREFIX
  });

/* ████████████████████████████ *\
 * ███ REGISTRATION-related ███ *
\* ████████████████████████████ */

let registrationResponseId = null;

const register = async (urlPathPostfix, data, captcha = null) => {
  try {
    const {
      body: { response_id, user_id }
    } = await requestAgent
      .post(REACT_APP_API_HOST_WITH_PORT + REACT_APP_API_URL_PATH_PREFIX + urlPathPostfix)
      .set('Captcha', captcha)
      .send(data);

    registrationResponseId = response_id;

    if (user_id) {
      return { response_id, user_id };
    } else {
      return { response_id };
    }
  } catch (error) {
    return errorMapper(error);
  }
};

export const createUser = (data, captcha) => register('/registration/user', data, captcha);

export const confirmSmsCode = (code) =>
  register('/registration/user/phone/confirm', {
    code,
    response_id: registrationResponseId
  });

export const resendPhoneRegistration = () =>
  register('/registration/user/phone/resend_code', {
    response_id: registrationResponseId
  });

export const registerAccount = (data) =>
  register('/registration/account', {
    ...data,
    response_id: registrationResponseId
  });

export const confirmEmail = (code) =>
  register('/registration/account/email/confirm', {
    code: code,
    response_id: registrationResponseId
  });

export const resendEmailRegistration = () =>
  register('/registration/account/email/resend', {
    response_id: registrationResponseId
  });

export const createAccount = async (data) => {
  return api({
    path: '/account/create',
    data
  });
};

export const confirmAccountEmail = (code, id) => {
  return api({
    path: '/account/create/email_confirmation/code',
    data: {
      response_id: id,
      code: code
    }
  });
};

export const resendCodeAccountEmail = (id) => {
  return api({
    path: '/api/account/create/email_confirmation/resend_code',
    data: { response_id: id }
  });
};

/* ████████████████ *\
 * ███ USER-API ███ *
\* ████████████████ */

export const getUserInformation = async () => {
  return api({
    path: '/user/new',
    method: 'get'
  });
};

export const getUserInfoByAccNumber = async (accountNumber) => {
  return api({
    path: `/account/${accountNumber}`,
    method: 'get'
  });
};

/* ███████████████████ *\
 * ███ WALLETS-API ███ *
\* ███████████████████ */

export const getWallets = async (accountNumber) => {
  return api({
    path: `/account/${accountNumber}/wallet`,
    method: 'get',
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
  });
};

/* █████████████████████████ *\
 * ███ VERIFICATION CODE ███ *
\* █████████████████████████ */

export const resendAuthorizationSecurityCode = () => {
  api({
    path: '/confirmation/resend',
    method: 'get',
    pathHost: REACT_APP_OAUTH_PATH,
    pathPrefix: ''
  });
};

export const generateSecurityCode = () =>
  api({
    path: '/confirmation/generate',
    method: 'get'
  });

export const resendSecurityCode = () =>
  api({
    path: '/confirmation/resend',
    method: 'get'
  });

/* ███████████████████████████*\
 * ███ RESET USER PASSWORD ███ *
\* ███████████████████████████ */

export const passwordReset = (data) =>
  api({
    path: '/user/change_password',
    data
  });

/* █████████████████████████ *\
 * ███ PASSWORD RECOVERY ███ *
\* █████████████████████████ */

export const passwordRecoveryPhone = (phone) => {
  return api({
    path: '/password_recovery/request',
    isRequestWithAuth: false,
    data: { phone: phone }
  });
};

export const passwordRecoveryPhoneConfirm = (id, code) => {
  return api({
    path: '/password_recovery/phone/confirm',
    isRequestWithAuth: false,
    data: { response_id: id, code: code }
  });
};

export const passwordRecoveryPhoneResend = (id) => {
  return api({
    path: '/password_recovery/phone/resend',
    isRequestWithAuth: false,
    data: { response_id: id }
  });
};

export const passwordRecoveryEmailCheck = (id, email) => {
  return api({
    path: '/password_recovery/email',
    isRequestWithAuth: false,
    data: { response_id: id, email: email }
  });
};

export const passwordRecoveryEmailConfirm = (id, code) => {
  return api({
    path: '/password_recovery/email/confirm',
    isRequestWithAuth: false,
    data: { response_id: id, code: code }
  });
};

export const passwordRecoveryEmailResend = (id) => {
  return api({
    path: '/password_recovery/email/resend',
    isRequestWithAuth: false,
    data: { response_id: id }
  });
};

export const changePasswordUser = (data) =>
  api({
    path: '/password_recovery/set_password',
    isRequestWithAuth: false,
    data
  });

export const passwordRecoveryRequest = (data) =>
  api({
    path: '/password_recovery',
    isRequestWithAuth: false,
    data
  });

export const generatePasswordRecoverySecurityCode = (responseId) =>
  api({
    path: '/password_recovery/request_confirmation',
    isRequestWithAuth: false,
    data: { response_id: responseId }
  });

export const fetchRecoveryLink = (tokenId) =>
  api({
    path: `/password_recovery/email/confirm/${tokenId}`,
    method: 'get',
    isRequestWithAuth: false
  });

/* ████████████████████████ *\
 * ███ TRANSACTIONS-API ███ *
\* ████████████████████████ */

export const getTransactionList = (accountNumber, filter = {}) =>
  api({
    path: `/account/${accountNumber}/transaction`,
    method: 'get',
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    query: filter
  });

export const getDocumentsVerificationTransactionList = (accountNumber) =>
  api({
    path: `/account/${accountNumber}/transaction/documents_processing`,
    method: 'get',
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
  });

const downloadFile = (response, isPreview = false, filename) => {
  if (!filename && response.headers['content-disposition']) {
    const parts = response.headers['content-disposition'].split('filename=');
    if (parts.length === 2) {
      // eslint-disable-next-line no-param-reassign
      filename = parts[1].replace(/"/g, '');
    }
  }

  const url = URL.createObjectURL(response.body);

  if (isPreview) {
    return url;
  }

  const downloadLink = document.createElement('a');
  downloadLink.style.display = 'none';
  downloadLink.setAttribute('download', filename || 'downloadedFile');
  downloadLink.href = url;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const exportCurrentPDF = async (accountNumber, transactionNumber, type) => {
  const response = await api({
    path: `/account/${accountNumber}/transaction/${type.toLowerCase()}/${transactionNumber}/report`,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    method: 'get',
    responseType: 'blob',
    returnFullResponse: true
  });

  downloadFile(response);
};

export const exportPDFList = async (accountNumber, query) => {
  const response = await api({
    path: `/account/${accountNumber}/wallet/review`,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    method: 'get',
    query,
    responseType: 'blob',
    returnFullResponse: true
  });

  downloadFile(response);
};

export const exportTransactionListCSV = async (accountNumber, query) => {
  const response = await api({
    path: `/account/${accountNumber}/transaction/export/csv`,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    method: 'get',
    query,
    responseType: 'blob',
    returnFullResponse: true
  });

  downloadFile(response);
};

export const uploadDocumentsToTransaction = async (accountNumber, transactionNumber, file) => {
  return api({
    path: `/account/${accountNumber}/document/${transactionNumber}`,
    data: file,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
  });
};

/* ██████████████████████████████████ *\
 * ███ APPLICATION-STATE_FROM-API ███ *
\* ██████████████████████████████████*/

export const getApplicationState = () =>
  api({
    path: '/application/state',
    method: 'get'
  });

/* ███████████████████ *\
 * ███ PAYMENT-API ███ *
\* ███████████████████ */

export const transactionPaymentCheck = async (accountNumber, data) => {
  const res = await api({
    path: `/account/${accountNumber}/transaction/check`,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    data
  });

  return {
    ...res,
    comment: data.comment
  };
};

export const transactionPaymentConfirm = async (account_number, data) => {
  const res = await api({
    path: `/account/${account_number}/transaction/create`,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    data
  });

  return { ...res };
};

export const transactionPaymentVerify = async (account_number, transaction_number, data) => {
  const res = await api({
    path: `/account/${account_number}/transaction/${transaction_number}/verify`,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    data
  });

  return {
    ...res,
    comment: data.comment
  };
};

export const internalTransactionCheck = async (account_number, data) =>
  await api({
    path: `/account/${account_number}/transaction/internal/check`,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    data
  });

export const internalTransactionCreate = async (account_number, data) =>
  await api({
    path: `/account/${account_number}/transaction/internal/create`,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    data
  });

export const transactionPaymentVerificationCodeResend = async (account_number, transaction_number) => {
  const res = await api({
    path: `/account/${account_number}/transaction/${transaction_number}/verification_code`,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    method: 'get'
  });

  return { ...res };
};

export const uploadDocuments = (accountNumber, file) => {
  return api({
    path: `/account/${accountNumber}/document`,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    data: file
  });
};

export const internalIbanValidate = (walletNumber, iban) => {
  return api({
    path: '/iban/validate',
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    data: {
      sender_wallet_number: walletNumber,
      recipient_iban: iban
    }
  });
};

/* ██████████████████ *\
 * ███ SUMSUB-API ███ *
\* ██████████████████ */

export const getSumsubAccessToken = (accountNumber, levelName) => {
  return api({
    path: '/account/sumsub/token/generate',
    data: {
      levelName: levelName,
      accountNumber: accountNumber
    }
  });
};

/* ████████████████ *\
 * ███ FEES-API ███ *
\* ████████████████ */

export const getTariffs = async (accountNumber, walletNumber, transactional = '') => {
  return api({
    // eslint-disable-next-line max-len
    path: `${
      transactional
        ? `/account/${accountNumber}/tariffs?wallet_number=${walletNumber}&transactional=${transactional}`
        : `/account/${accountNumber}/tariffs?&transactional=${transactional}`
    }`,
    method: 'get'
  });
};

export const setUnderReviewStatus = (accountNumber) => {
  return api({ path: `/account/${accountNumber}/kyc/under_review` });
};

/* █████████████████████████████ *\
 * ███ CURRENCY-EXCHANGE-API ███ *
\* █████████████████████████████ */

export const getCurrencyExchangeRate = async ({ from, to }) => {
  return api({
    path: `/currency_rate/${from}/${to}`,
    method: 'get',
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
  });
};

export const exchange = (accountNumber, data) =>
  api({
    path: `/account/${accountNumber}/transaction/exchange/check`,
    data,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
  });

export const exchangeConfirm = (accountNumber, { securityCode, ...data }) =>
  api({
    path: `/account/${accountNumber}/transaction/exchange/create`,
    data,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    securityCode: securityCode
  });

/* ███████████████████████████ *\
 * ███ REPRESENTATIVES-API ███ *
\* ███████████████████████████ */

export const getCandidateInfo = (email) =>
  api({
    path: '/account/info',
    data: { email }
  });

export const addRepresentative = ({ accountNumber, email, permissions, securityCode }) =>
  api({
    path: `/account/representative/${accountNumber}/add`,
    data: {
      representative_email: email,
      permissions
    },
    securityCode
  });

export const removeRepresentative = ({ accountNumber, id, securityCode }) =>
  api({
    path: `/account/representative/${accountNumber}/remove/${id}`,
    securityCode: securityCode
  });

export const updateRepresentative = ({ accountNumber, id, permissions, securityCode }) =>
  api({
    path: `/account/representative/${accountNumber}/update/${id}`,
    data: permissions,
    securityCode: securityCode
  });

/* █████████████████████████████ *\
 * ███ ACTIVITY-LOG-API ███ *
\* █████████████████████████████ */

export const getLoginAttempts = async (filter) =>
  api({
    path: '/login_attempt',
    method: 'get',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    query: filter
  });

/* ██████████████████████████ *\
 * ███ Messager Controller███ *
\* ██████████████████████████ */

export const getMessageList = async (accountNumber, filter) => {
  try {
    return await api({
      pathPrefix: REACT_APP_MESSAGER_API_URL_PATH_PREFIX,
      path: `/account/${accountNumber}/topic`,
      method: 'get',
      query: filter
    });
  } catch (err) {
    return await errorMapper(err);
  }
};

export const getTopicMessages = async (accountNumber, topicId, query = {}) => {
  try {
    return await api({
      pathPrefix: REACT_APP_MESSAGER_API_URL_PATH_PREFIX,
      path: `/account/${accountNumber}/topic/${topicId}/message`,
      method: 'get',
      query
    });
  } catch (err) {
    return await errorMapper(err);
  }
};

export const createMessage = async (accountNumber, topicId, securityCode, data) => {
  try {
    return await api({
      pathPrefix: REACT_APP_MESSAGER_API_URL_PATH_PREFIX,
      path: `/account/${accountNumber}/topic/${topicId}/message`,
      data,
      securityCode: securityCode
    });
  } catch (err) {
    return await errorMapper(err, {
      body: 'message'
    });
  }
};

export const createTopic = async (accountNumber, securityCode, data) => {
  try {
    return await api({
      pathPrefix: REACT_APP_MESSAGER_API_URL_PATH_PREFIX,
      path: `/account/${accountNumber}/topic`,
      method: 'post',
      data,
      securityCode
    });
  } catch (err) {
    return await errorMapper(err, {
      messageBody: 'message'
    });
  }
};

export const downloadMessageDocument = async (accountNumber, documentId, isPreview) => {
  try {
    return await api({
      path: `/account/${accountNumber}/document/${documentId}`,
      pathPrefix: REACT_APP_MESSAGER_API_URL_PATH_PREFIX,
      method: 'get',
      responseType: 'blob',
      returnFullResponse: true
    }).then((res) => {
      if (isPreview) {
        return downloadFile(res, isPreview);
      } else {
        downloadFile(res);
      }
    });
  } catch (err) {
    return errorMapper(err);
  }
};

export const uploadMessageDocument = (accountNumber, file) => {
  return api({
    path: `/account/${accountNumber}/document`,
    pathPrefix: REACT_APP_MESSAGER_API_URL_PATH_PREFIX,
    data: file
  });
};

/* █████████████████*\
 * ███ CARDS-API ███ *
\* █████████████████ */

export const getCardsList = async (accountNumber) => {
  return await api({
    path: `/account/${accountNumber}/card/list`,
    method: 'get',
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
  });
};

export const createCardRequest = async (accountNumber, data) => {
  const res = await api({
    path: `/account/${accountNumber}/card/create`,
    data,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
  });

  return { ...res };
};

export const confirmCardCreation = async (account_number, data) =>
  await api({
    path: `/account/${account_number}/card/create/confirm`,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    data
  });

export const resendConfirmationCode = async (uid) =>
  await api({
    path: `/confirmation/${uid}/resend`,
    method: 'get',
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
  });

export const getCardNumberRequest = async (account_number, card_id, data) =>
  await api({
    path: `/account/${account_number}/card/${card_id}/number`,
    data,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
  });

export const getCardCVVRequest = async (account_number, card_id, data) =>
  await api({
    path: `/account/${account_number}/card/${card_id}/cvv`,
    data,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
  });

export const getCardAuthorizationsListRequest = async (account_number, filters = {}) =>
  await api({
    path: `/account/${account_number}/card/authorization/list`,
    method: 'get',
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    query: filters
  });

export const blockCardRequest = async (account_number, card_id) => {
  return await api({
    path: `/account/${account_number}/card/${card_id}/block`,
    method: 'get',
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
  });
};

export const unblockCardRequest = async (account_number, card_id) => {
  return await api({
    path: `/account/${account_number}/card/${card_id}/unblock`,
    method: 'get',
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
  });
};
