import { useContext } from 'react';
import PropTypes from 'prop-types';
import i18nContext from '../i18n-context';
import { ReactComponent as BankIcon } from 'assets/bank-icon.svg';
import Button from 'uikit/Button/Button';

export const Individual = ({ handleOpenVerification, isUnderReview }) => {
  const i18n = useContext(i18nContext);

  const handleClick = () => {
    if (isUnderReview) {
      location.href = 'mailto:' + process.env.REACT_APP_SUPPORT_EMAIL;
    } else {
      handleOpenVerification('basic-kyc-level');
    }
  };

  return (
    <div className='verification'>
      <BankIcon className='bank-icon svg-bank-icon' />
      <div className='bank-wrapper'>
        <h1 className='bank-header'>
          {isUnderReview
            ? i18n.getMessage('verification.kyc.underReview.title')
            : i18n.getMessage(process.env.REACT_APP_KYC_TITLE_INDIVIDUAL)}
        </h1>
        <p className='bank-description'>
          {isUnderReview
            ? i18n.getMessage('verification.kyc.underReview.description')
            : i18n.getMessage(process.env.REACT_APP_KYC_DESCRIPTION_INDIVIDUAL)}
        </p>
        {isUnderReview && (
          <p className='bank-description'>
            {i18n.getMessage('verification.kyc.underReview.support', {
              appTitle: process.env.REACT_APP_PAYMENT_INSTITUTION
            })}
          </p>
        )}
        <Button
          type={'primary'}
          roleType={'submit'}
          className={isUnderReview ? 'btn-size' : ''}
          size={'large'}
          onClick={handleClick}
        >
          {isUnderReview
            ? i18n.getMessage('verification.button.contactUs')
            : i18n.getMessage(process.env.REACT_APP_KYC_BUTTON_TEXT)}
        </Button>
      </div>
    </div>
  );
};

Individual.propTypes = {
  handleOpenVerification: PropTypes.func.isRequired,
  isUnderReview: PropTypes.bool
};
