/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import i18nContext from 'components/i18n-context';
import BottomPartForm from './BottomPartForm';
import { clearJunctionModel } from './Models';
import clearJunctionValidation from './ModelValidators/clearJunctionValidation';
import { ReactComponent as LocalIcon } from 'assets/payments.svg';
import { ReactComponent as IbanIcon } from 'assets/payment-advanced.svg';
import { CURRENCY_TYPES, PAYMENT_METHOD, RECIPIENT_TYPES } from 'components/common/constants';
import { amountFormattedValue, formatSortCode, prepareFieldErrors } from 'services/utils';
import { IconButton } from 'uikit/IconButton/IconButton';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';

const LOCAL_TAB = 'LOCAL_ACCOUNT';
const IBAN_TAB = 'IBAN';

const CleatJunctionTable = ({
  accountNumber,
  getCommission,
  uploadDocuments,
  setIban,
  ibanCredentials,
  clearIbanCredentials,
  wallet,
  isRepeatPayment,
  checkIban,
  isIbanCheckLoading,
  paymentMethod,
  resetPaymentMethods,
  uploadedFiles,
  commission,
  removePaymentFile,
  error,
  setError,
  isCommissionLoading,
  isLoading,
  isSuccess,
  currency,
  validationProps
}) => {
  const i18n = useContext(i18nContext);
  const model = clearJunctionModel;
  const validationSchema = clearJunctionValidation({ ...validationProps, model });
  const serverFieldsErrors = prepareFieldErrors(i18n, error);
  const [disabled, setDisabled] = useState(true);
  const [showMethodCommission, setShowMethodCommission] = useState(false);
  const form = useFormik(validationSchema);
  const { values, errors, handleSubmit, handleChange, validateForm, resetForm, setFieldValue, setErrors } = form;
  const [activeTab, setActiveTab] = useState(
    currency === CURRENCY_TYPES.GBP && !values.recipient_wallet ? LOCAL_TAB : IBAN_TAB
  );

  useEffect(() => {
    if (isRepeatPayment) {
      handleIbanComplete();
    } else {
      resetForm();
    }
  }, [wallet.wallet_number]);

  useEffect(() => {
    if ((!isIbanCheckLoading && ibanCredentials) || (isRepeatPayment && !values.recipient_wallet)) {
      handleGetCommission();
    }
  }, [isIbanCheckLoading, ibanCredentials, isRepeatPayment]);

  useEffect(() => {
    if (isSuccess) {
      resetForm({});
      setDisabled(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (paymentMethod === PAYMENT_METHOD.INTERNAL && activeTab === LOCAL_TAB) {
      setFieldValue('account_number', '');
      setFieldValue('sort_code', '');
      delete errors['account_number'];
      delete errors['sort_code'];
      setErrors(errors);
      setActiveTab(IBAN_TAB);
    }
  }, [paymentMethod]);

  const handleGetCommission = async () => {
    if (
      !serverFieldsErrors?.iban &&
      (values.recipient_wallet || (values.account_number && values.sort_code)) &&
      values.amount &&
      values.recipient_type &&
      values.comment &&
      (values.recipient_type === RECIPIENT_TYPES.COMPANY ? values.company_name : values.first_name && values.last_name)
    ) {
      const errorsResult = await validateForm();
      if (!Object.keys(errorsResult).length) {
        await getCommission(
          accountNumber,
          model({
            providerTableData: values,
            data: { wallet, paymentMethod, ...ibanCredentials }
          })
        );
        setShowMethodCommission(true);
        setDisabled(false);
      }
    }
  };

  const handleRecipientTypeChange = (name, data) => handleChange({ target: { name, value: data } });
  const onAmountIBANChange = (e) => {
    setShowMethodCommission(false);
    handleChange(e);
    if (e.target.name === 'recipient_wallet') {
      setIban(e.target.value);
    }
  };

  const getCommissionValue = () => {
    if (!commission.value && !commission.currency && !commission.type) {
      return '0.00';
    }
    return `${amountFormattedValue(commission.value)} ${commission.currency}`;
  };

  const handleActiveTab = (activeTab) => {
    if (ibanCredentials) clearIbanCredentials();
    if (error) setError(null);
    if (!paymentMethod) resetPaymentMethods();
    if (activeTab === LOCAL_TAB) {
      setFieldValue('recipient_wallet', '');
      delete errors['recipient_wallet'];
    }
    if (activeTab === IBAN_TAB) {
      setFieldValue('account_number', '');
      setFieldValue('sort_code', '');
      delete errors['account_number'];
      delete errors['sort_code'];
    }
    setErrors(errors);
    setActiveTab(activeTab);
  };

  const onSortCodeChange = (e) => {
    let inputValue = e.target.value;
    let numericValue = formatSortCode(inputValue);
    setFieldValue('sort_code', numericValue);
  };

  const handleIbanComplete = async () => {
    values.recipient_wallet && (await checkIban(values.recipient_wallet));
  };

  const bottomPartProps = {
    values,
    errors,
    error,
    handleChange,
    handleSubmit,
    serverFieldsErrors,
    onAmountIBANChange,
    handleGetCommission,
    showMethodCommission,
    getCommissionValue,
    paymentMethod,
    paymentProvider: wallet.transfer_provider,
    isSubmitDisabled:
      disabled ||
      isLoading ||
      isCommissionLoading ||
      !!Object.keys(errors).length ||
      !!Object.keys(serverFieldsErrors).length ||
      !paymentMethod,
    isLoading: isLoading || isCommissionLoading,
    uploadedFiles,
    uploadDocuments,
    removePaymentFile
  };

  return (
    <>
      {currency === CURRENCY_TYPES.GBP && paymentMethod !== PAYMENT_METHOD.INTERNAL && (
        <div className='navigation-payment'>
          <IconButton
            className='navigation-payment-button'
            text={i18n.getMessage('transfer.divider.simpleForm')}
            active={activeTab === LOCAL_TAB}
            isDisabled={paymentMethod === PAYMENT_METHOD.INTERNAL}
            onClick={() => handleActiveTab(LOCAL_TAB)}
            Icon={LocalIcon}
          />
          <IconButton
            className='navigation-payment-button'
            text={i18n.getMessage('transfer.label.iban')}
            active={activeTab === IBAN_TAB}
            onClick={() => handleActiveTab(IBAN_TAB)}
            Icon={IbanIcon}
          />
        </div>
      )}
      {(activeTab === IBAN_TAB || wallet.currency === CURRENCY_TYPES.EUR) && (
        <div className='inputs-wrapper'>
          <Input
            isRequired={true}
            isDisabled={!!values.account_number || !!values.sort_code}
            label={i18n.getMessage('transfer.form.recipientIban.label')}
            name={'recipient_wallet'}
            value={values.recipient_wallet}
            error={(!values.account_number && errors.recipient_wallet) || serverFieldsErrors.iban}
            onChange={onAmountIBANChange}
            onBlur={handleIbanComplete}
            subText={
              ibanCredentials?.bank_name &&
              i18n.getMessage('transfer.form.bankName.subText', { bankName: ibanCredentials?.bank_name })
            }
          />
        </div>
      )}
      {currency === CURRENCY_TYPES.GBP && activeTab === LOCAL_TAB && (
        <div className='inputs-wrapper-evenly'>
          <Input
            isRequired={true}
            isDisabled={!!values.recipient_wallet}
            name={'account_number'}
            label={i18n.getMessage('transfer.form.accountNumberGBP.label')}
            value={values.account_number}
            error={errors.account_number || serverFieldsErrors.account_number}
            onChange={handleChange}
            onBlur={handleGetCommission}
            max={8}
          />
          <Input
            isRequired={true}
            isDisabled={!!values.recipient_wallet}
            name={'sort_code'}
            label={i18n.getMessage('transfer.form.sortCode.label')}
            value={values.sort_code}
            error={errors.sort_code || serverFieldsErrors.sort_code}
            onChange={onSortCodeChange}
            onBlur={handleGetCommission}
            max={8}
          />
        </div>
      )}
      <div className={`inputs-wrapper ${values.recipient_type}`}>
        <InputDropDown
          label={i18n.getMessage('transfer.form.recipientType.label')}
          name={'recipient_type'}
          value={values.recipient_type}
          onChange={handleRecipientTypeChange}
          options={Object.values(RECIPIENT_TYPES).map((value) => ({ key: value, value }))}
        />
        {values.recipient_type === RECIPIENT_TYPES.COMPANY && (
          <Input
            isRequired={true}
            label={i18n.getMessage('transfer.form.companyName.label')}
            name={'company_name'}
            value={values.company_name}
            error={errors.company_name || serverFieldsErrors.companyName}
            onChange={handleChange}
            onBlur={handleGetCommission}
          />
        )}
        {values.recipient_type === RECIPIENT_TYPES.INDIVIDUAL && (
          <Input
            isRequired={true}
            label={i18n.getMessage('transfer.form.firstName.label')}
            name={'first_name'}
            value={values.first_name}
            error={errors.first_name || serverFieldsErrors.firstName}
            onChange={handleChange}
            onBlur={handleGetCommission}
          />
        )}
        {values.recipient_type === RECIPIENT_TYPES.INDIVIDUAL && (
          <Input
            isRequired={true}
            label={i18n.getMessage('transfer.form.lastName.label')}
            name={'last_name'}
            value={values.last_name}
            error={errors.last_name || serverFieldsErrors.lastName}
            onChange={handleChange}
            onBlur={handleGetCommission}
          />
        )}
      </div>
      <BottomPartForm {...bottomPartProps} />
    </>
  );
};

CleatJunctionTable.propTypes = {
  accountNumber: PropTypes.string,
  getCommission: PropTypes.func.isRequired,
  uploadDocuments: PropTypes.func.isRequired,
  setIban: PropTypes.func,
  ibanCredentials: PropTypes.object,
  clearIbanCredentials: PropTypes.func,
  checkIban: PropTypes.func.isRequired,
  isRepeatPayment: PropTypes.bool,
  isIbanCheckLoading: PropTypes.bool,
  wallet: PropTypes.object,
  paymentMethod: PropTypes.string,
  resetPaymentMethods: PropTypes.func,
  commission: PropTypes.object,
  isCommissionLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.any,
  setError: PropTypes.func,
  uploadedFiles: PropTypes.array,
  removePaymentFile: PropTypes.func,
  isSuccess: PropTypes.bool,
  currency: PropTypes.string,
  validationProps: PropTypes.object.isRequired
};

export default CleatJunctionTable;
