import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { Link } from 'react-router-dom';
import { ROUTE_PATHS } from 'routes/constants';
import TransactionsList from './TransactionsList';
import { EmptyTransactionsList } from './EmptyTransactionsList';
import i18nContext from 'components/i18n-context';
import { PAYMENT_PROVIDERS } from 'components/common/constants';
import { Container } from 'uikit/Container/Container';
import './LatestTransactions.scss';

const LatestTransactionsCompact = ({
  accountNumber,
  transactionsStore,
  transferSuccessful,
  isCardsTransactions = false,
  walletNumber
}) => {
  const i18n = useContext(i18nContext);
  useEffect(() => {
    if (accountNumber || transferSuccessful) {
      if (isCardsTransactions) {
        transactionsStore.getLatestTransactions(accountNumber, {
          transfer_types: PAYMENT_PROVIDERS.WALLESTER,
          wallets: walletNumber
        });
      } else {
        transactionsStore.getLatestTransactions(accountNumber);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountNumber, transferSuccessful, walletNumber]);

  return (
    <section className='right-section latest-transactions'>
      <Container className='transactions-container' header={i18n.getMessage('container.latestTransactions')}>
        {transactionsStore.transactionList.length ? (
          <>
            <Link className='link-normal transaction-link' to={ROUTE_PATHS.TRANSACTIONS}>
              {i18n.getMessage('latestTransactions.link.transactions')}
            </Link>
            <TransactionsList
              isLoading={transactionsStore.isLoading}
              compact
              transactions={transactionsStore?.transactionList}
            />
          </>
        ) : (
          <EmptyTransactionsList />
        )}
      </Container>
    </section>
  );
};

LatestTransactionsCompact.propTypes = {
  accountNumber: PropTypes.string,
  compact: PropTypes.bool,
  transferSuccessful: PropTypes.bool,
  isCardsTransactions: PropTypes.bool,
  walletNumber: PropTypes.string,
  transactionsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  accountNumber: stores.userStore.userData.account?.account_number,
  transactionsStore: stores.transactionsStore,
  transferSuccessful: stores.paymentStore.isSuccess
}))(observer(LatestTransactionsCompact));
