/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import { ReactComponent as SimpleIcon } from 'assets/simple-icon.svg';
import i18nContext from 'components/i18n-context';
import AmountInput from 'components/common/AmountInput';
import { ContactUs } from 'components/common/ContactUs/ContactUs';
import PopUpSuccessScheme from 'components/common/PopUpScheme/PopUpSuccessScheme';
import TransferConfirmationScheme from 'components/common/PopUpScheme/TransferConfirmationScheme';
import { getWalletsOptionsForSelect } from 'components/utils';
import { useMediaQuery } from 'services/useMediaQUery';
import { amountFormattedValue, findWalletByCurrency, MEDIA_QUERY, prepareFieldErrors } from 'services/utils';
import Button from 'uikit/Button/Button';
import { Container } from 'uikit/Container/Container';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import { PopUp } from 'uikit/PopUp/PopUp';
import './BetweenAccounts.scss';

const BetweenAccounts = ({ userWallets, accountNumber, topUpWallet, betweenAccountsStore, userStore }) => {
  const i18n = useContext(i18nContext);
  const isMobile = useMediaQuery(MEDIA_QUERY.MOBILE);

  const getServerFieldsErrors = (variable) => prepareFieldErrors(i18n, betweenAccountsStore.error, variable);

  useEffect(() => {
    return () => {
      betweenAccountsStore.resetStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!betweenAccountsStore.isInitialized && userWallets.length > 0) {
      if (topUpWallet) {
        handleChangeWalletTo('walletTo', topUpWallet?.wallet_number);
      } else {
        const walletFrom = findWalletByCurrency({ wallets: userWallets });
        const walletTo = userWallets.find(
          (wallet) => wallet?.wallet_number !== walletFrom?.wallet_number && wallet?.currency === walletFrom?.currency
        );
        betweenAccountsStore.setFromToWallets(walletFrom, walletTo);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userWallets, betweenAccountsStore.isInitialized]);

  useEffect(() => {
    if (betweenAccountsStore.isInternalTransferCompleted) {
      userStore.getUserWallets();
      betweenAccountsStore.setIsInternalTransferCompleted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [betweenAccountsStore.isInternalTransferCompleted]);

  const handleChangeWalletFrom = (name, value) => {
    const selectedWallet = userWallets.find((wallet) => wallet?.wallet_number === value);
    const walletTo = userWallets.find((wallet) =>
      betweenAccountsStore.walletTo?.wallet_number === selectedWallet?.wallet_number ||
      betweenAccountsStore.walletTo?.currency !== selectedWallet?.currency
        ? wallet?.wallet_number !== selectedWallet?.wallet_number && wallet?.currency === selectedWallet?.currency
        : wallet?.wallet_number === betweenAccountsStore.walletTo?.wallet_number
    );
    betweenAccountsStore.setFromToWallets(selectedWallet, walletTo);
  };

  const handleChangeWalletTo = (name, value) => {
    const selectedWallet = userWallets.find((wallet) => wallet?.wallet_number === value);
    const walletFrom = userWallets.find((wallet) =>
      betweenAccountsStore.walletFrom?.wallet_number === selectedWallet?.wallet_number ||
      betweenAccountsStore.walletFrom?.currency !== selectedWallet?.currency
        ? wallet?.wallet_number !== selectedWallet?.wallet_number && wallet?.currency === selectedWallet?.currency
        : wallet?.wallet_number === betweenAccountsStore.walletFrom?.wallet_number
    );

    betweenAccountsStore.setFromToWallets(walletFrom, selectedWallet);
  };

  const handleAmountChange = ({ target: { name, value } }) => {
    betweenAccountsStore.setAmount(value);
  };

  const getAvailableBalance = (wallet) =>
    wallet &&
    i18n.getMessage('sendMoney.topLabel', {
      available: amountFormattedValue(wallet.available),
      currency: wallet.currency
    });

  const walletsOptions = getWalletsOptionsForSelect(userWallets);

  const handleChangeDescription = (event) => {
    betweenAccountsStore.setDescription(event.target.value);
  };

  const isBalanceExceeded = (amount, balance) => {
    return amount > balance;
  };

  const handleCheckInternalTransaction = async () => {
    if (betweenAccountsStore.amount && betweenAccountsStore.walletFrom && betweenAccountsStore.walletTo) {
      if (isBalanceExceeded(Number(betweenAccountsStore.amount), betweenAccountsStore?.walletFrom?.available)) {
        betweenAccountsStore.setError({ fields: { amount: 'balanceExceeded' } });
      } else {
        betweenAccountsStore.setError(null);
        await betweenAccountsStore?.checkInternalTransaction(accountNumber);
      }
    }
  };

  const handleVerifyInternalTransaction = async (securityCode) => {
    await betweenAccountsStore.verifyInternalTransaction(accountNumber, securityCode);
  };

  const handleCreateInternalTransaction = async () => {
    await betweenAccountsStore?.checkInternalTransaction(accountNumber);
    betweenAccountsStore?.setIsInternalTransactionCreated(true);
  };

  return (
    <>
      {userWallets.length === 0 ? (
        <ContactUs
          icon={<SimpleIcon />}
          header={i18n.getMessage('betweenAccounts.noWallets.title')}
          description={i18n.getMessage('betweenAccounts.noWallets.description')}
          link={process.env.REACT_APP_SUPPORT_EMAIL}
          button={i18n.getMessage('betweenAccounts.noWallets.button')}
        />
      ) : (
        <section className='right-section'>
          <Container
            className={!isMobile ? 'container-web' : ''}
            header={i18n.getMessage('betweenAccounts.container.label')}
            isMaxWidthUnset={true}
          >
            <div className='between-accounts-wrapper'>
              <div className='between-accounts-inputs-wrapper'>
                <InputDropDown
                  isRequired={true}
                  name='walletFrom'
                  topLabel={getAvailableBalance(betweenAccountsStore.walletFrom)}
                  value={betweenAccountsStore.walletFrom?.wallet_number}
                  currency={betweenAccountsStore.walletFrom?.currency}
                  options={walletsOptions}
                  onChange={handleChangeWalletFrom}
                  label={i18n.getMessage('betweenAccounts.form.from')}
                  isMulti={false}
                  error={!betweenAccountsStore.walletFrom ? betweenAccountsStore.error : ''}
                />
                <InputDropDown
                  isRequired={true}
                  name='walletTo'
                  topLabel={getAvailableBalance(betweenAccountsStore.walletTo)}
                  value={betweenAccountsStore.walletTo?.wallet_number}
                  currency={betweenAccountsStore.walletTo?.currency}
                  options={walletsOptions}
                  onChange={handleChangeWalletTo}
                  label={i18n.getMessage('betweenAccounts.form.to')}
                  isMulti={false}
                  error={!betweenAccountsStore.walletTo ? betweenAccountsStore.error : ''}
                />
                <AmountInput
                  isRequired={true}
                  label={i18n.getMessage('betweenAccounts.form.amount')}
                  name='amount'
                  value={betweenAccountsStore.amount}
                  onChange={handleAmountChange}
                  placeholder={i18n.getMessage('betweenAccounts.input.amount.placeholder')}
                  error={getServerFieldsErrors(amountFormattedValue(betweenAccountsStore.exchangeRate?.min)).amount}
                  onBlur={handleCheckInternalTransaction}
                />
                <Input
                  type='textarea'
                  className='between-accounts-description-input'
                  name='description'
                  label={i18n.getMessage('betweenAccounts.input.description.label')}
                  placeholder={i18n.getMessage('betweenAccounts.input.description.placeholder')}
                  value={betweenAccountsStore.description}
                  onChange={handleChangeDescription}
                  rows={3}
                />
              </div>
              <div className='between-accounts-action-wrapper'>
                <Button
                  type='primary'
                  roleType='button'
                  size='large'
                  isDisabled={
                    !betweenAccountsStore.isInternalTransactionChecked ||
                    betweenAccountsStore.isLoading ||
                    betweenAccountsStore.error
                  }
                  onClick={handleCreateInternalTransaction}
                >
                  {i18n.getMessage('betweenAccounts.button.transfer')}
                </Button>
              </div>
            </div>
          </Container>
          <PopUp
            className={betweenAccountsStore.isShowSuccessPopUp ? 'transaction-success' : 'transaction-info'}
            show={betweenAccountsStore.isInternalTransactionCreated || betweenAccountsStore.isShowSuccessPopUp}
            alignOnCenter={true}
            onClose={
              betweenAccountsStore.isShowSuccessPopUp
                ? () => betweenAccountsStore.resetStore()
                : () => betweenAccountsStore.setIsInternalTransactionCreated(false)
            }
          >
            {betweenAccountsStore.isShowSuccessPopUp ? (
              <PopUpSuccessScheme
                onClose={() => betweenAccountsStore.resetStore()}
                message={i18n.getMessage('popUp.message.moneyTransfer')}
              />
            ) : (
              <TransferConfirmationScheme
                isBetweenAccounts={true}
                transferData={{
                  from: betweenAccountsStore.walletFrom?.wallet_number,
                  to: betweenAccountsStore.walletTo?.wallet_number,
                  amount: betweenAccountsStore.amount,
                  currency: betweenAccountsStore.walletFrom?.currency || betweenAccountsStore.walletTo?.currency,
                  description: betweenAccountsStore.description
                }}
                error={betweenAccountsStore?.confirmationPopupError}
                generateSecurityCode={() => betweenAccountsStore.createInternalTransaction(accountNumber)}
                resendSecurityCode={() => betweenAccountsStore.resendTransactionSecurityCode()}
                clearError={() => betweenAccountsStore?.clearConfirmationPopupError()}
                onConfirm={handleVerifyInternalTransaction}
                onClose={() => betweenAccountsStore.setIsInternalTransactionCreated(false)}
              />
            )}
          </PopUp>
        </section>
      )}
    </>
  );
};

BetweenAccounts.propTypes = {
  userWallets: PropTypes.array,
  accountNumber: PropTypes.string,
  topUpWallet: PropTypes.object,
  betweenAccountsStore: MobXPropTypes.observableObject,
  userStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  userWallets: stores.userStore?.userWallets,
  accountNumber: stores.userStore.userData.account?.account_number,
  topUpWallet: stores.cardsStore.topUpWallet,
  betweenAccountsStore: stores.betweenAccountsStore,
  userStore: stores.userStore
}))(observer(BetweenAccounts));
