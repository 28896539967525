import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { animationScrollToTop, isJustNowMessageCheck } from '../utils';
import { ReactComponent as PlusIcon } from 'assets/plus-white.svg';
import i18nContext from 'components/i18n-context';
import Button from 'uikit/Button/Button';
import { parseDate } from 'components/utils';
import '../Messages.scss';

const Topics = ({
  topics,
  activeTopicIndex,
  handleChangeActiveTopic,
  handleClickNewMessageButton,
  topicsRef,
  showWriteMessageButton
}) => {
  const i18n = useContext(i18nContext);

  useEffect(() => {
    if (topics) {
      animationScrollToTop(topicsRef);
    }
  }, [topics, topicsRef]);

  const selectTopic = (topicId) => {
    handleChangeActiveTopic(topicId);
  };

  return (
    <div className='messages-left-side'>
      {showWriteMessageButton && (
        <div className='write-new-message-button-container'>
          <Button
            className='write-new-message-button'
            type={'primary'}
            onClick={handleClickNewMessageButton}
            roleType={'button'}
            size={'large'}
          >
            <span className='write-new-message-button-label'>{i18n.getMessage('messages.button.writeNewMessage')}</span>
            <PlusIcon />
          </Button>
        </div>
      )}
      <div ref={topicsRef} className='topics-wrapper'>
        {topics.map((topic, index) => {
          const createdAt = parseDate(topic?.last_message?.create_at);
          const isJustNowTopicLastMessageCreated = isJustNowMessageCheck(topic?.last_message?.create_at);

          return (
            <button
              key={index}
              className={`topic-block ${activeTopicIndex === index ? 'topic-block-active' : ''}`}
              onClick={() => selectTopic(topic.id)}
            >
              {isJustNowTopicLastMessageCreated ? (
                <div className='topic-last-message-date-block'>
                  <span className='topic-last-message-date-just-now'>{i18n.getMessage('messages.date.justNow')}</span>
                </div>
              ) : (
                <div className='topic-last-message-date-block'>
                  <span className='topic-last-message-date-text'>{createdAt.date}</span>
                  <span className='topic-last-message-date-text'>{createdAt.time}</span>
                </div>
              )}
              <span className='topic-last-message-content'>{topic.subject}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

Topics.propTypes = {
  topics: PropTypes.array.isRequired,
  activeTopicIndex: PropTypes.number.isRequired,
  handleChangeActiveTopic: PropTypes.func.isRequired,
  handleClickNewMessageButton: PropTypes.func.isRequired,
  topicsRef: PropTypes.any.isRequired,
  showWriteMessageButton: PropTypes.bool.isRequired
};

export default Topics;
