import { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { PREDEFINED_FILTERS, getFromDate } from './utils';

const tabWidth = 150;

const AuthorizationRangeFilter = ({ onChange, value }) => {
  const i18n = useContext(i18nContext);
  const tabsContainerRef = useRef(null);

  const handleTabClick = (filterName) => {
    const tabElement = document.getElementById(filterName);
    if (tabElement) {
      const containerWidth = tabsContainerRef.current.offsetWidth;
      const scrollLeft = tabElement.offsetLeft + tabWidth - containerWidth;
      const isLeftEdge = tabElement.offsetLeft === 0;
      const isRightEdge = tabElement.offsetLeft + tabWidth === containerWidth;
      tabsContainerRef.current.scrollLeft = isRightEdge ? Math.max(scrollLeft, 0) : isLeftEdge ? 0 : scrollLeft;
    }
    onChange(filterName, getFromDate(filterName));
  };

  return (
    <div className='tabs-wrapper' ref={tabsContainerRef}>
      {Object.values(PREDEFINED_FILTERS).map((filterName) => (
        <button
          type='button'
          className={`filter-tab ${value === filterName ? 'active' : ''}`}
          onClick={() => handleTabClick(filterName)}
          key={filterName}
          id={filterName}
        >
          {i18n.getMessage(`cards.authorizations.rangeFilter.${filterName}`)}
        </button>
      ))}
    </div>
  );
};

AuthorizationRangeFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};

export default AuthorizationRangeFilter;
