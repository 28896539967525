import { useContext, useState } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { ReactComponent as PhoneIcon } from 'assets/phone-icon.svg';
import i18nContext from 'components/i18n-context';
import { SecurityInput } from 'uikit/SecurityInput/SecurityInput';
import Alert from 'uikit/Alert/Alert';
import authStore from 'stores/authStore';
import { getErrorMessageForAlert } from 'services/utils';

const Pin = ({ pin, sendPin, error }) => {
  const i18n = useContext(i18nContext);
  const [sentAlert, setSentAlert] = useState('');

  const onComplete = (pin) => {
    sendPin(pin);
  };

  return (
    <div className='authorization-form' id='form1'>
      <PhoneIcon />
      <h1 className='authorization-title'>{i18n.getMessage('settings.modal.label.enterPinCode')}</h1>
      <Alert
        type={error ? 'warning' : 'success'}
        message={(error && getErrorMessageForAlert(i18n, error)) || i18n.getMessage(sentAlert)}
      />
      <div className='form-data w-100'>
        <div className='forms-inputs mb-4'>
          <div>
            <p className='form-description mb-3'>{i18n.getMessage('pin.label.pin')}</p>
          </div>
        </div>
        <SecurityInput type={'pin'} values={pin} error={error} onComplete={onComplete} />
        <hr />
        <div className='return-wrapper'>
          <button className='unset link-normal' onClick={() => authStore.setIsLoginSuccess(false)}>
            {i18n.getMessage('pin.returnToLogIn')}
          </button>
        </div>
      </div>
    </div>
  );
};

Pin.propTypes = {
  pin: PropTypes.string.isRequired,
  sendPin: PropTypes.func.isRequired,
  sentAlert: PropTypes.string,
  error: PropTypes.any
};

export default inject('authStore')(observer(Pin));
