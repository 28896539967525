import { ReactComponent as LoadIcon } from './load-icon.svg';
import PropTypes from 'prop-types';
import './FileDownloadButton.scss';

export const FileDownloadButton = ({ onClick, type, className, style, isDisabled }) => {
  return (
    <button className={`pdf-button ${isDisabled ? 'disabled' : ''} ${className} ${style}`} onClick={onClick}>
      {type}
      <LoadIcon className='icon' />
    </button>
  );
};

FileDownloadButton.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.string,
  isDisabled: PropTypes.bool
};
