import { useContext, useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { CardDashboardView } from './CardDashboardView';
import { ReactComponent as AddNewCardIcon } from 'assets/add-new-card-icon.svg';
import i18nContext from 'components/i18n-context';
import { ROUTE_PATHS } from 'routes/constants';
import { useMediaQuery } from 'services/useMediaQUery';
import { MEDIA_QUERY } from 'services/utils';
import { Container } from 'uikit/Container/Container';
import '../../../Cards.scss';

const CardsView = ({ accountType, openCardDetails, cardsStore }) => {
  const i18n = useContext(i18nContext);
  const isMobile = useMediaQuery(MEDIA_QUERY.MOBILE);
  const cardsWrapperRef = useRef(null);
  const [paddingValue, setPaddingValue] = useState('0');

  useEffect(() => {
    if (cardsWrapperRef.current) {
      const wrapperHeight = cardsWrapperRef.current.clientHeight;
      if (wrapperHeight > 390 && isMobile) {
        setPaddingValue('10px');
      } else {
        setPaddingValue('0');
      }
    }
  }, [cardsStore.selectedWallet]);

  return (
    <Container className={'container-cards'} header={i18n.getMessage('cards.container.cards')} isMaxWidthUnset={true}>
      <Link className='link-normal get-new-card-link' to={ROUTE_PATHS.CARDS_CREATE}>
        <AddNewCardIcon />
        {i18n.getMessage('cards.newCard.title')}
      </Link>
      <div className='cards-wrapper' style={{ paddingLeft: paddingValue }} ref={cardsWrapperRef}>
        {cardsStore.cards.map(
          (card, index) =>
            card?.wallet_number === cardsStore.selectedWallet?.wallet_number && (
              <CardDashboardView key={index} accountType={accountType} card={card} handleClick={openCardDetails} />
            )
        )}
      </div>
    </Container>
  );
};

CardsView.propTypes = {
  accountType: PropTypes.string,
  openCardDetails: PropTypes.func,
  cardsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  accountType: stores.userStore.userData.account?.type,
  cardsStore: stores.cardsStore
}))(observer(CardsView));
