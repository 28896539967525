import ComplexTranslate from 'components/ComplexTranslate';
import moment from 'moment';

const Footer = () => {
  const currentYear = moment().year();

  return (
    <footer className='footer copyright-text demo-border'>
      <p className={'copyright'}>
        <ComplexTranslate
          text={'footer.text'}
          interpolation={{
            current_year: currentYear,
            payment_institution: (
              <a href={process.env.REACT_APP_LINK_HOME_PAGE} target='_blank' rel='noreferrer' className='link-normal'>
                {process.env.REACT_APP_PAYMENT_INSTITUTION}
              </a>
            )
          }}
        />
      </p>
    </footer>
  );
};

export default Footer;
