import React, { useContext } from 'react';
import { ReactComponent as ErrorIcon } from 'assets/error-popUp-icon.svg';
import i18nContext from '../../i18n-context';

export const PopUpSessionEndedScheme = ({ onClose }) => {
  const i18n = useContext(i18nContext);
  return (
    <div className='scheme-wrapper pop-up-error'>
      <ErrorIcon />
      <h2 className='scheme-success-header'>{i18n.getMessage('popUp.sessionEnded.header')}</h2>
      <p className='scheme-error-message'>{i18n.getMessage('PopUp.sessionEnded.description')}</p>
      <button className='scheme-success-button' onClick={() => onClose()}>
        {i18n.getMessage('popUp.success.button')}
      </button>
    </div>
  );
};
