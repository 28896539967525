import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'uikit/Container/Container';
import i18nContext from '../i18n-context';
import { FeesTable } from './FeesTable';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useMediaQuery } from 'services/useMediaQUery';
import { formPaymentMethod, formServiceName, formTransactionType, MEDIA_QUERY } from 'services/utils';
import { IconButton } from '../../uikit/IconButton/IconButton';
import { InputDropDown } from '../../uikit/InputDropDown/InputDropDown';
import { ReactComponent as Payments } from 'assets/payments.svg';
import { ReactComponent as Services } from 'assets/services.svg';
import './Fees.scss';
import { getWalletsOptionsForSelect, parseDate } from 'components/utils';
import { isTokenValid } from 'services/authUtils';
import Loader from 'components/common/Loader';
import { toJS } from 'mobx';

const Fees = ({ userStore }) => {
  const i18n = useContext(i18nContext);
  const isMobile = useMediaQuery(MEDIA_QUERY.MOBILE);
  const [transactional, setTransactional] = useState(true); // is payments tab active
  const [walletNumber, setWalletNumber] = useState(userStore.currentWallet?.wallet_number);

  useEffect(() => {
    if (isTokenValid()) {
      userStore.resetEnabledTariffs();
      userStore.loadEnabledTariffs(walletNumber, transactional);
    }
  }, [walletNumber, transactional]);

  // Input dropdown is same as in PaymentForm, might encapsulate that component with logic separately to not repeat the code
  const handleChange = (name, data) => {
    const selectedWallet = userStore.userWallets.find(({ wallet_number }) => wallet_number === data);
    userStore.setCurrentWallet(selectedWallet);
    setWalletNumber(selectedWallet.wallet_number);
  };

  const walletsOptions = getWalletsOptionsForSelect(userStore.userWallets);

  const mobileText = {
    paymentMethod: i18n.getMessage('fees.paymentMethod'),
    type: i18n.getMessage('fees.type'),
    tariffName: i18n.getMessage('fees.tariffName'),
    tariffValue: i18n.getMessage('fees.tariffValue'),
    dateLastUpdates: i18n.getMessage('fees.dateLastUpdates')
  };

  return (
    <section className='right-section fees'>
      <Container className='fees-container' header={i18n.getMessage('container.fees')}>
        <div className='tabs-container'>
          <IconButton
            className={`fees-tab ${transactional ? 'active' : ''}`}
            active={transactional}
            onClick={() => setTransactional(true)}
            Icon={Payments}
            text={i18n.getMessage('fees.tab.payments')}
          />
          <IconButton
            className='fees-tab'
            active={!transactional}
            onClick={() => setTransactional(false)}
            Icon={Services}
            text={i18n.getMessage('fees.tab.services')}
          />
        </div>
        <div className='dropdown-container'>
          {transactional && (
            <InputDropDown
              label={i18n.getMessage('accounts.iban')}
              className='fees-dropdown'
              options={walletsOptions}
              value={userStore.currentWallet?.wallet_number}
              currency={userStore.currentWallet?.currency}
              onChange={handleChange}
              isMulti={false}
            />
          )}
        </div>
        <div className='fees-list'>
          {!isMobile && (
            <div className='fees-head'>
              {transactional ? (
                <>
                  <p className='head-item'>{i18n.getMessage('fees.paymentMethod')}</p>
                  <p className='head-item'>{i18n.getMessage('fees.type')}</p>
                </>
              ) : (
                <p className='head-item'>{i18n.getMessage('fees.tariffName')}</p>
              )}
              <p className='head-item'>{i18n.getMessage('fees.tariffValue')}</p>
              <p className='head-item'>{i18n.getMessage('fees.dateLastUpdates')}</p>
            </div>
          )}
          {
            !userStore.isLoading ? (
              userStore.enabledTariffs?.map((data) => (
                <FeesTable
                  mobileText={mobileText}
                  type={'list'}
                  key={data.name} // need change it with better identificator
                  transactional={transactional}
                  paymentMethod={formPaymentMethod(data)}
                  transactionType={formTransactionType(data)}
                  tariffName={formServiceName(data)}
                  lastUpdate={parseDate(data.updated_at)}
                  feeType={data.fee_type}
                  tariffData={[toJS(data)]}
                />
              ))
            ) : (
              <Loader />
            ) // need to separate wallet and tariffs logic from userStore
          }
        </div>
      </Container>
    </section>
  );
};

Fees.propTypes = {
  userStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  userStore: stores.userStore
}))(observer(Fees));
