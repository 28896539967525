import { makeAutoObservable, runInAction } from 'mobx';
import i18n from 'i18n';
import { strToFloat } from 'services/utils';
import {
  internalTransactionCheck,
  internalTransactionCreate,
  transactionPaymentVerify,
  transactionPaymentVerificationCodeResend
} from 'services/requestAgent';

class BetweenAccountsStore {
  isInitialized = false;
  isLoading = false;
  isInternalTransactionChecked = false;
  isInternalTransactionCreated = false;
  isInternalTransferCompleted = false;
  isShowConfirmationPopUp = false;
  isShowSuccessPopUp = false;
  error = null;
  confirmationPopupError = null;
  walletFrom = null;
  walletTo = null;
  amount = null;
  description = null;
  transactionNumber = null;

  constructor() {
    makeAutoObservable(this);
  }

  resetStore() {
    this.isInitialized = false;
    this.isLoading = false;
    this.isInternalTransactionChecked = false;
    this.isInternalTransactionCreated = false;
    this.isInternalTransferCompleted = false;
    this.isShowConfirmationPopUp = false;
    this.isShowSuccessPopUp = false;
    this.error = null;
    this.confirmationPopupError = null;
    this.walletFrom = null;
    this.walletTo = null;
    this.amount = null;
    this.description = null;
    this.transactionNumber = null;
  }

  setError(error) {
    this.error = error;
  }

  setFromToWallets(from, to) {
    this.walletFrom = from;
    this.walletTo = to;

    if (!from || !to) {
      this.error = i18n.getMessage('betweenAccounts.error.sameCurrencyWallets');
    } else {
      this.error = null;
    }

    if (!this.isInitialized) {
      this.isInitialized = true;
    }
  }

  setAmount(amount) {
    this.amount = amount;
  }

  setDescription(description) {
    this.description = description;
  }

  setIsInternalTransactionCreated(status) {
    this.isInternalTransactionCreated = status;
  }

  setIsInternalTransferCompleted(status) {
    this.isInternalTransferCompleted = status;
  }

  clearConfirmationPopupError() {
    this.confirmationPopupError = null;
  }

  async checkInternalTransaction(accountNumber) {
    try {
      runInAction(() => {
        this.isLoading = true;
      });

      await internalTransactionCheck(accountNumber, {
        sender_wallet: this.walletFrom.wallet_number,
        recipient_wallet: this.walletTo.wallet_number,
        amount: strToFloat(this.amount),
        comment: this.description
      });

      runInAction(() => {
        this.isInternalTransactionChecked = true;
      });
    } catch (err) {
      if (err.code === 'EXCHANGE_RATE_MIN_VALUE') {
        let newError = {};
        newError.fields = {
          amount: err.code
        };
        runInAction(() => {
          this.error = newError;
          this.isInternalTransactionChecked = false;
        });
        return;
      }
      runInAction(() => {
        this.error = err;
        this.isInternalTransactionChecked = false;
      });
      throw err;
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async createInternalTransaction(accountNumber) {
    try {
      runInAction(() => {
        this.isLoading = true;
      });

      const { transaction_number: transactionNumber } = await internalTransactionCreate(accountNumber, {
        sender_wallet: this.walletFrom.wallet_number,
        recipient_wallet: this.walletTo.wallet_number,
        amount: strToFloat(this.amount),
        comment: this.description
      });

      runInAction(() => {
        this.isInternalTransactionCreated = true;
        this.transactionNumber = transactionNumber;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isInternalTransactionCreated = false;
      });
      throw err;
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async resendTransactionSecurityCode(accountNumber) {
    try {
      const { transaction_number: transactionNumber } = await transactionPaymentVerificationCodeResend(
        accountNumber,
        this.transactionNumber
      );

      runInAction(() => {
        this.transactionNumber = transactionNumber;
      });
    } catch (err) {
      runInAction(() => {
        this.confirmationPopupError = err;
      });
    }
  }

  async verifyInternalTransaction(accountNumber, securityCode) {
    try {
      runInAction(() => {
        this.isLoading = false;
      });
      await transactionPaymentVerify(accountNumber, this.transactionNumber, { verification_code: securityCode });

      runInAction(() => {
        this.isShowSuccessPopUp = true;
        this.isInternalTransferCompleted = true;
      });
    } catch (err) {
      runInAction(() => {
        this.confirmationPopupError = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
}

export default new BetweenAccountsStore();
