import { useContext } from 'react';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import '../../../Cards.scss';

export const PhysicalCardForm = () => {
  const i18n = useContext(i18nContext);

  return (
    <form className='virtual-card-form'>
      <span className='virtual-card-form-title'>{i18n.getMessage('cards.creationForm.virtual.title')}</span>
    </form>
  );
};

PhysicalCardForm.propTypes = {
  cards: PropTypes.array
};
