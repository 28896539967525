import { useContext } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import i18nContext from 'components/i18n-context';
import TransactionsList from 'components/common/LatestTransactions/TransactionsList';
import { Container } from 'uikit/Container/Container';
import './LatestTransactions.scss';

const ComplianceRequests = ({ transactionsStore }) => {
  const i18n = useContext(i18nContext);

  return (
    <section className='right-section latest-transactions'>
      <Container className='transactions-container' header={i18n.getMessage('container.complianceRequests')}>
        <TransactionsList
          isLoading={transactionsStore.isLoading}
          isComplianceRequests={true}
          compact
          transactions={transactionsStore?.complianceRequestList}
        />
      </Container>
    </section>
  );
};

ComplianceRequests.propTypes = {
  transactionsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  transactionsStore: stores.transactionsStore
}))(observer(ComplianceRequests));
