import { makeAutoObservable, runInAction } from 'mobx';
import { decryptCardData, generateRSAKeyPair } from 'components/Cards/utils';
import { CARD_AUTHORIZATIONS_STATUSES } from 'components/common/constants';
import {
  extractWalletNumbers,
  filterStatuses,
  getFromDate,
  PREDEFINED_FILTERS
} from 'components/common/AuthorisationsList/utils';
import {
  blockCardRequest,
  confirmCardCreation,
  createCardRequest,
  getCardAuthorizationsListRequest,
  getCardCVVRequest,
  getCardNumberRequest,
  getCardsList,
  resendConfirmationCode,
  unblockCardRequest
} from 'services/requestAgent';
import { convertDateToServerFilter } from 'services/utils';

const getFilters = (rangeFilter) => {
  const { from, to } = getFromDate(rangeFilter);
  return {
    from_date: new Date(from) || null,
    to_date: new Date(to) || null,
    wallets: [],
    statuses: filterStatuses(CARD_AUTHORIZATIONS_STATUSES)
  };
};

class CardsStore {
  isInitialized = false;
  isLoading = false;
  isAuthorizationsLoading = false;
  isCardCreateConfirmation = false;
  isCardCreated = false;
  confirmationIntent = null;
  error = null;
  wallets = [];
  cards = [];
  selectedCard = null;
  authorizationsList = [];
  selectedWallet = null;
  topUpWallet = null;
  cardNumber = null;
  cvv = null;

  totalElements = null;
  totalPages = null;
  rangeFilter = PREDEFINED_FILTERS.WEEK;
  filters = getFilters(this.rangeFilter);

  pagination = {
    size: 7,
    page: 0
  };
  latestAuthorizationsSort = {
    sort_column: 'updated_at',
    sort_direction: 'DESC',
    size: 5
  };

  authorizationsSort = {
    sort_column: 'updated_at',
    sort_direction: 'DESC'
  };

  constructor() {
    makeAutoObservable(this);
  }

  resetCardsStore() {
    this.isInitialized = false;
    this.isLoading = false;
    this.isAuthorizationsLoading = false;
    this.isCardCreateConfirmation = false;
    this.isCardCreated = false;
    this.confirmationIntent = null;
    this.error = null;
    this.wallets = [];
    this.cards = [];
    this.selectedCard = null;
    this.authorizationsList = [];
    this.selectedWallet = null;
    this.topUpWallet = null;
    this.cardNumber = null;
    this.cvv = null;
    this.totalElements = null;
    this.totalPages = null;
    this.rangeFilter = PREDEFINED_FILTERS.WEEK;
    this.filters = getFilters(this.rangeFilter);

    this.pagination = {
      size: 7,
      page: 0
    };
    this.latestAuthorizationsSort = {
      sort_column: 'updated_at',
      sort_direction: 'DESC',
      size: 5
    };
    this.authorizationsSort = {
      sort_column: 'updated_at',
      sort_direction: 'DESC'
    };
  }

  resetCardData() {
    this.cardNumber = null;
    this.cvv = null;
  }

  async initializeStoreState(accountNumber, wallets) {
    try {
      if (wallets.length === 0) {
        runInAction(() => {
          this.isInitialized = true;
        });
      } else {
        runInAction(() => {
          this.isLoading = true;
          this.wallets = wallets;
          if (!this.selectedWallet) {
            this.selectedWallet = wallets[0];
          }
        });

        const res = await getCardsList(accountNumber);

        runInAction(() => {
          this.cards = res;
          this.isInitialized = true;
        });
      }
    } catch (err) {
      this.error = err;
    } finally {
      this.isLoading = false;
    }
  }

  setIsLoading(status) {
    this.isLoading = status;
  }

  setSelectedCard(card) {
    this.selectedCard = card;
  }

  setWallets(wallets) {
    this.wallets = wallets;
  }

  setIsCardCreated(status) {
    this.isCardCreated = status;
  }

  setError(error) {
    this.error = error;
  }

  clearError() {
    this.error = null;
  }

  setSelectedWallet(wallet) {
    this.selectedWallet = wallet;
  }

  setTopUpWallet(wallet) {
    this.topUpWallet = wallet;
  }

  setFilter(fieldName, value) {
    this.filters[fieldName] = value;
    this.pagination.page = 0;
  }

  setRangeFilter(filterType, dateRange) {
    this.pagination.page = 0;
    this.rangeFilter = filterType;
    this.filters.from_date = new Date(dateRange.from);
    this.filters.to_date = new Date(dateRange.to);
  }

  clearPredefinedFilter() {
    this.rangeFilter = null;
  }

  clearFilters() {
    this.rangeFilter = PREDEFINED_FILTERS.WEEK;
    this.filters = getFilters(this.rangeFilter);
  }

  setAuthorizationsPage(page) {
    this.pagination.page = page;
  }

  setAuthorizationsSort(sortData) {
    this.authorizationsSort.sort_column = sortData.sort_column;
    this.authorizationsSort.sort_direction = sortData.sort_direction;
  }

  getCardById(cardId) {
    return this.cards.find((card) => card?.card_id === cardId);
  }

  async sendCreateCardRequest(accountNumber, data) {
    try {
      this.setIsLoading(true);

      const res = await createCardRequest(accountNumber, data);

      runInAction(() => {
        this.confirmationIntent = res;
        this.isCardCreateConfirmation = true;
      });
    } catch (err) {
      this.error = err;
    } finally {
      this.setIsLoading(false);
    }
  }

  async sendConfirmCardCreationCode(accountNumber, code) {
    try {
      this.setIsLoading(true);

      const res = await confirmCardCreation(accountNumber, { uid: this.confirmationIntent.uid, code });

      runInAction(() => {
        this.cards.push(res);
        this.isCardCreateConfirmation = false;
        this.confirmationIntent = null;
        this.isCardCreated = true;
      });
    } catch (err) {
      this.error = err;
    } finally {
      this.setIsLoading(false);
    }
  }

  async resendSecurityCode() {
    try {
      this.setIsLoading(true);

      const res = await resendConfirmationCode(this.confirmationIntent.uid);

      runInAction(() => {
        this.confirmationIntent = res;
      });
    } catch (err) {
      this.error = err;
    } finally {
      this.setIsLoading(false);
    }
  }

  async getCardNumber(accountNumber, cardId) {
    try {
      this.setIsLoading(true);

      const { publicKey, privateKey } = await generateRSAKeyPair();

      const { encrypted_card_data: encryptedCardData } = await getCardNumberRequest(accountNumber, cardId, {
        public_key: publicKey
      });

      const decryptedCardNumber = await decryptCardData(encryptedCardData, 'CardNumber', privateKey);

      runInAction(() => {
        this.cardNumber = decryptedCardNumber;
      });
    } catch (err) {
      this.error = err;
    } finally {
      this.setIsLoading(false);
    }
  }

  async getCardCVV(accountNumber, cardId) {
    try {
      this.setIsLoading(true);

      const { publicKey, privateKey } = await generateRSAKeyPair();

      const { encrypted_card_data: encryptedCardData } = await getCardCVVRequest(accountNumber, cardId, {
        public_key: publicKey
      });

      const decryptedCardCVV = await decryptCardData(encryptedCardData, 'CVV2', privateKey);

      runInAction(() => {
        this.cvv = decryptedCardCVV;
      });
    } catch (err) {
      this.error = err;
    } finally {
      this.setIsLoading(false);
    }
  }

  async blockCard(accountNumber, cardId) {
    try {
      this.setIsLoading(false);

      const cardInfo = await blockCardRequest(accountNumber, cardId);
      const cardIndex = this.cards.findIndex((card) => card?.card_id === cardId);

      runInAction(() => {
        this.cards[cardIndex] = cardInfo;
        this.selectedCard = cardInfo;
      });
    } catch (err) {
      this.error = err;
    } finally {
      this.setIsLoading(false);
    }
  }

  async unblockCard(accountNumber, cardId) {
    try {
      this.setIsLoading(false);

      const cardInfo = await unblockCardRequest(accountNumber, cardId);
      const cardIndex = this.cards.findIndex((card) => card?.card_id === cardId);

      runInAction(() => {
        this.cards[cardIndex] = cardInfo;
        this.selectedCard = cardInfo;
      });
    } catch (err) {
      this.error = err;
    } finally {
      this.setIsLoading(false);
    }
  }

  async createNewVirtualCard() {
    /* Create virtual card logic*/
  }

  async getLatestAuthorizationsList(accountNumber, filters = {}) {
    try {
      this.setIsLoading(true);

      const {
        content: data,
        total_elements,
        total_pages,
        number,
        size
      } = await getCardAuthorizationsListRequest(accountNumber, {
        ...this.latestAuthorizationsSort,
        ...filters,
        statuses: filterStatuses(CARD_AUTHORIZATIONS_STATUSES)
      });

      runInAction(() => {
        this.totalElements = total_elements;
        this.totalPages = total_pages;
        this.pagination.size = size;
        this.pagination.page = number;
        this.authorizationsList = data;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
      throw err;
    } finally {
      this.setIsLoading(false);
    }
  }

  async getCardAuthorizations(accountNumber) {
    try {
      this.setIsLoading(true);
      const query = {
        ...this.filters,
        ...this.pagination,
        from: convertDateToServerFilter(this.filters.from_date),
        to: convertDateToServerFilter(this.filters.to_date),
        ...this.authorizationsSort,
        wallets: extractWalletNumbers(this.wallets)
      };

      const {
        content: data,
        total_elements,
        total_pages,
        number,
        size
      } = await getCardAuthorizationsListRequest(accountNumber, query);

      runInAction(() => {
        this.totalElements = total_elements;
        this.totalPages = total_pages;
        this.pagination.size = size;
        this.pagination.page = number;
        this.authorizationsList = data;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
      throw err;
    } finally {
      this.setIsLoading(false);
    }
  }
}

export default new CardsStore();
