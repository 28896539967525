import { useEffect, useState } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { Outlet } from 'react-router-dom';
import Header from 'components/common/Header';
import Footer from 'components/common/Footer';
import Loader from 'components/common/Loader';
import NavigationBar from 'components/common/NavigationBar';
import NoOutlet from 'components/common/NoOutlet/NoOutlet';
import { PopUpSessionAutoLogOutScheme } from 'components/common/PopUpScheme/PopUpSessionAutoLogOutScheme';
import { useMediaQuery } from 'services/useMediaQUery';
import { MEDIA_QUERY } from 'services/utils';

const ApplicationLayout = ({ userStore }) => {
  const [compactMenu, setCompactMenu] = useState(false);
  const isMobile = useMediaQuery(MEDIA_QUERY.MOBILE);
  const isDesktop = useMediaQuery(MEDIA_QUERY.DESKTOP);

  useEffect(() => {
    if (isDesktop === false && !compactMenu && isMobile === false) {
      setCompactMenu(true);
    } else if (isDesktop && compactMenu) {
      setCompactMenu(false);
    }
  }, [isMobile, isDesktop]);

  return (
    <div className='page application'>
      <Header collapseMenu={setCompactMenu} compact={compactMenu} />
      <main className={`content ${compactMenu ? 'compact' : isMobile ? 'bg-transparent' : ''}`}>
        <PopUpSessionAutoLogOutScheme />
        <NavigationBar
          isMobile={isMobile}
          collapseMenu={setCompactMenu}
          compact={compactMenu}
          isAccountVerified={userStore.isAccountVerified}
          isFees={userStore.isFeesEnabled()}
        />

        {userStore.isLoading ? (
          <Loader className='application' />
        ) : Object.keys(userStore.userData).length ? (
          <Outlet />
        ) : (
          <NoOutlet error={userStore?.error} />
        )}
      </main>
      <Footer />
    </div>
  );
};

ApplicationLayout.propTypes = {
  userStore: MobXPropTypes.observableObject
};

export default inject('userStore')(observer(ApplicationLayout));
