import { replaceSpaces, strToFloat } from 'services/utils';

export default ({ providerTableData, data }) => ({
  sender_wallet: (data.wallet && data.wallet?.wallet_number?.trim()) || '',
  currency: (data.wallet && data.wallet?.currency?.trim()) || '',
  amount: strToFloat(providerTableData?.amount?.toString() || ''),
  transaction_recipient: {
    beneficiary: {
      type: providerTableData.recipient_type.toUpperCase(),
      ...(providerTableData.recipient_type === 'Company'
        ? {
            company_name: providerTableData?.company_name?.trim() || ''
          }
        : {
            first_name: providerTableData?.first_name?.trim() || '',
            last_name: providerTableData?.last_name?.trim() || ''
          })
    },
    requisites: {
      iban: replaceSpaces(providerTableData?.recipient_wallet) || '',
      bank_country: data?.bank_country?.trim()
    }
  },
  payment_method: data.paymentMethod || '',
  transfer_provider: (data.wallet && data.wallet?.transfer_provider) || '',
  comment: providerTableData?.comment?.trim() || '',
  documents: data.uploadedFiles ? data.uploadedFiles : []
});
