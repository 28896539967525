import { useContext } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { IconButton } from 'uikit/IconButton/IconButton';
import i18nContext from 'components/i18n-context';
import { ACCOUNT_TYPE } from 'components/common/constants';
import { ReactComponent as PersonalDetailsIcon } from 'assets/personal-details.svg';

import './Profile.scss';
import { Container } from '../../uikit/Container/Container';

const Profile = ({ userEmail, accountID, firstName, lastName, companyName, type }) => {
  const i18n = useContext(i18nContext);

  return (
    <section className='right-section profile'>
      <Container header={i18n.getMessage('navigation.profile.text')}>
        <div className='settings-wrapper'>
          <div className='settings-container-left'>
            <IconButton
              className='settings-icon'
              active
              onClick={() => {}}
              Icon={PersonalDetailsIcon}
              text={i18n.getMessage('profile.account-details')}
            />
          </div>
          <div className='settings-container-right'>
            <div className='profile-wrapper'>
              <div className='profile-info-wrapper left'>
                <p className='profile-header'>
                  {type === ACCOUNT_TYPE.INDIVIDUAL
                    ? i18n.getMessage('profile.firstName')
                    : i18n.getMessage('profile.companyName')}
                </p>
                <p className='profile-info'>{type === ACCOUNT_TYPE.INDIVIDUAL ? firstName : companyName}</p>
              </div>
              {type === ACCOUNT_TYPE.INDIVIDUAL && (
                <div className='profile-info-wrapper'>
                  <p className='profile-header'>{i18n.getMessage('profile.last-name')}</p>
                  <p className='profile-info'>{lastName}</p>
                </div>
              )}
            </div>
            <div className='profile-wrapper'>
              <div className='profile-info-wrapper left'>
                <p className='profile-header'>{i18n.getMessage('profile.email')}</p>
                <p className='profile-info'>{userEmail}</p>
              </div>
              <div className='profile-info-wrapper'>
                <p className='profile-header'>{i18n.getMessage('profile.accountID')}</p>
                <p className='profile-info'>{accountID}</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

Profile.propTypes = {
  userEmail: PropTypes.string,
  accountID: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  type: PropTypes.string,
  companyName: PropTypes.string,
  phoneNumber: PropTypes.string
};

export default inject((stores) => ({
  userEmail: stores.userStore.userData?.account?.email,
  accountID: stores.userStore.userData?.account?.account_number,
  firstName: stores.userStore.userData.account?.details.name,
  lastName: stores.userStore.userData.account?.details.surname,
  type: stores.userStore.userData.account?.type,
  companyName: stores.userStore.userData.account?.details.company_name
}))(observer(Profile));
