import PropTypes from 'prop-types';
import { ReactComponent as CalendarIcon } from 'assets/calendar-icon.svg';
import { ReactComponent as StatusIcon } from 'assets/mobile-transaciton-icon.svg';
import { ReactComponent as ProfileIcon } from 'assets/iconButtons/profile.svg';
import { ReactComponent as WalletIcon } from 'assets/wallet.svg';
import './AuthorizationsList.scss';

export const AuthorizationItemLoader = ({ isMobile }) => {
  if (isMobile) {
    return (
      <div className='authorization-item-mobile'>
        <div className='item-mobile-top'>
          <div className='item-mobile-date'>
            <CalendarIcon className='icon' />
            <div className={'loader-placeholder'} />
          </div>
          <div className='item-mobile-status'>
            <StatusIcon className='icon' />
            <div className={'loader-placeholder'} />
          </div>
        </div>
        <div className='item-mobile-from-to'>
          <ProfileIcon className='icon' />
          <div>
            <div className={'loader-placeholder'} />
            <div className={'loader-placeholder'} />
          </div>
        </div>
        <div className='item-mobile-amount'>
          <WalletIcon className='icon' />
          <div className={'loader-placeholder'} />
          <div className={'loader-placeholder'} />
        </div>
      </div>
    );
  }

  return (
    <tr className='authorization-item'>
      <td className='authorization-date'>
        <div className={'loader-placeholder'} />
        <div className={'loader-placeholder'} />
      </td>
      <td className='authorization-status'>
        <div className={'loader-placeholder'} />
      </td>
      <td className='authorization-from-to'>
        <div className={'loader-placeholder'} />
        <div className={'loader-placeholder'} />
      </td>
      <td className='authorization-purpose'>
        <div className={'loader-placeholder'} />
        <div className={'loader-placeholder'} />
      </td>
      <td className={'authorization-amount-commission'}>
        <div className={'loader-placeholder'} />
      </td>
      <td className='authorization-exclamation'>
        <div className={'loader-placeholder'} />
      </td>
    </tr>
  );
};

AuthorizationItemLoader.propTypes = {
  isMobile: PropTypes.bool
};
