import { useContext } from 'react';
import PropTypes from 'prop-types';
import i18nContext from '../../i18n-context';
import { ReactComponent as LoadIcon } from 'assets/loader.svg';
import './Loader.scss';

const Loader = ({ className }) => {
  const i18n = useContext(i18nContext);

  return (
    <div className={`loader-wrapper ${className}`}>
      {i18n.getMessage('loader.text')}
      <LoadIcon className='loader-wrapper-icon' />
    </div>
  );
};

Loader.propTypes = {
  className: PropTypes.string
};

export default Loader;
