import { useContext } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ROUTE_PATHS } from 'routes/constants';
import i18nContext from 'components/i18n-context';
import { PhysicalCardForm } from './CardCreationForms/PhysicalCardForm';
import { VirtualCardForm } from './CardCreationForms/VirtualCardForm';
import Loader from 'components/common/Loader';
import PopUpSuccessScheme from 'components/common/PopUpScheme/PopUpSuccessScheme';
import { useMediaQuery } from 'services/useMediaQUery';
import { amountFormattedValue, MEDIA_QUERY } from 'services/utils';
import { Container } from 'uikit/Container/Container';
import { PopUp } from 'uikit/PopUp/PopUp';
import '../../Cards.scss';

const authorizedPersonOptions = [
  {
    key: 'Name Surname 1',
    value: 'Name Surname 1'
  },
  {
    key: 'Name Surname 2',
    value: 'Name Surname 2'
  },
  {
    key: 'Name Surname 3',
    value: 'Name Surname 3'
  },
  {
    key: 'Name Surname 4',
    value: 'Name Surname 4'
  }
];

const CreateNewCardForm = ({ accountNumber, accountDetails, accountType, cardsStore, paymentStore }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const i18n = useContext(i18nContext);
  const isMobile = useMediaQuery(MEDIA_QUERY.MOBILE);

  const clearError = () => cardsStore.clearError();
  const sendCreateCardRequest = (data) => cardsStore.sendCreateCardRequest(accountNumber, data);
  const sendConfirmCardCreationCode = (code) => cardsStore.sendConfirmCardCreationCode(accountNumber, code);
  const resendSecurityCode = () => cardsStore.resendSecurityCode();
  const onCloseSuccessPopUp = () => {
    cardsStore.setIsCardCreated(false);
    navigate('/cards');
  };

  const getAvailableBalance = () =>
    paymentStore?.currentWallet &&
    i18n.getMessage('sendMoney.topLabel', {
      available: amountFormattedValue(paymentStore?.currentWallet.available),
      currency: paymentStore?.currentWallet.currency
    });

  const walletsOptions = cardsStore?.wallets.map((wallet) => {
    return {
      key: wallet.wallet_number,
      value: wallet.wallet_number
    };
  });

  return (
    <Container
      className={!isMobile ? 'container-web' : ''}
      header={i18n.getMessage('container.cards')}
      isMaxWidthUnset={true}
    >
      {!cardsStore.isInitialized ? (
        <div className='loader-container'>
          <Loader />
        </div>
      ) : location.pathname === ROUTE_PATHS.CARDS_CREATE_VIRTUAL ? (
        <VirtualCardForm
          accountType={accountType}
          userFullName={`${accountDetails?.name} ${accountDetails?.surname}`}
          getAvailableBalance={getAvailableBalance}
          walletsOptions={walletsOptions}
          authorizedPersonOptions={authorizedPersonOptions}
          openSuccessPopUp={cardsStore.setIsCardCreated}
          sendCreateCardRequest={sendCreateCardRequest}
          isCardCreateConfirmation={cardsStore.isCardCreateConfirmation}
          onConfirm={sendConfirmCardCreationCode}
          resendSecurityCode={resendSecurityCode}
          error={cardsStore.error}
          clearError={clearError}
          dashboardSelectedWalletNumber={cardsStore.selectedWallet?.wallet_number}
        />
      ) : location.pathname === ROUTE_PATHS.CARDS_CREATE_PHYSICAL ? (
        <PhysicalCardForm />
      ) : null}

      <PopUp show={cardsStore.isCardCreated} onClose={onCloseSuccessPopUp} alignOnCenter={true}>
        <PopUpSuccessScheme
          onClose={onCloseSuccessPopUp}
          message={i18n.getMessage('popUp.success.virtualCardCreated')}
        />
      </PopUp>
    </Container>
  );
};

CreateNewCardForm.propTypes = {
  accountNumber: PropTypes.string,
  accountDetails: PropTypes.object,
  accountType: PropTypes.string,
  cardsStore: MobXPropTypes.observableObject,
  paymentStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  accountNumber: stores.userStore.userData.account?.account_number,
  accountDetails: stores.userStore.userData.account?.details,
  accountType: stores.userStore.userData.account?.type,
  cardsStore: stores.cardsStore,
  paymentStore: stores.paymentStore
}))(observer(CreateNewCardForm));
