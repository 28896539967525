import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import Button from 'uikit/Button/Button';
import { useContext, useEffect, useState } from 'react';
import i18nContext from 'components/i18n-context';
import { CheckBox } from 'uikit/CheckBox/CheckBox';
import { ACCOUNT_PERMISSIONS, SMS_ERROR_CODE_DISABLE } from 'components/common/constants';
import { ConfirmCodeWrapper } from 'components/common/ConfirmCodeWrapper/ConfirmCodeWrapper';
import { generateSecurityCode, resendSecurityCode } from 'services/requestAgent';
import { getErrorMessageForAlert } from 'services/utils';
import { getSecureUserPhone } from '../../services/authUtils';

export const PermissionScheme = ({
  closePopUp,
  email,
  error,
  clearError,
  submitPermissions,
  permissions,
  representativeForUpdate
}) => {
  const i18n = useContext(i18nContext);
  const [showSMS, setShowSms] = useState(false);
  const [showCountDown, setShowCountDown] = useState(false);
  const [alertState, setAlertState] = useState({ type: '', message: '' });
  const [securityCode, setSecurityCode] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [currentPermissions, setCurrentPermissions] = useState(
    representativeForUpdate?.permissions || [ACCOUNT_PERMISSIONS.READ_ACCOUNT]
  );

  useEffect(() => {
    if (SMS_ERROR_CODE_DISABLE.includes(error?.code)) {
      setIsDisabled(true);
    }
    error && setAlertState({ type: 'warning', message: getErrorMessageForAlert(i18n, error) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onCompleteSecurityCode = (code) => {
    setSecurityCode(code);
    setAlertState({ type: '', message: '' });
  };

  const handleResendCode = async () => {
    clearError();
    await resendSecurityCode();
    isDisabled && setIsDisabled(false);
    setShowCountDown(true);
    setAlertState({ type: 'success', message: i18n.getMessage('pin.alert.sent') });
  };

  const onConfirmClick = async () => {
    if (showSMS) {
      await submitPermissions(currentPermissions, securityCode);
      return;
    }

    setShowSms(true);
    setShowCountDown(true);
    await generateSecurityCode();
  };

  const onCheckBoxChange = (key) => {
    if (!currentPermissions.includes(key)) setCurrentPermissions((prev) => [...prev, key]);
    else {
      setCurrentPermissions(currentPermissions.filter((perm) => perm !== key));
    }
  };

  const isSelectAll = !permissions.filter((permission) => {
    if (permission !== ACCOUNT_PERMISSIONS.READ_MESSAGES) {
      return !currentPermissions.includes(permission);
    }
  }).length;

  const availablePermissions = permissions.filter((permission) => permission !== ACCOUNT_PERMISSIONS.READ_MESSAGES);

  const renderButtonText = () => {
    if (showSMS) return i18n.getMessage('recovery.button.confirm');
    return i18n.getMessage(representativeForUpdate ? 'representatives.label.change' : 'representatives.label.add');
  };

  return (
    <div className='scheme-wrapper permission'>
      <div className='representatives-add-header-wrapper'>
        <h2 className='representatives-popUp-header'>{i18n.getMessage('representatives.header.permission')}</h2>
        <CloseIcon className='representatives-icon' onClick={closePopUp} />
      </div>
      <p className={`representatives-email ${showSMS ? 'disabled' : ''}`}>{email || 'Name@example.com'}</p>
      <CheckBox
        className='representatives-checkbox full-checkbox'
        isActive={isSelectAll}
        showCheckBox={true}
        disabled={showSMS}
        onChange={() => setCurrentPermissions(isSelectAll ? [ACCOUNT_PERMISSIONS.READ_ACCOUNT] : availablePermissions)}
        value={i18n.getMessage('representatives.label.fullAccess')}
      />
      <div className='permission-checkboxes-wrapper'>
        {Object.keys(ACCOUNT_PERMISSIONS).map(
          (permission) =>
            permission !== ACCOUNT_PERMISSIONS.READ_MESSAGES && (
              <CheckBox
                className='representatives-checkbox'
                key={`representatives-permission-${permission}-id`}
                isActive={currentPermissions?.includes(permission)}
                onChange={() => onCheckBoxChange(permission)}
                disabled={showSMS || permission === ACCOUNT_PERMISSIONS.READ_ACCOUNT}
                showCheckBox={true}
                value={i18n.getMessage(`representatives.permission.${permission}`)}
              />
            )
        )}
      </div>
      {showSMS && (
        <ConfirmCodeWrapper
          PhoneComponent={<strong>{getSecureUserPhone()}</strong>}
          isDisabled={isDisabled}
          error={error}
          onComplete={onCompleteSecurityCode}
          showCountDown={showCountDown}
          handleResendCode={handleResendCode}
          onTimerEnd={() => setShowCountDown(false)}
          alertState={alertState}
        />
      )}
      <Button size='large' fullWidth={true} onClick={onConfirmClick}>
        {renderButtonText()}
      </Button>
    </div>
  );
};

PermissionScheme.propTypes = {
  closePopUp: PropTypes.func.isRequired,
  email: PropTypes.string,
  phone: PropTypes.string,
  clearError: PropTypes.func,
  submitPermissions: PropTypes.func,
  error: PropTypes.any,
  representativeForUpdate: PropTypes.object,
  permissions: PropTypes.array
};
