import * as Yup from 'yup';
import { passwordRegExp } from '../../services/utils';
import i18n from '../../i18n';

export const verifyPasswordSecurity = (password) =>
  /\d/.test(password) &&
  /[A-Za-z]/.test(password) &&
  /^[A-Za-z0-9/~!@#$%&*_+=`|(){}:;"'<>,.?^[\]\-\\]{7,}$/.test(password);

export const verifyPinSecurity = (pin) => !/^\d{4}$/.test(pin);

export const getPasswordValidation = () => {
  return Yup.string()
    .nullable(true)
    .min(7, i18n.getMessage('error.PASSWORD_REQUIREMENTS'))
    .max(30, i18n.getMessage('error.fieldSize', { amount: '30' }))
    .required(i18n.getMessage('register.error.passwordSecurityRequirementsViolation'))
    .matches(passwordRegExp, { message: i18n.getMessage('register.error.password', { amount: '7-30' }) });
};

export const getRandomHash = () => {
  return (Math.random() + 1).toString(36).substring(7);
};
