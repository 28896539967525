import PropTypes from 'prop-types';
import { ReactComponent as UploadIcon } from 'assets/upload-file-icon.svg';
import { ReactComponent as DeleteIcon } from 'assets/delete-file-icon.svg';
import { classNames } from 'uikit/utils';
import './AttachDoc.scss';

export const AttachDoc = ({
  name,
  error,
  files,
  size,
  onChange,
  label,
  className,
  initialStatus,
  isDisabled,
  isRequired,
  onRemoveFiles,
  hintText
}) => {
  const inputChange = (e) => {
    onChange(e);
  };

  const inputClasses = classNames({
    'input-wrapper-file': true,
    active: files?.length,
    error: !!error,
    hit: !error && !!initialStatus,
    disabled: isDisabled,
    [className]: true
  });

  const renderFileInfo = (file) => {
    return (
      <div className='files-item' key={file?.name + file?.id}>
        <p className='file-text'>{file?.name}</p>
        <DeleteIcon onClick={() => onRemoveFiles(file?.id)} className='delete-file-icon' />
        <div className='tooltip'>
          <p className='tooltip-text'>{file?.name}</p>
        </div>
      </div>
    );
  };

  return (
    <div className={inputClasses}>
      {label && <label htmlFor={name}>{label + (isRequired ? '*' : '')}</label>}
      <div className='input'>
        <div className='files-list-wrapper'>{files?.map((file) => renderFileInfo(file))}</div>
        <div className='upload-file-button'>
          <input
            type='file'
            value=''
            name={name}
            multiple
            accept='.jpg,.jpeg,.pdf, .png'
            size={size}
            title=' '
            onChange={inputChange}
            disabled={isDisabled}
            className='file-input'
          />
          <UploadIcon className='upload-file-icon' />
        </div>
      </div>
      <p className={`${error ? 'input-sub-text input-error' : 'input-hint-text'}`}>{error || hintText}</p>
    </div>
  );
};

AttachDoc.propTypes = {
  name: PropTypes.string,
  error: PropTypes.any,
  files: PropTypes.array.isRequired,
  size: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  initialStatus: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  onRemoveFiles: PropTypes.func.isRequired,
  hintText: PropTypes.string.isRequired
};
