export default class FieldError extends Error {
  constructor({ status, fields, code, message = 'Error' }) {
    super(...arguments);
    this.name = this.constructor.name;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }

    this.status = status;
    this.code = code;
    this.message = message;
    this.fields = fields.reduce((res, { code: errorCode, field: name }) => {
      res[name] = errorCode;
      return res;
    }, {});
  }
}
