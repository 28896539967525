import { Outlet } from 'react-router-dom';
import Footer from 'components/common/Footer';

const logoPath = process.env.REACT_APP_LOGO_PATH;

const AuthorizationLayout = () => {
  return (
    <div className='page authorization'>
      <main className='content'>
        <a href={process.env.REACT_APP_LINK_HOME_PAGE} target='_blank' rel='noreferrer' className='icon-logo-wrapper'>
          <img src={logoPath} alt='Logo' className='icon-logo' />
        </a>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default AuthorizationLayout;
