import { useContext, useEffect, useState } from 'react';

import { PopUp } from 'uikit/PopUp/PopUp';
import Button from 'uikit/Button/Button';
import i18nContext from 'components/i18n-context';
import ComplexTranslate from 'components/ComplexTranslate';
import { CountDown } from 'components/common/CountDown/CountDown';
import { runLogout } from 'services/requestAgent';
import {
  isActiveSession,
  logoutActiveSessionWithoutToken,
  setLastUserActivityDateTime,
  LAST_USER_ACTIVITY_DATE_TIME
} from 'services/authUtils';
import { ReactComponent as LogOutIcon } from 'assets/log-out-icon.svg';

import './PopUpScheme.scss';
import { SESSION_STORAGE_CONSTANTS } from '../constants';

let autoLogoutModelId = null;

export const PopUpSessionAutoLogOutScheme = () => {
  const i18n = useContext(i18nContext);

  const [isActiveProlongationModel, setProlongationModelStatus] = useState(false);

  useEffect(() => {
    startPopupTimeout();
    // For close session in other tabs
    window.addEventListener('storage', ({ key, newValue }) => {
      // logout other tabs when logout was execute on another
      if (key === LAST_USER_ACTIVITY_DATE_TIME && !newValue) {
        logoutActiveSessionWithoutToken();
      }
    });
    return removePopupTimeout;
  }, []);

  const startPopupTimeout = () => {
    autoLogoutModelId = setTimeout(() => {
      if (!isActiveSession()) {
        setProlongationModelStatus(true);
      } else {
        removePopupTimeout();
        startPopupTimeout();
      }
    }, process.env.REACT_APP_PROLONGATION_MODAL_APPEARING_TIME * 1000);
  };

  const removePopupTimeout = () => {
    clearTimeout(autoLogoutModelId);
  };

  const resetPopupTimeOut = () => {
    removePopupTimeout();
    setProlongationModelStatus(false);
    startPopupTimeout();
  };

  const handleSuccessClick = () => {
    setLastUserActivityDateTime();
    resetPopupTimeOut();
  };

  // check for active session when inactive tab execute logout through timer end
  const onEndCountDown = () => (!isActiveSession() ? handleCancelClick(true) : resetPopupTimeOut());

  const handleCancelClick = (onEndCountDown) => {
    onEndCountDown && window.sessionStorage.setItem(SESSION_STORAGE_CONSTANTS.SESSION_ENDED, 'sessionEnded');
    setProlongationModelStatus(false);
    runLogout();
  };

  return (
    <PopUp alignOnCenter show={isActiveProlongationModel} isHighPriority onClose={() => {}}>
      <div className='scheme-wrapper pop-up-logOut'>
        <LogOutIcon className='icon-hit' />
        <h2 className='scheme-success-header'>{i18n.getMessage('popUp.sessionAutoLogOut.header')}</h2>
        <div className='logOut-description-wrapper'>
          <ComplexTranslate
            text={'popUp.sessionAutoLogOut.description'}
            interpolation={{
              inactivity: (
                <span className='logOut-inactivity'>
                  <CountDown
                    duration={Number(process.env.REACT_APP_PROLONGATION_MODAL_AUTO_LOGOUT_TIMER)}
                    onEnd={onEndCountDown}
                  />
                  {i18n.getMessage('popUp.sessionAutoLogOut.inactivity')}
                </span>
              )
            }}
          />
        </div>
        <p className='scheme-success-message'>{i18n.getMessage('popUp.sessionAutoLogOut.description2')}</p>
        <div className='scheme-wrapper-buttons'>
          <Button className='scheme-success-button scheme-button' size='large' onClick={handleSuccessClick}>
            {i18n.getMessage('session.autologout.btn.ok')}
          </Button>
          <Button className='scheme-button' size='large' type='outline' onClick={handleCancelClick}>
            {i18n.getMessage('session.autologout.btn.cancel')}
          </Button>
        </div>
      </div>
    </PopUp>
  );
};
