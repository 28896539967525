/* eslint-disable react-hooks/exhaustive-deps */
import PaymentForm from 'components/common/PaymentForm';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useEffect } from 'react';

const NewPayment = ({ paymentStore }) => {
  useEffect(() => {
    return () => {
      paymentStore.resetPaymentStore();
    };
  }, []);

  return (
    <section className='right-section'>
      <PaymentForm />
    </section>
  );
};

NewPayment.propTypes = {
  paymentStore: MobXPropTypes.observableObject
};

export default inject('paymentStore')(observer(NewPayment));
