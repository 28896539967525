import PropTypes from 'prop-types';
import { ReactComponent as AddNewCardIcon } from 'assets/add-new-card-icon.svg';
import '../Cards.scss';

export const NewCard = ({ cardType, title, description, handleClick, isDisabled, isFirstCard }) => {
  return (
    <button
      className={`new-card-wrapper ${!isFirstCard ? 'not-first-card' : ''}`}
      onClick={() => handleClick(cardType)}
      disabled={isDisabled}
    >
      <AddNewCardIcon />
      <span className='new-card-title'>{title}</span>
      {isFirstCard && description && <span className='new-card-description'>{description}</span>}
    </button>
  );
};

NewCard.propTypes = {
  cardType: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isFirstCard: PropTypes.bool.isRequired
};
