import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import i18nContext from 'components/i18n-context';
import Input from 'uikit/Input/Input';
import Button from 'uikit/Button/Button';
import { AuthorizationBackButton } from 'components/common/AuthorizationBackButton/AuthorizationBackButton';
import { getErrorMessageForAlert, prepareFieldErrors } from 'services/utils';
import { getPasswordValidation } from '../Registration/utils';
import Alert from '../../uikit/Alert/Alert';

export const ChangePassword = ({ onSubmitStep, setPassword, error: storeAPIError }) => {
  const i18n = useContext(i18nContext);
  const serverFieldsErrors = prepareFieldErrors(i18n, storeAPIError);

  const form = useFormik({
    validateOnChange: false,
    initialValues: {
      password: null,
      repeat_password: null
    },
    validationSchema: Yup.object({
      password: getPasswordValidation(),
      repeat_password: Yup.string()
        .nullable(true)
        .required(i18n.getMessage('error.passwordEmpty'))
        .oneOf([Yup.ref('password')], i18n.getMessage('error.passwordsDontMatch'))
    }),
    onSubmit: (values) => {
      onSubmitStep(values.password);
    }
  });

  const { values, errors, handleSubmit, handleChange, submitCount } = form;

  const handleChangePassword = (e) => {
    handleChange(e);
    setPassword(e.target.value);
  };

  return (
    <section className='authorization-form email'>
      <h1 className='authorization-title'>{i18n.getMessage('recovery.title.changePassword')}</h1>
      <Alert type={'warning'} message={storeAPIError ? getErrorMessageForAlert(i18n, storeAPIError) : ''} />
      <div className='sms-description'>
        {i18n.getMessage('recovery.changePassword.description', {
          appTitle: process.env.REACT_APP_PAYMENT_INSTITUTION
        })}
      </div>
      <form action='#' id='send' onSubmit={handleSubmit} className='form'>
        <div className='form-inputs-wrapper'>
          <Input
            type={'password'}
            name={'password'}
            label={i18n.getMessage('settings.form.label.newPassword')}
            placeholder={i18n.getMessage('register.password.change.placeholder')}
            value={values.password}
            error={errors?.password || serverFieldsErrors.password}
            initialStatus={submitCount}
            onChange={handleChangePassword}
            isRequired={true}
            subText={i18n.getMessage('register.password.subText', { amount: '7-30' })}
          />
          <Input
            type={'password'}
            name={'repeat_password'}
            label={i18n.getMessage('settings.form.label.repeatPassword')}
            placeholder={i18n.getMessage('register.password.change.placeholder')}
            value={values.repeat_password}
            error={errors?.repeat_password || serverFieldsErrors.password}
            initialStatus={submitCount}
            onChange={handleChangePassword}
            isRequired={true}
            subText={i18n.getMessage('register.password.subText', { amount: '7-30' })}
          />
        </div>
        <Button
          type={'primary'}
          roleType={'submit'}
          size={'large'}
          fullWidth={true}
          onClick={() => {}}
          className='password-recovery-button'
        >
          {i18n.getMessage('recovery.button.confirm')}
        </Button>
      </form>
      <AuthorizationBackButton type={'register'} />
    </section>
  );
};

ChangePassword.propTypes = {
  onSubmitStep: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  error: PropTypes.any
};
