import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ConfirmChangePasswordIcon } from 'assets/Message_open_error.svg';
import i18nContext from 'components/i18n-context';
import Button from 'uikit/Button/Button';
import './PopUpScheme.scss';

const PopUpChangePasswordConfirmationScheme = ({ onClose, onConfirm }) => {
  const i18n = useContext(i18nContext);

  return (
    <div className='scheme-wrapper pop-up-success'>
      <ConfirmChangePasswordIcon />
      <h2 className='scheme-success-header'>{i18n.getMessage('settings.confirmPopup.header')}</h2>
      <p className='scheme-success-message'>
        {i18n.getMessage('settings.confirmPopup.message1')}
        <br />
        {i18n.getMessage('settings.confirmPopup.message2')}
      </p>
      <div className='scheme-wrapper-buttons'>
        <Button className='scheme-button' size='large' type='outline' roleType={'button'} onClick={onConfirm}>
          {i18n.getMessage('settings.confirmPopup.btn.confirm')}
        </Button>
        <Button className='scheme-confirm-button scheme-button' size='large' onClick={onClose}>
          {i18n.getMessage('settings.confirmPopup.btn.cancel')}
        </Button>
      </div>
    </div>
  );
};

PopUpChangePasswordConfirmationScheme.propTypes = {
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  icon: PropTypes.elementType,
  header: PropTypes.string,
  message: PropTypes.string,
  closeButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  confirmButtonRoleType: PropTypes.string
};

export default PopUpChangePasswordConfirmationScheme;
