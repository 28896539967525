import { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import i18nContext from 'components/i18n-context';
import Input from 'uikit/Input/Input';
import Button from 'uikit/Button/Button';
import Alert from 'uikit/Alert/Alert';
import { AuthorizationBackButton } from 'components/common/AuthorizationBackButton/AuthorizationBackButton';
import { emailRegExp, getErrorMessageForAlert, replaceSpaces } from 'services/utils';

export const Email = ({ onSubmit, error: storeAPIError, userName, recoveryLinkError }) => {
  const i18n = useContext(i18nContext);
  const [validation, setValidation] = useState(false);

  useEffect(() => {
    setValidation(false);
  }, []);

  const form = useFormik({
    validateOnChange: validation,
    initialValues: {
      username: userName
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .nullable(true)
        .trim()
        .max(50, i18n.getMessage('error.emailSize'))
        .matches(emailRegExp, { message: i18n.getMessage('register.error.invalidEmail') })
        .required(i18n.getMessage('login.error.emptyFiled', { field: 'email' }))
    }),
    onSubmit: (values) => {
      onSubmit(replaceSpaces(values.username));
    }
  });

  const { values, errors, handleSubmit, handleChange, submitCount } = form;

  return (
    <section className='authorization-form email'>
      <h1 className='authorization-title'>{i18n.getMessage('recovery.title.email')}</h1>
      <Alert
        type={'warning'}
        message={
          (storeAPIError && getErrorMessageForAlert(i18n, storeAPIError)) ||
          (recoveryLinkError && i18n.getMessage('error.recoveryLink.invalidExpired'))
        }
      />
      <div className='sms-description'>
        {i18n.getMessage('recovery.email.description', { appTitle: process.env.REACT_APP_PAYMENT_INSTITUTION })}
      </div>
      <form action='#' id='send' onSubmit={handleSubmit} className='form'>
        <div className='form-inputs-wrapper'>
          <Input
            type={'text'}
            name={'username'}
            value={replaceSpaces(values.username)}
            label={i18n.getMessage('register.label.email')}
            placeholder={i18n.getMessage('register.email.change.placeholder')}
            error={errors?.username || (storeAPIError?.fields && storeAPIError.fields.username)}
            initialStatus={submitCount}
            onFocus={() => setValidation(true)}
            onChange={handleChange}
          />
        </div>
        <Button type={'primary'} roleType={'submit'} size={'large'} fullWidth={true} onClick={() => {}}>
          {i18n.getMessage('recovery.button.text.send')}
        </Button>
      </form>
      <AuthorizationBackButton type={'register'} />
    </section>
  );
};

Email.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.any,
  recoveryLinkError: PropTypes.any,
  userName: PropTypes.string
};
