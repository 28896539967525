import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ActionButton } from 'uikit/ActionButton/ActionButton';
import { Status } from 'uikit/Status/Status';
import i18nContext from 'components/i18n-context';
import { parseDate } from 'components/utils';
import { amountFormattedValue } from 'services/utils';
import { TRANSACTIONS_TABLE_STATUS, TRANSACTION_TYPES } from 'components/common/constants';
import { ReactComponent as CalendarIcon } from 'assets/calendar-icon.svg';
import { ReactComponent as StatusIcon } from 'assets/mobile-transaciton-icon.svg';
import { ReactComponent as ProfileIcon } from 'assets/iconButtons/profile.svg';
import { ReactComponent as WalletIcon } from 'assets/wallet.svg';
import './LatestTransactions.scss';

export const TransactionItem = ({ transaction, onInfoClick, isMobile }) => {
  const i18n = useContext(i18nContext);
  const createdAt = parseDate(transaction.created_at);
  const isIncomingReturnType = TRANSACTION_TYPES.INCOMING === transaction.type;
  const amount = `${isIncomingReturnType ? '+' : '-'} ${amountFormattedValue(
    isIncomingReturnType && transaction.status !== TRANSACTIONS_TABLE_STATUS.failed
      ? transaction.source_amount
      : transaction.amount
  )} 
  ${transaction.currency_code}`;
  const transactionStatus = Object.keys(TRANSACTIONS_TABLE_STATUS).find(
    (key) => TRANSACTIONS_TABLE_STATUS[key] === transaction.status
  );

  if (isMobile) {
    return (
      <div className='transaction-item-mobile'>
        <div className='item-mobile-top'>
          <div className='item-mobile-date'>
            <CalendarIcon className='icon' />
            <p>{`${createdAt.date} ${createdAt.time}`}</p>
          </div>
          <div className='item-mobile-status'>
            <StatusIcon className='icon' />
            <Status color={transactionStatus} status={i18n.getMessage(`transaction.status.${transactionStatus}`)} />
          </div>
        </div>
        <div className='item-mobile-from-to'>
          <ProfileIcon className='icon' />
          <div>
            <p>{transaction.counterparty?.name}</p>
            <p>{transaction.counterparty?.iban || transaction.counterparty?.number}</p>
          </div>
        </div>
        <div className='item-mobile-amount'>
          <WalletIcon className='icon' />
          <p className='item-amount-text'>{amount}</p>
          <ActionButton onClick={onInfoClick} />
        </div>
      </div>
    );
  }

  return (
    <tr className='transaction-item'>
      <td className='transaction-date'>
        <p>{createdAt.date}</p>
        <p>{createdAt.time}</p>
      </td>
      <td className='transaction-status'>
        <Status color={transactionStatus} status={i18n.getMessage(`transaction.status.${transactionStatus}`)} />
      </td>
      <td className='transaction-from-to'>
        {transaction.type === 'OUTGOING' ? (
          <>
            <p className='iban-text'>{transaction.counterparty?.iban || transaction.counterparty?.number}</p>
            <p>{transaction?.recipient_name}</p>
          </>
        ) : (
          <>
            <p className='iban-text'>{transaction.counterparty?.iban || transaction.counterparty?.number}</p>
            <p>{transaction?.sender_name}</p>
          </>
        )}
      </td>
      <td className='transaction-purpose'>{transaction.comment}</td>
      <td className={`transaction-amount-commission ${transaction?.type}`}>
        <p className='amount'>{amount}</p>
        {transaction.transaction_type !== 'COMMISSION' && (
          <p className='commission-total'>
            {`- ${amountFormattedValue(transaction.total_commissions || 0)} ${transaction.currency_code}`}
          </p>
        )}
      </td>
      <td className='transaction-exclamation'>
        <ActionButton tooltipText={i18n.getMessage('popUp.paymentDetails')} onClick={onInfoClick} />
      </td>
    </tr>
  );
};

TransactionItem.propTypes = {
  transaction: PropTypes.object.isRequired,
  onInfoClick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool
};
