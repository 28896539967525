import enGeneral from './en.json';
import enCountries from './countries/countries_en.json';

import ru from './ru.json';

export default {
  en: {
    ...enGeneral,
    ...enCountries
  },
  ru
};
