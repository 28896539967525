import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as GoogleAuthenticatorIcon } from 'assets/google-authenticator-icon.svg';
import i18nContext from 'components/i18n-context';
import Button from 'uikit/Button/Button';

export const PopUpGoogle2FADisableConfirmationScheme = ({ onClose, onConfirm }) => {
  const i18n = useContext(i18nContext);

  return (
    <div>
      <div className='scheme-wrapper pop-up-google2fa settings-google2fa-popup'>
        <GoogleAuthenticatorIcon className='google2fa_disable_icon' />
        <h2 className='scheme-success-header'>{i18n.getMessage('popUp.google2fa.disableConfirmation.title')}</h2>
        <p className='scheme-success-message'>{i18n.getMessage('popUp.google2fa.disableConfirmation.description')}</p>
        <div className='scheme-wrapper-buttons'>
          <Button className='scheme-button' size='large' type='outline' onClick={onConfirm}>
            {i18n.getMessage('popUp.google2fa.disableConfirmation.btn.confirm')}
          </Button>
          <Button className='scheme-error-button scheme-button' size='large' onClick={() => onClose()}>
            {i18n.getMessage('popUp.google2fa.disableConfirmation.btn.cancel')}
          </Button>
        </div>
      </div>
    </div>
  );
};

PopUpGoogle2FADisableConfirmationScheme.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
};
