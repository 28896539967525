import { useContext } from 'react';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import Button from 'uikit/Button/Button';
import { ReactComponent as ContactUsIcon } from 'assets/contact-us-icon.svg';
import { ReactComponent as PlusIcon } from 'assets/plus-white.svg';
import '../Messages.scss';

const NoMessages = ({ handleClickNewMessageButton, showWriteNewMessage }) => {
  const i18n = useContext(i18nContext);

  return (
    <div className='messages-empty-container'>
      <div className='messages-empty-wrapper'>
        <div className='messages-empty-icon'>
          <ContactUsIcon />
        </div>
        <span className='messages-empty-header'>{i18n.getMessage('messages.empty.header')}</span>
        <span className='messages-empty-body'>{i18n.getMessage('messages.empty.body')}</span>
        {showWriteNewMessage && (
          <Button
            className='messages-empty-button'
            type={'primary'}
            onClick={handleClickNewMessageButton}
            roleType={'button'}
            size={'large'}
          >
            <span className='messages-empty-button-label'>{i18n.getMessage('messages.button.writeNewMessage')}</span>
            <PlusIcon />
          </Button>
        )}
      </div>
    </div>
  );
};

NoMessages.propTypes = {
  handleClickNewMessageButton: PropTypes.func.isRequired,
  showWriteNewMessage: PropTypes.bool.isRequired
};

export default NoMessages;
