import PropTypes from 'prop-types';
import { classNames } from '../utils';
import './Container.scss';

export const Container = ({ header, children, className, isMaxWidthUnset }) => {
  const containerClasses = classNames({
    'uikit-container': true,
    'uikit-container-max-width-unset': !!isMaxWidthUnset,
    [className]: true
  });

  return (
    <div className={containerClasses}>
      <div className='container-header'>
        <p className='container-header-text'>{header}</p>
      </div>
      {children}
    </div>
  );
};

Container.propTypes = {
  header: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  isMaxWidthUnset: PropTypes.bool
};
