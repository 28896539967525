import { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import i18nContext from 'components/i18n-context';
import { AuthorizationBackButton } from 'components/common/AuthorizationBackButton/AuthorizationBackButton';
import ComplexTranslate from 'components/ComplexTranslate';
import Alert from 'uikit/Alert/Alert';
import { ReactComponent as EmailIcon } from 'assets/email-icon.svg';
import { SecurityInput } from '../../uikit/SecurityInput/SecurityInput';
import { CountDown } from '../common/CountDown/CountDown';
import { getErrorMessageForAlert } from '../../services/utils';

const EXCEEDED_ATTEMPTS_ERROR = 'EMAIL_CONFIRMATION_EXCEEDED_ATTEMPTS';
const RESEND_TIMEOUT = 60;
const CODE_SENT_TIMEOUT = 'CODE_SEND_TIMEOUT';

export const EmailConfirmation = ({ email, backToEmail, error, handleCode, resendCode }) => {
  const i18n = useContext(i18nContext);
  const [sentMessage, setSentMessage] = useState('');
  const [showCountDown, setShowCountDown] = useState(true);

  const handleSubmit = (verificationCode) => {
    handleCode(verificationCode);
    setSentMessage('');
  };

  const handleResendCode = async () => {
    setShowCountDown(true);
    await resendCode();
    setSentMessage(i18n.getMessage('pin.alert.sent'));
  };

  return (
    <section className='authorization-form email-confirmation'>
      <EmailIcon className='svg-email-icon registration-icon' />
      <Alert type={'warning'} message={(error && getErrorMessageForAlert(i18n, error, false)) || sentMessage} />
      <h1 className='authorization-title'>{i18n.getMessage('recovery.title.emailConfirmation')}</h1>
      <div className='sms-description'>
        {i18n.getMessage('recovery.emailCodeConfirmation.description', { email: email })}
      </div>
      <div className='change-email-wrapper'>
        <ComplexTranslate
          text={'recovery.emailConfirmation.description.changeEmail'}
          interpolation={{
            changeEmail: (
              <button onClick={() => backToEmail()} className='unset link-normal'>
                {i18n.getMessage('recovery.emailConfirmation.changeEmail')}
              </button>
            )
          }}
        />
      </div>
      <SecurityInput
        type={'email'}
        error={!!error}
        disabled={error?.code === EXCEEDED_ATTEMPTS_ERROR}
        onComplete={handleSubmit}
      />
      <div className='resend-wrapper'>
        {!showCountDown ? (
          <ComplexTranslate
            text={'sms.description.resend'}
            interpolation={{
              resendCode: (
                <button
                  className='unset link-normal'
                  disabled={error?.code === CODE_SENT_TIMEOUT}
                  onClick={(e) => handleResendCode(e)}
                >
                  {i18n.getMessage('sms.resendCode')}
                </button>
              )
            }}
          />
        ) : (
          <p className='resend-info'>
            {i18n.getMessage('sms.resentMessage') + ' '}
            <CountDown onEnd={() => setShowCountDown(false)} duration={RESEND_TIMEOUT} />
          </p>
        )}
      </div>
      <AuthorizationBackButton />
    </section>
  );
};

EmailConfirmation.propTypes = {
  email: PropTypes.string,
  backToEmail: PropTypes.func.isRequired,
  handleCode: PropTypes.func,
  resendCode: PropTypes.func,
  error: PropTypes.any
};
