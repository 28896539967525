import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import Loader from '../Loader';
import { filteringPaymentMethods } from '../PaymentForm/utils';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import i18nContext from 'components/i18n-context';
import { parseDate } from 'components/utils';
import {
  ACCOUNT_PERMISSIONS,
  DOCUMENT_STATUS,
  MAX_UPLOADED_FILES_SIZE,
  TRANSACTION_TYPES,
  TYPES_OF_TRANSACTION
} from 'components/common/constants';
import { amountFormattedValue, convertBytesToMegabytes } from 'services/utils';
import { AttachDoc } from 'uikit/AttachDoc/AttachDoc';
import Button from 'uikit/Button/Button';
import { FileDownloadButton } from 'uikit/FileDownloadButton/FileDownloadButton';
import './PopUpScheme.scss';

const getTransactionDetailsFields = (i18n, transaction) => {
  const createdAt = parseDate(transaction.created_at);
  const updatedAt = parseDate(transaction.updated_at);
  const isOutgoingTransaction = transaction.type === TRANSACTION_TYPES.OUTGOING;
  const amount = isOutgoingTransaction ? transaction.amount : transaction.source_amount;

  return [
    {
      label: i18n.getMessage('transaction.info.label.transactionNumber'),
      value: transaction.transaction_number
    },
    {
      label: i18n.getMessage('transaction.info.label.createdDate'),
      value: `${createdAt.date} ${createdAt.time}`
    },
    {
      label: i18n.getMessage('transaction.info.label.completedDate'),
      value: `${updatedAt.date} ${updatedAt.time}`
    },
    {
      label: i18n.getMessage('transaction.info.label.transactionStatus'),
      value: i18n.getMessage(`transaction.status.${transaction.status}`)
    },
    {
      label: i18n.getMessage('transaction.info.label.amount'),
      value: `${amountFormattedValue(amount)} ${transaction.currency_code}`
    },
    {
      label: i18n.getMessage('transaction.info.label.commission'),
      value: `${amountFormattedValue(transaction.total_commissions)} ${transaction.currency_code}`
    },
    {
      label: i18n.getMessage('transaction.info.label.totalAmount'),
      value: `${amountFormattedValue(isOutgoingTransaction ? transaction.source_amount : transaction.target_amount)} 
      ${transaction.currency_code}`
    },
    {
      label: i18n.getMessage('transaction.info.label.typeOfTransfer'),
      value:
        transaction.transaction_type !== TYPES_OF_TRANSACTION.NORMAL ? transaction.transaction_type : transaction.type
    },
    {
      label: i18n.getMessage('transaction.info.label.paymentMethod'),
      value: transaction.payment_method && i18n.getMessage(`transfer.paymentMethod.${transaction.payment_method}`)
    },
    {
      label: i18n.getMessage('transaction.info.label.recipientName'),
      value: transaction.recipient_name
    },
    {
      label: i18n.getMessage('transaction.info.label.recipientAccount'),
      value: isOutgoingTransaction ? transaction.counterparty.iban : transaction.iban
    },
    {
      label: i18n.getMessage('transaction.info.label.recipientBankCountry'),
      value: isOutgoingTransaction ? transaction.counterparty.bank_country : transaction.recipient_bank_country
    },
    {
      label: i18n.getMessage('transaction.info.label.recipientBankName'),
      value: isOutgoingTransaction ? transaction.counterparty.bank_name : transaction.recipient_bank
    },
    {
      label: i18n.getMessage('transaction.info.label.recipientBic'),
      value: isOutgoingTransaction ? transaction.counterparty.bic : transaction.recipient_bank_bic
    },
    {
      label: i18n.getMessage('transaction.info.label.recipientAccountNumber'),
      value: isOutgoingTransaction ? transaction.counterparty.account_number : transaction.account_number
    },
    {
      label: i18n.getMessage('transaction.info.label.recipientSortCode'),
      value: transaction.counterparty.sort_code
    },
    {
      label: i18n.getMessage('transaction.info.label.sendersName'),
      value: transaction.sender_name
    },
    {
      label: i18n.getMessage('transaction.info.label.sendersAccount'),
      value: isOutgoingTransaction ? transaction.iban : transaction.counterparty.iban
    },
    {
      label: i18n.getMessage('transaction.info.label.files'),
      value: transaction.documents?.map(({ name }) => name).join(',\n ')
    },
    {
      label: i18n.getMessage('transaction.info.label.commentToTransfer'),
      value: transaction.comment
    }
  ].filter((field) => field.value || field.value === 0);
};

const PopUpTransactionScheme = ({
  selectedTransaction,
  onClose,
  accountNumber,
  userWallets,
  paymentStore,
  transactionStore,
  accountPermissions
}) => {
  const navigate = useNavigate();
  const i18n = useContext(i18nContext);
  const transactionDetailsFields = getTransactionDetailsFields(i18n, selectedTransaction);
  const isReportDisabled = accountPermissions && !accountPermissions.includes(ACCOUNT_PERMISSIONS.CREATE_REPORT);
  const uploadedFiles = transactionStore?.transactionFiles?.map((file, index) => ({ name: file?.name, id: index }));
  const addDocuments = ({ target: { files } }) => transactionStore.addTransactionFiles(files);
  const removePaymentFile = (fileId) => transactionStore.removeTransactionFile(fileId);
  const clearTransactionFiles = () => transactionStore.clearTransactionFiles();

  useEffect(() => {
    if (selectedTransaction.document_status === DOCUMENT_STATUS.REQUESTED) {
      clearTransactionFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToPayment = () => {
    const wallet = userWallets.find((userWallet) => userWallet.wallet_number === selectedTransaction.wallet_number);
    paymentStore.resetPaymentStore();
    paymentStore.setSelectedWallet(wallet);
    paymentStore.setPreviousTransactionInfo(selectedTransaction);
    paymentStore.setIsRepeatPaymentStatus(true);
    paymentStore.setPaymentMethod(selectedTransaction.payment_method);
    paymentStore.setAvailablePaymentMethods(filteringPaymentMethods(wallet?.payment_methods));
    navigate('/new-payment');
  };

  const onPDFClick = (e) => {
    e.preventDefault();
    if (selectedTransaction?.is_pdf_available) {
      transactionStore.downloadCurrentPDF(
        selectedTransaction.account_number,
        selectedTransaction.transaction_number,
        selectedTransaction.type
      );
    }
  };

  const renderExportPDFButton = () => {
    if (selectedTransaction.transaction_type === 'COMMISSION') return null;

    return transactionStore.isFileLoading ? (
      <Loader className={'button-file'} />
    ) : (
      <FileDownloadButton isDisabled={isReportDisabled} onClick={onPDFClick} type='PDF' />
    );
  };

  const schemeLine = (label, value) => {
    return (
      <div key={label} className='scheme-line'>
        <p className='left'>{label}:</p>
        <p className='center'>{value}</p>
      </div>
    );
  };

  const uploadFiles = async () => {
    transactionStore.uploadTransactionFiles(accountNumber, selectedTransaction?.transaction_number).then(() => {
      onClose();
    });
  };

  return (
    <div className='scheme-wrapper'>
      <div className='scheme-header'>
        <p className='account'>{i18n.getMessage('popUp.paymentDetails')}</p>
        <CloseIcon className='scheme-close-icon' onClick={onClose} />
      </div>
      {selectedTransaction.document_status === DOCUMENT_STATUS.REQUESTED && (
        <div className='scheme-upload-documents-wrapper'>
          <div className='scheme-upload-documents-notice-wrapper'>
            <span className='scheme-upload-documents-notice-header'>
              {i18n.getMessage('popUp.paymentDetails.notice.header')}
            </span>
            <p className='scheme-upload-documents-notice-body'>
              {i18n.getMessage('popUp.paymentDetails.notice.body1')}
            </p>
            <p className='scheme-upload-documents-notice-body'>
              {i18n.getMessage('popUp.paymentDetails.notice.body2')}
            </p>
            <p className='scheme-upload-documents-notice-body'>
              {i18n.getMessage('popUp.paymentDetails.notice.body3')}
            </p>
          </div>
          <div className='buttons-wrapper upload-documents'>
            <AttachDoc
              label={i18n.getMessage('transfer.form.attachDoc.label')}
              className='hidden'
              type='file'
              size={MAX_UPLOADED_FILES_SIZE}
              files={uploadedFiles}
              onChange={addDocuments}
              onRemoveFiles={removePaymentFile}
              error={transactionStore.error}
              hintText={
                /* eslint-disable-next-line max-len */
                i18n.getMessage('attachDoc.hint.text', {
                  maxFilesSize: convertBytesToMegabytes(MAX_UPLOADED_FILES_SIZE)
                })
              }
            />
            <Button
              className='upload-button'
              type={'primary'}
              roleType={'submit'}
              size={'large'}
              isDisabled={transactionStore?.transactionFiles?.length === 0 || transactionStore.isFileLoading}
              onClick={uploadFiles}
            >
              {transactionStore.isFileLoading ? <Loader /> : i18n.getMessage('button.upload')}
            </Button>
          </div>
        </div>
      )}
      {transactionDetailsFields.map((field) => schemeLine(field.label, field.value))}
      {/* eslint-disable-next-line max-len */}
      <div
        className={`buttons-wrapper ${
          selectedTransaction.transaction_type === TYPES_OF_TRANSACTION.COMMISSION ? 'transaction-commission' : ''
        }`}
      >
        {renderExportPDFButton()}
        {/* eslint-disable-next-line max-len */}
        {selectedTransaction.transaction_type === TYPES_OF_TRANSACTION.NORMAL &&
          selectedTransaction.type === TRANSACTION_TYPES.OUTGOING && (
            <Button type='outline' size='large' onClick={goToPayment}>
              {i18n.getMessage('transaction.label.repeatPayment')}
            </Button>
          )}
      </div>
    </div>
  );
};

PopUpTransactionScheme.propTypes = {
  selectedTransaction: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  userWallets: PropTypes.array,
  paymentStore: MobXPropTypes.observableObject,
  transactionStore: MobXPropTypes.observableObject,
  accountPermissions: PropTypes.array,
  accountNumber: PropTypes.string
};

export default inject((stores) => ({
  accountNumber: stores.userStore.userData.account?.account_number,
  userWallets: stores.userStore?.userWallets,
  accountPermissions: stores.userStore?.currentAccountPermission,
  paymentStore: stores.paymentStore,
  transactionStore: stores.transactionsStore
}))(observer(PopUpTransactionScheme));
