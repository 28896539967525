import { useContext } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { ReactComponent as GoogleAuthenticatorIcon } from 'assets/google-authenticator-icon.svg';
import i18nContext from 'components/i18n-context';
import Button from 'uikit/Button/Button';

const Manage2FA = ({ userStore }) => {
  const i18n = useContext(i18nContext);
  const enableGoogle2FA = () => userStore.enableGoogle2FA();

  return (
    <div className='manage2fa-main-wrapper'>
      <div className='settings-container-manage2fa'>
        <div className='manage2fa-wrapper'>
          {userStore.isGoogle2FAEnabled && (
            <p className='manage2fa-status'>{i18n.getMessage('settings.manage2fa.status')}</p>
          )}
          <p className='manage2fa-title'>{i18n.getMessage('settings.manage2fa.title')}</p>
          <p className='manage2fa-description'>{i18n.getMessage('settings.manage2fa.description')}</p>
          <div className='manage2fa-actions-wrapper'>
            <Button className='manage2fa-button' roleType='button' onClick={enableGoogle2FA}>
              {userStore.isGoogle2FAEnabled
                ? i18n.getMessage('settings.manage2fa.button.resetCode')
                : i18n.getMessage('settings.manage2fa.button.enable')}
            </Button>
            {userStore.isGoogle2FAEnabled && (
              <Button
                className='manage2fa-button'
                roleType='button'
                onClick={() => userStore.setIsGoogle2FaDisablingConfirmation(true)}
              >
                {i18n.getMessage('settings.manage2fa.button.disable')}
              </Button>
            )}
          </div>
        </div>
        <div className='google2fa-icon-wrapper'>
          <GoogleAuthenticatorIcon className='google2fa-icon' />
        </div>
      </div>
    </div>
  );
};

Manage2FA.propTypes = {
  userStore: MobXPropTypes.observableObject
};

export default inject('userStore')(observer(Manage2FA));
