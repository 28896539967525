export const UNVERIFIED_ROUTE_PATHS = {
  DASHBOARD: '/dashboard',
  VERIFICATION: '/verification',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  MESSAGES: '/messages',
  MESSAGES_TOPIC: '/messages/:id',
  MESSAGES_CREATE_NEW_TOPIC: '/messages/create-new-topic'
};

export const UNAUTHORIZED_ROUTE_PATHS = {
  LOGIN: '/login',
  REGISTRATION: '/registration',
  ACCOUNT_REGISTRATION: '/registration/account',
  REGISTRATION_SUCCESS: '/registration/account/success',
  PASSWORD_RECOVERY: '/password_recovery'
};

export const ROUTE_PATHS = {
  ROOT: '/',
  NEW_PAYMENT: '/new-payment',
  CURRENCY_EXCHANGE: '/currency-exchange',
  BETWEEN_ACCOUNTS: '/between-accounts',
  ACCOUNT: 'account',
  CARDS: '/cards',
  CARDS_CREATE: '/cards/create',
  CARDS_CREATE_VIRTUAL: '/cards/create/virtual',
  CARDS_CREATE_PHYSICAL: '/cards/create/physical',
  CARD_SETTINGS: '/cards/:id',
  TRANSACTIONS: '/transactions',
  AUTHORIZATIONS: '/authorizations',
  INVOICES: '/invoices',
  FEES: '/fees',
  REPRESENTATIVES: '/representatives',
  ...UNVERIFIED_ROUTE_PATHS,
  ...UNAUTHORIZED_ROUTE_PATHS
};

export const INITIAL_PATH_AFTER_LOGIN = ROUTE_PATHS.DASHBOARD;
