import { makeAutoObservable, runInAction } from 'mobx';
import i18n from 'i18n';
import {
  MAX_UPLOADED_FILES_SIZE,
  AFTER_VALIDATE_PAYMENT_METHODS_SORTING_EXAMPLE,
  BEFORE_VALIDATE_PAYMENT_METHODS_SORTING_EXAMPLE,
  PAYMENT_PROVIDERS,
  MANUAL_PROVIDER_PAYMENT_METHODS_SORTING_EXAMPLE,
  PAYMENT_METHOD
} from 'components/common/constants';
import {
  filteringPaymentMethods,
  sortingPaymentMethods,
  moveSelectedMethodToFront
} from 'components/common/PaymentForm/utils';
import {
  transactionPaymentCheck,
  transactionPaymentConfirm,
  uploadDocuments,
  internalIbanValidate,
  transactionPaymentVerify,
  transactionPaymentVerificationCodeResend,
  internalTransactionCheck,
  internalTransactionCreate
} from 'services/requestAgent';
import { replaceSpaces } from 'services/utils';

class PaymentStore {
  isLoading = false;
  isCommissionLoading = false;
  isIbanCheckLoading = false;
  isFileUploading = false;
  error = null;
  confirmationPopupError = null;
  currentWallet = null;
  providerData = {};
  paymentMethod = null;
  availablePaymentMethods = [];

  uploadedFiles = [];
  isInternalIban = null;
  commission = {
    value: '',
    currency: '',
    type: ''
  };
  transactionForCreate = null;
  serverTransactionData = null;
  transactionNumber = null;
  isTransactionConfirmation = false;
  isSuccess = false;
  previousTransactionInfo = null;
  isRepeatPayment = false;
  ibanCredentials = null;
  iban = null;
  previousIban = null;

  constructor() {
    makeAutoObservable(this);
  }

  resetPaymentStore() {
    this.isLoading = false;
    this.isCommissionLoading = false;
    this.isFileUploading = false;
    this.error = null;
    this.providerData = {};
    this.paymentMethod = null;
    this.availablePaymentMethods = [];
    this.uploadedFiles = [];
    this.commission = {
      value: '',
      currency: '',
      type: ''
    };
    this.transactionForCreate = null;
    this.serverTransactionData = null;
    this.transactionNumber = null;
    this.isTransactionConfirmation = false;
    this.isSuccess = false;
    this.previousTransactionInfo = null;
    this.isRepeatPayment = false;
    this.confirmationPopupError = null;
    this.currentWallet = null;
    this.ibanCredentials = null;
    this.iban = null;
    this.previousIban = null;
  }

  setIsLoading(status) {
    this.isLoading = status;
    this.error = null;
  }

  setError(error) {
    this.error = error;
  }

  clearConfirmationPopupError() {
    this.confirmationPopupError = null;
  }

  clearIbanCredentials() {
    this.ibanCredentials = null;
    this.iban = null;
    this.previousIban = null;
  }

  setIsCommissionLoading(status) {
    this.isCommissionLoading = status;
    this.error = null;
  }

  setIsFileUploading(status) {
    this.isFileUploading = status;
    this.error = null;
  }

  setIsIbanCheckLoading(status) {
    this.isIbanCheckLoading = status;
    this.error = null;
  }

  setIsTransactionConfirmation(isConfirmation) {
    this.isTransactionConfirmation = isConfirmation;
  }

  setSelectedWallet(wallet) {
    this.currentWallet = wallet;
  }

  setPreviousTransactionInfo(transaction) {
    this.previousTransactionInfo = transaction;
  }

  setIsRepeatPaymentStatus(status) {
    this.isRepeatPayment = status;
  }

  setProviderData(providerData) {
    this.providerData = providerData;
  }

  setPaymentMethod(paymentMethod) {
    this.paymentMethod = paymentMethod;
  }

  setAvailablePaymentMethods(methods) {
    this.availablePaymentMethods = sortingPaymentMethods(
      methods,
      this.currentWallet?.currency,
      this.currentWallet?.transfer_provider === PAYMENT_PROVIDERS.MANUAL
        ? MANUAL_PROVIDER_PAYMENT_METHODS_SORTING_EXAMPLE
        : BEFORE_VALIDATE_PAYMENT_METHODS_SORTING_EXAMPLE
    );
  }

  setIban(iban) {
    this.iban = iban;
  }

  resetPaymentMethodInfo() {
    this.setAvailablePaymentMethods(filteringPaymentMethods(this.currentWallet?.payment_methods));
    this.setPaymentMethod(this.availablePaymentMethods[0]);
  }

  setWalletInfo(wallet) {
    this.setSelectedWallet(wallet);
    this.setAvailablePaymentMethods(filteringPaymentMethods(this.currentWallet?.payment_methods));
    if (this.availablePaymentMethods) {
      this.setPaymentMethod(this.availablePaymentMethods[0]);
    }
    this.setPaymentCommission({});
    this.setError(null);
    this.clearIbanCredentials();
    this.setPreviousTransactionInfo(null);
  }

  setPaymentCommission(commission) {
    this.commission = commission;
  }

  resetSuccess() {
    this.isSuccess = false;
  }

  removePaymentFile(fileId) {
    this.uploadedFiles = this.uploadedFiles.filter((file) => file?.id !== fileId);
  }

  uploadDocuments(accountNumber) {
    return async ({ target: { files } }) => {
      if (!Object.keys(files).length) {
        return null;
      }
      this.setIsFileUploading(true);
      try {
        const selectedFiles = [...files];
        const Data = new FormData();
        selectedFiles.forEach((file) => {
          if (file.size > MAX_UPLOADED_FILES_SIZE) {
            throw { code: 'REQUEST_HAS_BEEN_TERMINATED' };
          } else {
            Data.append('file', file, file?.name);
          }
        });
        const documentArray = await uploadDocuments(accountNumber, Data);

        runInAction(() => {
          this.isFileUploading = false;
          this.uploadedFiles = [
            ...this.uploadedFiles,
            ...documentArray.map((file) => ({ name: file?.name, id: file?.id }))
          ];
        });
      } catch (err) {
        runInAction(() => {
          this.isFileUploading = false;
          this.error = { type: 'attachDoc', ...err };
        });
      }
    };
  }

  async checkTransfer(accountNumber, data) {
    this.setIsCommissionLoading(true);
    try {
      const transactionData = await transactionPaymentCheck(accountNumber, data);

      runInAction(() => {
        if (this.isRepeatPayment) {
          this.isRepeatPayment = false;
        }
        this.isCommissionLoading = false;
        this.paymentMethod = transactionData.payment_method;
        this.commission = {
          value: transactionData.total_commissions,
          currency: transactionData.currency_code,
          type: transactionData.commission_type
        };
      });
    } catch (err) {
      runInAction(() => {
        this.isCommissionLoading = false;
        if (err.code === 'WALLET_NOT_FOUND') {
          this.error = { code: err.code, message: i18n.getMessage('error.WALLET_NOT_FOUND') };
        } else if (err.code === 'AMOUNT_CHECK_FAILED' && this.paymentMethod === PAYMENT_METHOD.SEPA_INSTANT) {
          this.paymentMethod = PAYMENT_METHOD.SEPA;
        }
        this.error = err;
      });
    }
  }

  async validateIban(iban, currentProviderType, walletNumber) {
    if (iban !== this.previousIban) {
      this.setIsIbanCheckLoading(true);
      this.setIban(iban);
      this.ibanCredentials = null;
      try {
        const ibanCredentials = await internalIbanValidate(walletNumber, replaceSpaces(iban));

        const sortedPaymentMethods = sortingPaymentMethods(
          filteringPaymentMethods(ibanCredentials?.payment_methods),
          this.currentWallet?.currency,
          this.currentWallet?.transfer_provider === PAYMENT_PROVIDERS.MANUAL
            ? MANUAL_PROVIDER_PAYMENT_METHODS_SORTING_EXAMPLE
            : AFTER_VALIDATE_PAYMENT_METHODS_SORTING_EXAMPLE
        );

        runInAction(() => {
          this.isInternalIban = true;
          this.ibanCredentials = ibanCredentials;

          if (this.isRepeatPayment) {
            this.availablePaymentMethods = moveSelectedMethodToFront(sortedPaymentMethods, this.paymentMethod);
            this.isRepeatPayment = false;
          } else {
            this.availablePaymentMethods = sortedPaymentMethods;
            this.paymentMethod = this.availablePaymentMethods[0];
          }
          if (ibanCredentials?.payment_methods.length === 0) {
            this.error = { fields: [{ field: 'iban', code: 'NO_PAYMENT_METHODS_AVAILABLE' }] };
          }
        });
      } catch (err) {
        runInAction(() => {
          if (err?.code && err.code === 'IBAN_CHECK_FAILED') {
            this.error = { fields: [{ field: 'iban', code: 'EXTERNAL_IBAN_VALIDATION_FAILED' }] };
          } else {
            this.error = err;
          }
        });
      } finally {
        runInAction(() => {
          this.previousIban = iban;
          this.isIbanCheckLoading = false;
        });
      }
    }
  }

  async validateTransfer({ accountNumber, providerData, model }) {
    this.setIsLoading(true);
    const data = model({
      providerTableData: providerData,
      data: {
        wallet: this.currentWallet,
        paymentMethod: this.paymentMethod,
        uploadedFiles: this.uploadedFiles.map((file) => file.id),
        ...this.ibanCredentials
      }
    });

    try {
      const transactionData = await transactionPaymentCheck(accountNumber, data);

      runInAction(() => {
        this.isLoading = false;
        this.isTransactionConfirmation = true;
        this.paymentMethod = transactionData.payment_method;
        this.transactionForCreate = {
          ...data,
          source_amount: transactionData.source_amount,
          target_amount: transactionData.target_amount
        };
        this.serverTransactionData = transactionData;
        this.commission = {
          value: transactionData.total_commissions,
          currency: transactionData.currency_code,
          type: transactionData.commission_type
        };
      });
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
        this.error = err;
      });
    }
  }

  createTransfer(accountNumber) {
    return async () => {
      this.setIsLoading(true);
      try {
        const { transaction_number: transactionNumber } = await transactionPaymentConfirm(accountNumber, {
          ...this.transactionForCreate
        });

        runInAction(() => {
          this.isLoading = false;
          this.transactionNumber = transactionNumber;
        });
      } catch (err) {
        runInAction(() => {
          this.isLoading = false;
          this.confirmationPopupError = err;
        });
      }
    };
  }

  async verifyPaymentSecurityCode(accountNumber, securityCode) {
    try {
      this.setIsLoading(true);
      await transactionPaymentVerify(accountNumber, this.transactionNumber, { verification_code: securityCode });

      runInAction(() => {
        this.isLoading = false;
        this.transactionNumber = null;
        this.isTransactionConfirmation = false;
        this.isSuccess = true;
        this.uploadedFiles = [];
        this.previousTransactionInfo = null;
        this.ibanCredentials = null;
        this.iban = null;
        this.previousIban = null;
      });
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
        this.confirmationPopupError = err;
      });
    }
  }

  async resendPaymentSecurityCode(accountNumber) {
    try {
      const { transaction_number: transactionNumber } = await transactionPaymentVerificationCodeResend(
        accountNumber,
        this.transactionNumber
      );

      runInAction(() => {
        this.transactionNumber = transactionNumber;
      });
    } catch (err) {
      runInAction(() => {
        this.confirmationPopupError = err;
      });
    }
  }

  async validateInternalTransfer({ accountNumber, providerData, model }) {
    this.setIsLoading(true);
    const data = model({
      providerTableData: providerData,
      data: {
        wallet: this.currentWallet,
        paymentMethod: this.paymentMethod,
        uploadedFiles: this.uploadedFiles.map((file) => file.id),
        ...this.ibanCredentials
      }
    });

    try {
      const transactionData = await internalTransactionCheck(accountNumber, data);

      runInAction(() => {
        this.isLoading = false;
        this.isTransactionConfirmation = true;
        this.paymentMethod = transactionData.payment_method;
        this.transactionForCreate = {
          ...data,
          source_amount: transactionData.source_amount,
          target_amount: transactionData.target_amount
        };
        this.serverTransactionData = transactionData;
        this.commission = {
          value: transactionData.total_commissions,
          currency: transactionData.currency_code,
          type: transactionData.commission_type
        };
      });
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
        this.error = err;
      });
    }
  }

  createInternalTransfer(accountNumber) {
    return async () => {
      this.setIsLoading(true);
      try {
        const { transaction_number: transactionNumber } = await internalTransactionCreate(accountNumber, {
          ...this.transactionForCreate
        });

        runInAction(() => {
          this.isLoading = false;
          this.transactionNumber = transactionNumber;
        });
      } catch (err) {
        runInAction(() => {
          this.isLoading = false;
          this.confirmationPopupError = err;
        });
      }
    };
  }
}

export default new PaymentStore();
