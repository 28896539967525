import i18n from 'i18n';
import { PAYMENT_PROVIDERS } from 'components/common/constants';

export const TIMEZONE = 'Europe/London';

const dateOpts = {
  timeZone: TIMEZONE,
  year: 'numeric',
  month: 'short',
  day: '2-digit'
};

const timeOpts = {
  timeZone: TIMEZONE,
  hour: '2-digit',
  minute: '2-digit'
};

export const parseDate = (data) => {
  const date = new Date(data);

  return {
    date: date.toLocaleString('en-UK', dateOpts),
    time: date.toLocaleString('en-UK', timeOpts) + ' GB'
  };
};

export const strToFloat = (str) => {
  const trimmed = str.trim().replace(/\.$/, '');
  const float = parseFloat(trimmed);

  return float === Number(trimmed) ? float : Number.NaN;
};

export const getWalletTitle = (wallet) => {
  return wallet.transfer_provider === PAYMENT_PROVIDERS.WALLESTER
    ? i18n.getMessage('wallet.title.cardAccount', { walletNumber: wallet.wallet_number })
    : wallet.transfer_provider === PAYMENT_PROVIDERS.MANUAL
    ? i18n.getMessage('wallet.title.account', { walletNumber: wallet.wallet_number })
    : wallet.multi_wallet_number
    ? wallet.multi_iban?.iban || wallet.multi_wallet_number
    : wallet.iban?.iban || wallet.wallet_number;
};

export const getWalletsOptionsForSelect = (wallets) =>
  wallets?.map((wallet) => {
    const title = getWalletTitle(wallet);

    return {
      key: wallet?.wallet_number,
      value: title,
      currency: wallet?.currency
    };
  });

export const extractBaseUrl = (url) => {
  const parts = url.split('/');
  return `/${parts.slice(1, 2).join('/')}`;
};
