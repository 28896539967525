import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// calling setInterval every second
const REFRESH_INTERVAL_SCALE = 1000;
let timeoutStart = new Date().getTime() / 1000;
const getCurrentSeconds = () => new Date().getTime() / 1000;

export const CountDown = ({ duration, onEnd }) => {
  const [remainingTime, setRemainingTime] = useState(duration);

  useEffect(() => {
    // in mount stage saving time mark for beginning of countdown
    timeoutStart = getCurrentSeconds();
  }, []);

  useEffect(() => {
    const countdown = setInterval(() => {
      if (remainingTime > 0) {
        // getting current time mark minus duration of countdown
        const timeoutCurrent = getCurrentSeconds() - duration;
        // getting difference between time marks we get how many seconds pass since beginning
        setRemainingTime(Math.ceil(timeoutStart - timeoutCurrent));
      }
      if (remainingTime <= 0) {
        onEnd();
      }
    }, REFRESH_INTERVAL_SCALE);

    return () => clearTimeout(countdown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingTime]);

  const formatTime = (time) => {
    const minutes = parseInt(time / 60, 10);
    const seconds = parseInt(time % 60, 10);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return <span className='timer'>{formatTime(Math.abs(remainingTime))}</span>;
};

CountDown.propTypes = {
  duration: PropTypes.number.isRequired,
  onEnd: PropTypes.func.isRequired
};
