import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as BlockCardIcon } from 'assets/block_card_pop_up.svg';
import { CARD_ACTIONS } from 'components/common/constants';
import i18nContext from 'components/i18n-context';
import Button from 'uikit/Button/Button';

export const PopUpBlockOrUnblockCardConfirmationScheme = ({ type, onClose, onConfirm }) => {
  const i18n = useContext(i18nContext);

  return (
    <div>
      <div className='scheme-wrapper pop-up-logOut'>
        <BlockCardIcon className={type === CARD_ACTIONS.BLOCK ? 'icon-error' : 'icon-hit'} />
        <h2 className='scheme-success-header'>{i18n.getMessage(`cards.popUp.cardAction.${type}.title`)}</h2>
        <p className='scheme-success-message'>{i18n.getMessage(`cards.popUp.cardAction.${type}.description`)}</p>
        <div className='scheme-wrapper-buttons'>
          <Button className='scheme-button' size='large' type='outline' onClick={onConfirm}>
            {i18n.getMessage('cards.popUp.cardAction.btn.confirm')}
          </Button>
          <Button
            className={
              type === CARD_ACTIONS.BLOCK ? 'scheme-error-button scheme-button' : 'scheme-success-button scheme-button'
            }
            size='large'
            onClick={() => onClose()}
          >
            {i18n.getMessage('cards.popUp.cardAction.btn.cancel')}
          </Button>
        </div>
      </div>
    </div>
  );
};

PopUpBlockOrUnblockCardConfirmationScheme.propTypes = {
  type: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
};
