/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { INITIAL_PATH_AFTER_LOGIN } from 'routes/constants';
import Login from './Login';
import Pin from './Pin';
import Sms from './Sms';
import Google2FA from './Google2FA';
import { AUTHORIZATION_TYPES } from '../common/constants';

const LoginStages = ({ authStore }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!authStore.isLoading && authStore.isLoginSuccess && authStore.isPinSuccess) {
      navigate(INITIAL_PATH_AFTER_LOGIN, { replace: true });
      authStore.reset();
    }
  }, [authStore.isLoading, authStore.isLoginSuccess, authStore.isPinSuccess]);

  const handleSetPhone = (phone) => authStore.setPhone(phone);

  const handleSetPassword = (password) => authStore.setPassword(password);

  const resendSecurityCode = () => authStore.resendSecurityCode();

  const handleCode = (code, disableSend) => {
    authStore.setCode(code);
    if (!disableSend) {
      authStore.sendCode();
    }
  };

  const handleSendAuthorizationData = () => authStore.sendAuthorizationData();

  const showSecondStep = () => {
    if (authStore.confirmationActionType === AUTHORIZATION_TYPES.PHONE) {
      return (
        <Sms
          phone={authStore.phone}
          sms={authStore.values.code}
          error={authStore.error}
          handleSms={handleCode}
          resendCode={resendSecurityCode}
        />
      );
    } else if (authStore.confirmationActionType === AUTHORIZATION_TYPES.GOOGLE2FA) {
      return (
        <Google2FA
          sms={authStore.values.code}
          error={authStore.error}
          handleSms={handleCode}
          resendCode={resendSecurityCode}
        />
      );
    } else {
      return <Pin pin={authStore.values.code} sendPin={handleCode} />;
    }
  };

  return (
    <div>
      {authStore.isLoginSuccess ? (
        showSecondStep()
      ) : (
        <Login
          phone={authStore.values.phone}
          password={authStore.values.password}
          setPhone={handleSetPhone}
          setPassword={handleSetPassword}
          sendAuthorizationData={handleSendAuthorizationData}
          error={authStore.error}
          isLoading={authStore.isLoading}
        />
      )}
    </div>
  );
};

LoginStages.propTypes = {
  authStore: MobXPropTypes.observableObject
};

export default inject('authStore')(observer(LoginStages));
