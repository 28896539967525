import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SuccessIcon } from 'assets/message_success.svg';
import i18nContext from 'components/i18n-context';

const PopUpSuccessScheme = ({ onClose, message, title, buttonText, className }) => {
  const i18n = useContext(i18nContext);

  return (
    <div className={`scheme-wrapper pop-up-success ${className}`}>
      <SuccessIcon />
      <h2 className='scheme-success-header'>{title ? title : i18n.getMessage('popUp.success')}</h2>
      {message && <p className='scheme-success-message'>{message}</p>}
      <button className='scheme-success-button' onClick={() => onClose()}>
        {buttonText || i18n.getMessage('popUp.success.button')}
      </button>
    </div>
  );
};

PopUpSuccessScheme.propTypes = {
  onClose: PropTypes.func,
  message: PropTypes.string,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  className: PropTypes.string
};

export default PopUpSuccessScheme;
