import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'assets/pop-up-close-icon.svg';
import { ReactComponent as CardInfoCopyIcon } from 'assets/card-info-copy-icon.svg';
import i18nContext from 'components/i18n-context';
import { chunkCardNumber } from 'components/Cards/utils';
import Loader from 'components/common/Loader';
import Button from 'uikit/Button/Button';
import { IconButton } from 'uikit/IconButton/IconButton';
import './PopUpScheme.scss';

export const PopUpCardDetailsScheme = ({ cardholderName, cardNumber, expirationDate, cvv, onClose }) => {
  const i18n = useContext(i18nContext);
  const [isCardDataUploaded, setIsCardDataUploaded] = useState(false);

  useEffect(() => {
    if (cardNumber && cvv) {
      setIsCardDataUploaded(true);
    }
  }, [cardNumber, cvv]);

  const copyAllData = async () => {
    const formattedData = `Card number\n${cardNumber}\nExpiration date\n${expirationDate}\nCVV\n${cvv}`;
    await navigator.clipboard.writeText(formattedData);
  };

  return (
    <div className='scheme-wrapper'>
      <div className='scheme-header'>
        <p className='scheme-header-text'>{i18n.getMessage('cards.popUp.cardDetails.title')}</p>
        <CloseIcon className='scheme-close-icon' onClick={onClose} />
      </div>
      <div className='scheme-content-wrapper'>
        {!isCardDataUploaded ? (
          <Loader />
        ) : (
          <>
            {cardholderName && (
              <div className='scheme-card-info-block'>
                <div className='scheme-card-info'>
                  <p className='scheme-card-info-label'>
                    {i18n.getMessage('cards.popUp.cardDetails.block.cardholderName')}
                  </p>
                  <p className='scheme-card-info-value'>{cardholderName}</p>
                </div>
                <IconButton
                  className='scheme-card-info-copy-button'
                  onClick={() => navigator.clipboard.writeText(cardholderName)}
                  Icon={CardInfoCopyIcon}
                />
              </div>
            )}
            <div className='scheme-card-info-block'>
              <div className='scheme-card-info'>
                <p className='scheme-card-info-label'>{i18n.getMessage('cards.popUp.cardDetails.block.cardNumber')}</p>
                <p className='scheme-card-info-value'>{chunkCardNumber(cardNumber)}</p>
              </div>
              <IconButton
                className='scheme-card-info-copy-button'
                onClick={() => navigator.clipboard.writeText(cardNumber)}
                Icon={CardInfoCopyIcon}
              />
            </div>
            <div className='scheme-card-info-block'>
              <div className='scheme-card-info'>
                <p className='scheme-card-info-label'>
                  {i18n.getMessage('cards.popUp.cardDetails.block.expirationDate')}
                </p>
                <p className='scheme-card-info-value'>{expirationDate}</p>
              </div>
              <IconButton
                className='scheme-card-info-copy-button'
                onClick={() => navigator.clipboard.writeText(expirationDate)}
                Icon={CardInfoCopyIcon}
              />
            </div>
            <div className='scheme-card-info-block'>
              <div className='scheme-card-info'>
                <p className='scheme-card-info-label'>{i18n.getMessage('cards.popUp.cardDetails.block.cvv')}</p>
                <p className='scheme-card-info-value'>{cvv}</p>
              </div>
              <IconButton
                className='scheme-card-info-copy-button'
                onClick={() => navigator.clipboard.writeText(cvv)}
                Icon={CardInfoCopyIcon}
              />
            </div>
          </>
        )}
      </div>
      <Button className='scheme-button' fullWidth={true} onClick={copyAllData}>
        {i18n.getMessage('cards.details.button.copyAll')}
      </Button>
    </div>
  );
};

PopUpCardDetailsScheme.propTypes = {
  cardholderName: PropTypes.string,
  cardNumber: PropTypes.string,
  expirationDate: PropTypes.string,
  cvv: PropTypes.string,
  onClose: PropTypes.func.isRequired
};
