/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import UserPanel from './UserPanel';
import { getAppRouteButtons } from 'components/common/MenuButtons';
import { INITIAL_PATH_AFTER_LOGIN } from 'routes/constants';
import { useMediaQuery } from 'services/useMediaQUery';
import { MEDIA_QUERY } from 'services/utils';
import { IconButton } from 'uikit/IconButton/IconButton';
import './Header.scss';

const headerLogo = process.env.REACT_APP_LOGO_PATH;
const headerLogoCompact = process.env.REACT_APP_LOGO_COMPACT_PATH;

const Header = ({ compact, collapseMenu }) => {
  const navigate = useNavigate();
  const MenuButtons = getAppRouteButtons();
  const isMobile = useMediaQuery(MEDIA_QUERY.MOBILE);

  const goToInitialPage = () => navigate(INITIAL_PATH_AFTER_LOGIN);

  return (
    <header className='header'>
      <div className={`header-content-wrapper ${compact && !isMobile ? 'active' : ''}`}>
        <div className={`logo-wrapper ${compact && !isMobile ? 'collapsed' : ''}`}>
          <img
            src={compact && !isMobile ? headerLogoCompact : headerLogo}
            alt='Logo'
            className={`header-logo ${compact && !isMobile ? 'collapsed' : ''}`}
            onClick={goToInitialPage}
          />
        </div>
        <div className='header-menu-links'>
          <IconButton
            className={'header-menu-button'}
            active={compact}
            Icon={compact ? MenuButtons[0].mobileIcon : MenuButtons[0].icon}
            text={MenuButtons[0].text}
            onClick={() => collapseMenu(!compact)}
          />
          {!isMobile && (
            <div className={`header-user-panel-wrapper ${isMobile ? 'collapsed' : ''}`}>
              <UserPanel />
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  compact: PropTypes.bool,
  collapseMenu: PropTypes.func
};

export default Header;
