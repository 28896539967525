import { useContext } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import i18nContext from 'components/i18n-context';
import { ReactComponent as ArrowRightIcon } from 'assets/card-arrow-right.svg';
import '../../../Cards.scss';

export const CardSettings = ({ cardsStore, isCardBlocked }) => {
  const i18n = useContext(i18nContext);

  return (
    <div className='card-settings-bar-wrapper'>
      <span className='card-settings-bar-title'>{i18n.getMessage('cards.details.virtual.settings.title')}</span>
      <div className='card-settings-bar-options-wrapper'>
        <button
          className={`card-settings-bar-option ${isCardBlocked ? 'option-disabled' : ''}`}
          disabled={isCardBlocked}
        >
          <span>{i18n.getMessage('cards.details.virtual.settings.cardLabel')}</span>
          <ArrowRightIcon />
        </button>
        <button
          className={`card-settings-bar-option ${isCardBlocked ? 'option-disabled' : ''}`}
          disabled={isCardBlocked}
        >
          <span>{i18n.getMessage('cards.details.virtual.settings.changePin')}</span>
          <ArrowRightIcon />
        </button>
        <button
          className={`card-settings-bar-option ${isCardBlocked ? 'option-disabled' : ''}`}
          disabled={isCardBlocked}
        >
          <span>{i18n.getMessage('cards.details.virtual.settings.onlinePurchasesLimit')}</span>
          <ArrowRightIcon />
        </button>
      </div>
    </div>
  );
};

CardSettings.propTypes = {
  cardsStore: MobXPropTypes.observableObject,
  isCardBlocked: PropTypes.bool
};

export default inject('cardsStore')(observer(CardSettings));
