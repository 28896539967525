/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { Outlet, useLocation } from 'react-router-dom';
import { filterWallesterWallets } from './utils';
import { ReactComponent as SimpleIcon } from 'assets/simple-icon.svg';
import { BackButton } from 'components/common/BackButton/BackButton';
import { ContactUs } from 'components/common/ContactUs/ContactUs';
import i18nContext from 'components/i18n-context';
import { ROUTE_PATHS } from 'routes/constants';
import './Cards.scss';

const Cards = ({ accountNumber, cardsStore, userStore }) => {
  const location = useLocation();
  const i18n = useContext(i18nContext);

  useEffect(() => {
    return () => {
      cardsStore.resetCardsStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const wallesterWallets = filterWallesterWallets(userStore.userWallets);

    cardsStore.initializeStoreState(accountNumber, wallesterWallets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.userWallets]);

  return (
    <>
      {cardsStore.isInitialized && filterWallesterWallets(userStore.userWallets).length === 0 ? (
        <ContactUs
          icon={<SimpleIcon />}
          header={i18n.getMessage('cards.noWallets.title')}
          description={i18n.getMessage('cards.noWallets.description')}
          link={process.env.REACT_APP_SUPPORT_EMAIL}
          button={i18n.getMessage('cards.noWallets.button')}
        />
      ) : (
        <section className='right-section'>
          {location.pathname !== ROUTE_PATHS.CARDS && <BackButton />}
          <Outlet />
        </section>
      )}
    </>
  );
};

Cards.propTypes = {
  accountNumber: PropTypes.string,
  cardsStore: MobXPropTypes.observableObject,
  userStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  accountNumber: stores.userStore.userData.account?.account_number,
  cardsStore: stores.cardsStore,
  userStore: stores.userStore
}))(observer(Cards));
