import { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'yup-phone-lite';
import PopUpSuccessScheme from '../common/PopUpScheme/PopUpSuccessScheme';
import { SESSION_STORAGE_CONSTANTS, SESSION_STORAGE_OPTIONS } from 'components/common/constants';
import { PopUpSessionEndedScheme } from 'components/common/PopUpScheme/PopUpSessionEndedScheme';
import i18nContext from 'components/i18n-context';
import { AuthorizationBackButton } from 'components/common/AuthorizationBackButton/AuthorizationBackButton';
import { getErrorMessageForAlert, prepareFieldErrors, replaceSpaces } from 'services/utils';
import { ROUTE_PATHS } from 'routes/constants';
import Alert from 'uikit/Alert/Alert';
import Button from 'uikit/Button/Button';
import Input from 'uikit/Input/Input';
import { PopUp } from 'uikit/PopUp/PopUp';
import './Login.scss';
import Loader from '../common/Loader';

const Login = ({ phone, setPhone, setPassword, sendAuthorizationData, error: storeAPIError, isLoading }) => {
  const i18n = useContext(i18nContext);
  const [isSessionEnded, setIsSessionEnded] = useState(
    window.sessionStorage.getItem(SESSION_STORAGE_CONSTANTS.SESSION_ENDED)
  );
  const [countryCode, setCountryCode] = useState(process.env.REACT_APP_DEFAULT_COUNTRY_CODE);

  const closeSessionEndedPopUp = () => {
    window.sessionStorage.removeItem(SESSION_STORAGE_CONSTANTS.SESSION_ENDED);
    setIsSessionEnded(false);
  };

  const form = useFormik({
    validateOnChange: false,
    initialValues: {
      phone: phone,
      password: ''
    },
    initialStatus: { password: true, username: true },
    validationSchema: Yup.object({
      phone: Yup.string()
        .nullable(true)
        .phone(countryCode.toUpperCase(), i18n.getMessage('error.fieldError.phone.Phone'))
        .required(i18n.getMessage('login.error.emptyPhone')),
      password: Yup.string()
        .nullable(true)
        .min(7, i18n.getMessage('error.PASSWORD_REQUIREMENTS'))
        .max(30, i18n.getMessage('error.fieldSize', { amount: '30' }))
        .required(i18n.getMessage('login.error.emptyFiled', { field: 'password' }))
      // COMMENTED OUT IN ORDER TO KEEP OLDER ACCOUNTS AVAILABLE, NEED TO MAKE SURE THIS REGEX MATCHES THE ONE ON BE
      // .required(i18n.getMessage('register.error.passwordSecurityRequirementsViolation'))
      // .matches(
      //   passwordRegExp,
      //   { message: i18n.getMessage('register.error.password', { amount: '7-30' }) }
      // )
    }),
    onSubmit: (values) => {
      setPhone(values.phone);
      setPassword(replaceSpaces(values.password));
      sendAuthorizationData();
    }
  });

  const { values, errors, handleSubmit, handleChange, submitCount, setFieldValue } = form;

  return (
    <section className='authorization-form login'>
      <h1 className='authorization-title'>{i18n.getMessage('login.title')}</h1>
      <Alert type={'warning'} message={storeAPIError && getErrorMessageForAlert(i18n, storeAPIError)} />
      <form action='#' id='send' onSubmit={handleSubmit} className='form'>
        <div className='form-inputs-wrapper'>
          <Input
            type={'phone'}
            label={i18n.getMessage('register.label.phone')}
            name={'phone'}
            value={values.phone}
            setValue={setFieldValue}
            onChange={handleChange}
            setCountryCode={setCountryCode}
            initialStatus={submitCount}
            isRequired={true}
            error={errors?.phone || (errors && prepareFieldErrors(i18n, errors)?.phone)}
            isApiError={!!storeAPIError}
          />
          <Input
            type={'password'}
            name={'password'}
            label={i18n.getMessage('login.label.password')}
            placeholder={i18n.getMessage('login.placeholder.password')}
            value={values.password}
            error={errors?.password || (storeAPIError?.fields && storeAPIError.fields.password)}
            isApiError={!!storeAPIError}
            autoComplete={'off'}
            initialStatus={submitCount}
            onChange={handleChange}
          />
        </div>
        <Button
          type={'primary'}
          roleType={'submit'}
          size={'large'}
          fullWidth={true}
          onClick={() => {}}
          isDisabled={isLoading}
        >
          {isLoading ? <Loader /> : i18n.getMessage('login.btn.login')}
        </Button>
        <div className='form-forgot-password'>
          <a href={ROUTE_PATHS.PASSWORD_RECOVERY} className='link-dark'>
            {i18n.getMessage('login.btn.forgot')}
          </a>
        </div>
      </form>
      <AuthorizationBackButton type={'register'} />
      <PopUp show={isSessionEnded} onClose={() => closeSessionEndedPopUp()}>
        {isSessionEnded === SESSION_STORAGE_OPTIONS.SESSION_ENDED ? (
          <PopUpSessionEndedScheme onClose={() => closeSessionEndedPopUp()} />
        ) : (
          <PopUpSuccessScheme
            onClose={() => closeSessionEndedPopUp()}
            message={i18n.getMessage('popUp.success.passwordRecovery')}
          />
        )}
      </PopUp>
    </section>
  );
};

Login.propTypes = {
  phone: PropTypes.string.isRequired,
  setPhone: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  sendAuthorizationData: PropTypes.func.isRequired,
  error: PropTypes.any,
  isLoading: PropTypes.bool
};

export default observer(Login);
