/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import PopUpSuccessScheme from '../PopUpScheme/PopUpSuccessScheme';
import { ClearJunctionTable, OpenPaydTable, LPBForm } from './ProviderForms';
import { filterPaymentWallets, getPaymentMethodsOptions } from './utils';
import i18nContext from 'components/i18n-context';
import { PAYMENT_PROVIDERS, FORM_TYPES, PAYMENT_METHOD } from 'components/common/constants';
import TransferConfirmationScheme from 'components/common/PopUpScheme/TransferConfirmationScheme';
import { debounce, getErrorMessageForAlert, amountFormattedValue } from 'services/utils';
import { Container } from 'uikit/Container/Container';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import Alert from 'uikit/Alert/Alert';
import { PopUp } from 'uikit/PopUp/PopUp';
import './PaymentForm.scss';
import { getWalletTitle } from '../../utils';

const SIMPLE_FORM = FORM_TYPES.SIMPLE;
const ADVANCED_FORM = FORM_TYPES.ADVANCED;

const PaymentForm = ({ accountNumber, userStore, paymentStore }) => {
  const i18n = useContext(i18nContext);
  const providerType = paymentStore.currentWallet?.transfer_provider;

  useEffect(() => {
    if (paymentStore.isSuccess) {
      userStore.getUserWallets();
    }
  }, [paymentStore.isSuccess]);

  const activeTab = [PAYMENT_METHOD.SWIFT, PAYMENT_METHOD.TARGET2].includes(paymentStore.paymentMethod)
    ? ADVANCED_FORM
    : SIMPLE_FORM;

  // Preselecting wallet with Clear Junction provider
  useEffect(() => {
    const paymentWallets = filterPaymentWallets(userStore.userWallets);
    const hasWallets = paymentWallets.length > 0;
    const clearJunctionWallet = paymentWallets.find(
      ({ transfer_provider }) => transfer_provider === PAYMENT_PROVIDERS.CLEARJUNCTION
    );
    clearJunctionWallet && !paymentStore.currentWallet && paymentStore.setSelectedWallet(clearJunctionWallet);
    if (paymentStore.currentWallet) {
      handleWalletChange('', paymentStore.currentWallet.wallet_number);
    }
    if (!paymentStore.currentWallet && !clearJunctionWallet && hasWallets) {
      handleWalletChange('', walletsOptions[0].key);
    }
  }, [userStore.userWallets]);

  const walletsOptions = filterPaymentWallets(userStore.userWallets)?.map((wallet) => {
    const title = getWalletTitle(wallet);

    return {
      key: wallet?.wallet_number,
      value: title,
      currency: wallet?.currency
    };
  });

  const setProviderData = (data) => paymentStore.setProviderData(data);
  const getCommission = (accountNumber, data) => paymentStore.checkTransfer(accountNumber, data);
  const validateTransfer = (...args) => paymentStore.validateTransfer(...args);
  const removePaymentFile = (fileId) => paymentStore.removePaymentFile(fileId);
  // eslint-disable-next-line max-len
  const validateIban = (iban) =>
    paymentStore.validateIban(iban, providerType, paymentStore.currentWallet?.wallet_number);
  const clearIbanCredentials = () => paymentStore.clearIbanCredentials();
  const setError = (error) => paymentStore.setError(error);
  const setIban = (iban) => paymentStore.setIban(iban);
  const resetPaymentMethods = () => paymentStore.resetPaymentMethodInfo();
  const verifyPayment = (securityCode) => paymentStore.verifyPaymentSecurityCode(accountNumber, securityCode);
  const resendVerifyCode = () => paymentStore.resendPaymentSecurityCode(accountNumber);

  const validationProps = {
    i18n,
    accountNumber,
    onChange: setProviderData,
    onSubmit: validateTransfer,
    previousTransaction: paymentStore.previousTransactionInfo,
    currency: paymentStore?.currentWallet?.currency,
    paymentMethod: paymentStore?.paymentMethod,
    providerType
  };
  const formProps = {
    wallet: { ...paymentStore.currentWallet },
    commission: paymentStore.commission,
    isCommissionLoading: paymentStore.isCommissionLoading,
    paymentMethod: paymentStore.paymentMethod,
    resetPaymentMethods,
    uploadedFiles: paymentStore.uploadedFiles,
    removePaymentFile: removePaymentFile,
    accountNumber: accountNumber,
    getCommission: debounce(getCommission, 400),
    isRepeatPayment: paymentStore.isRepeatPayment,
    iban: paymentStore.iban,
    setIban,
    isInternalIban: paymentStore.isInternalIban,
    checkIban: validateIban,
    isIbanCheckLoading: paymentStore.isIbanCheckLoading,
    uploadDocuments: paymentStore.uploadDocuments(accountNumber),
    error: paymentStore.error,
    setError,
    isSuccess: paymentStore.isSuccess,
    isLoading: paymentStore.isLoading || paymentStore.isFileUploading,
    currency: paymentStore?.currentWallet?.currency,
    formType: activeTab,
    ibanCredentials: paymentStore?.ibanCredentials,
    clearIbanCredentials,
    validationProps,
    countries: paymentStore?.currentWallet?.countries,
    providerType
  };

  const getProviderTable = (providerType) => {
    switch (providerType) {
      case PAYMENT_PROVIDERS.CLEARJUNCTION: {
        return <ClearJunctionTable {...formProps} />;
      }
      case PAYMENT_PROVIDERS.OPENPAYD: {
        return <OpenPaydTable {...formProps} />;
      }
      case PAYMENT_PROVIDERS.LPB: {
        return <LPBForm {...formProps} />;
      }
      case PAYMENT_PROVIDERS.MANUAL: {
        return <OpenPaydTable {...formProps} />; // same as OP
      }
      default:
        return <div>{i18n.getMessage('transfer.provider.notFound')}</div>;
    }
  };

  const handleWalletChange = (name, data) => {
    const selectedWallet = filterPaymentWallets(userStore.userWallets).find(
      ({ wallet_number }) => wallet_number === data
    );

    if (
      paymentStore.currentWallet?.wallet_number !== selectedWallet?.wallet_number ||
      (!paymentStore.error && paymentStore.availablePaymentMethods.length === 0)
    ) {
      paymentStore.setWalletInfo(selectedWallet);
    }
  };

  const handlePaymentMethodChange = (name, data) => {
    paymentStore.setPaymentMethod(data);
  };

  const getAvailableBalance = () =>
    paymentStore?.currentWallet &&
    i18n.getMessage('sendMoney.topLabel', {
      available: amountFormattedValue(paymentStore?.currentWallet.available),
      currency: paymentStore?.currentWallet.currency
    });

  return (
    <Container className='send-money' header={i18n.getMessage('container.sendMoney')}>
      <Alert
        className='send-money-alert'
        type={'warning'}
        message={
          paymentStore.error && !paymentStore.error?.type ? getErrorMessageForAlert(i18n, paymentStore.error) : ''
        }
      />
      <div className='send-money-wrapper'>
        <div className='inputs-wrapper'>
          <InputDropDown
            isRequired={true}
            topLabel={getAvailableBalance()}
            label={i18n.getMessage('transfer.form.accountNumber.label')}
            value={paymentStore.currentWallet?.wallet_number}
            currency={paymentStore.currentWallet?.currency}
            onChange={handleWalletChange}
            options={walletsOptions}
            isMulti={false}
          />
          <InputDropDown
            isRequired={true}
            label={i18n.getMessage('transfer.form.paymentMethod.label')}
            value={paymentStore?.paymentMethod}
            onChange={handlePaymentMethodChange}
            options={getPaymentMethodsOptions(paymentStore?.availablePaymentMethods)}
            isMulti={false}
          />
        </div>
        {getProviderTable(providerType)}
      </div>
      <PopUp
        className={paymentStore.isSuccess ? 'transaction-success' : 'transaction-info'}
        show={paymentStore.isTransactionConfirmation || paymentStore.isSuccess}
        alignOnCenter={paymentStore.isSuccess}
        onClose={
          paymentStore.isSuccess
            ? () => paymentStore.resetSuccess()
            : () => paymentStore.setIsTransactionConfirmation(false)
        }
      >
        {paymentStore.isSuccess ? (
          <PopUpSuccessScheme
            onClose={() => paymentStore.resetSuccess()}
            message={i18n.getMessage('popUp.message.moneyTransfer')}
          />
        ) : (
          <TransferConfirmationScheme
            transferData={{
              ...paymentStore.serverTransactionData,
              uploadedFiles: paymentStore.uploadedFiles
            }}
            error={paymentStore.confirmationPopupError}
            generateSecurityCode={paymentStore.createTransfer(accountNumber)}
            resendSecurityCode={resendVerifyCode}
            clearError={() => paymentStore.clearConfirmationPopupError()}
            onConfirm={verifyPayment}
            onClose={() => paymentStore.setIsTransactionConfirmation(false)}
          />
        )}
      </PopUp>
    </Container>
  );
};

PaymentForm.propTypes = {
  paymentStore: MobXPropTypes.observableObject,
  userStore: MobXPropTypes.observableObject,
  accountNumber: PropTypes.string
};

export default inject((stores) => ({
  accountNumber: stores.userStore.userData.account?.account_number,
  userStore: stores.userStore,
  paymentStore: stores.paymentStore
}))(observer(PaymentForm));
