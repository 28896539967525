import AuthorizationsList from 'components/common/AuthorisationsList/AuthorizationsList';
import './CardsAuthorizations.scss';

export const CardsAuthorizations = () => {
  return (
    <section className='right-section authorizations'>
      <AuthorizationsList />
    </section>
  );
};
