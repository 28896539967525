import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CalendarIcon } from 'assets/calendar-icon.svg';
import { ReactComponent as StatusIcon } from 'assets/mobile-transaciton-icon.svg';
import { ReactComponent as ProfileIcon } from 'assets/iconButtons/profile.svg';
import { ReactComponent as WalletIcon } from 'assets/wallet.svg';
import i18nContext from 'components/i18n-context';
import { CARD_AUTHORIZATIONS_STATUS } from 'components/common/constants';
import { parseDate } from 'components/utils';
import { amountFormattedValue } from 'services/utils';
import { ActionButton } from 'uikit/ActionButton/ActionButton';
import { Status } from 'uikit/Status/Status';
import './AuthorizationsList.scss';

export const AuthorizationItem = ({ authorization, onInfoClick, isMobile }) => {
  const i18n = useContext(i18nContext);
  const updatedAt = parseDate(authorization?.updated_at);
  const amount = `- ${amountFormattedValue(authorization.amount)} ${authorization.currency}`;
  const authorizationStatus = Object.keys(CARD_AUTHORIZATIONS_STATUS).find(
    (key) => CARD_AUTHORIZATIONS_STATUS[key] === authorization.status
  );

  if (isMobile) {
    return (
      <div className='authorization-item-mobile'>
        <div className='item-mobile-top'>
          <div className='item-mobile-date'>
            <CalendarIcon className='icon' />
            <p>{`${updatedAt.date} ${updatedAt.time}`}</p>
          </div>
          <div className='item-mobile-status'>
            <StatusIcon className='icon' />
            <Status
              color={authorizationStatus}
              status={i18n.getMessage(`cards.authorizations.status.${authorizationStatus}`)}
            />
          </div>
        </div>
        <div className='item-mobile-from-to'>
          <ProfileIcon className='icon' />
          <div>
            <p className='from-text'>{authorization.card_number}</p>
            <p>{authorization.merchant_name}</p>
          </div>
        </div>
        <div className='item-mobile-amount'>
          <WalletIcon className='icon' />
          <p className='item-amount-text'>{amount}</p>
          <ActionButton onClick={onInfoClick} />
        </div>
      </div>
    );
  }

  return (
    <tr className='authorization-item'>
      <td className='authorization-date'>
        <p>{updatedAt.date}</p>
        <p>{updatedAt.time}</p>
      </td>
      <td className='authorization-status'>
        <Status
          color={authorizationStatus}
          status={i18n.getMessage(`cards.authorizations.status.${authorizationStatus}`)}
        />
      </td>
      <td className='authorization-from-to'>
        <p className='from-text'>{authorization.card_number}</p>
        <p>{authorization.merchant_name}</p>
      </td>
      <td className={`authorization-amount-commission ${authorization?.type}`}>
        <p className='amount'>{amount}</p>
      </td>
      <td className='authorization-exclamation'>
        <ActionButton tooltipText={i18n.getMessage('popUp.paymentDetails')} onClick={onInfoClick} />
      </td>
    </tr>
  );
};

AuthorizationItem.propTypes = {
  authorization: PropTypes.object.isRequired,
  onInfoClick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool
};
