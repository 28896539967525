export default class UnknownError extends Error {
  constructor({ status, code, message = 'Error' }) {
    super(...arguments);
    this.name = this.constructor.name;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }

    this.status = status;
    this.code = code;
    this.message = message;
  }
}
