import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { TransactionDateFilter } from './TransactionDateFilter';
import TransactionRangeFilter from './TransactionRangeFilter';
import { ReactComponent as SearchIcon } from 'assets/search-icon.svg';
import { ReactComponent as FilterIcon } from 'assets/filter-mobile-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import i18nContext from 'components/i18n-context';
import { TRANSACTION_TYPES, ACCOUNT_PERMISSIONS, TRANSACTIONS_TABLE_STATUS } from 'components/common/constants';
import { getWalletTitle } from 'components/utils';
import Button from 'uikit/Button/Button';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import { FileDownloadButton } from 'uikit/FileDownloadButton/FileDownloadButton';
import 'react-datepicker/dist/react-datepicker.css';

const TransactionsFilter = ({
  accountNumber,
  transactionStatuses = [],
  transactionsStore,
  userWallets,
  isMobile,
  permissions
}) => {
  const i18n = useContext(i18nContext);
  const [isOpen, setIsOpen] = useState(false);

  const walletsOptions = userWallets?.map((wallet) => {
    const title = getWalletTitle(wallet);

    return {
      key: wallet?.wallet_number,
      value: title,
      currency: wallet?.currency
    };
  });

  const transactionTypeOptions = Object.keys(TRANSACTION_TYPES).map((type) => {
    return {
      key: type,
      value: i18n.getMessage(`transaction.type.${TRANSACTION_TYPES[type]}`)
    };
  });

  const currencyOptions = [...new Set(userWallets?.map(({ currency }) => currency))].map((currency) => {
    return {
      key: currency,
      value: currency
    };
  });

  const statusOptions = transactionStatuses?.reduce((acc, status) => {
    if (status !== TRANSACTIONS_TABLE_STATUS.acceptRequest && status !== TRANSACTIONS_TABLE_STATUS.rejectRequest) {
      acc.push({
        key: status,
        value: i18n.getMessage('transaction.status.' + status)
      });
    }
    return acc;
  }, []);

  const handleSetFilter = (name, value) => transactionsStore.setFilter(name, value);

  const handleSetDateFilter = (name, value) => {
    transactionsStore.clearPredefinedFilter();
    transactionsStore.setFilter(name, value);
  };

  const handleSearchTest = ({ target: { name, value } }) => {
    handleSetFilter(name, value);
    transactionsStore.getTransactionsWithDebounce(accountNumber);
  };

  const handleSetRangeFilter = (filterType, dateRang) => transactionsStore.setRangeFilter(filterType, dateRang);

  const handleDownloadPDF = (e) => {
    e.preventDefault();
    transactionsStore.downloadPDFList(accountNumber);
  };

  const handleDownloadCSV = (e) => {
    e.preventDefault();
    transactionsStore.downloadCSVList(accountNumber);
  };

  // When we don't have permission in account object -> main account
  const disableDownloadReport = permissions
    ? !permissions?.find((permission) => permission === ACCOUNT_PERMISSIONS.CREATE_REPORT)
    : false;

  if (isMobile && !isOpen) {
    return (
      <div className='transaction-filter-compact'>
        <TransactionRangeFilter name='tabs' value={transactionsStore.rangeFilter} onChange={handleSetRangeFilter} />
        <div className='filter-compact-wrapper'>
          <Input
            className='search'
            name='search_text'
            value={transactionsStore.filters.search_text && disableDownloadReport}
            onChange={handleSearchTest}
            Icon={SearchIcon}
          />
          <Button className='filter-button' type={'primary'} size={'large'} onClick={() => setIsOpen(true)}>
            <FilterIcon />
          </Button>
        </div>
        <FileDownloadButton
          style='input'
          isDisabled={!transactionsStore.transactionList.length || disableDownloadReport}
          onClick={handleDownloadPDF}
          type='DOWNLOAD PDF'
        />
      </div>
    );
  }

  return (
    <form action='#' id='send' className='transaction-filter-wrapper'>
      {isMobile && (
        <div className='filter-mobile-header'>
          <h1>{i18n.getMessage('menu.filters.text')}</h1>
          <CloseIcon onClick={() => setIsOpen(false)} className='filters-close-icon' />
        </div>
      )}
      <TransactionRangeFilter name='tabs' value={transactionsStore.rangeFilter} onChange={handleSetRangeFilter} />
      <div className='filter-wrapper'>
        <div className='filter-wrapper-line'>
          <TransactionDateFilter
            name='from_date'
            value={transactionsStore.filters.from_date}
            onChange={handleSetDateFilter}
            label={i18n.getMessage('transaction.text.from')}
          />
          <TransactionDateFilter
            name='to_date'
            value={transactionsStore.filters.to_date}
            onChange={handleSetDateFilter}
            label={i18n.getMessage('transaction.text.to')}
          />
          <Input
            className='search'
            name='search_text'
            value={transactionsStore.filters.search_text}
            onChange={handleSearchTest}
            Icon={SearchIcon}
          />
          <FileDownloadButton
            style='input'
            isDisabled={!transactionsStore.transactionList.length || disableDownloadReport}
            onClick={handleDownloadCSV}
            type='CSV'
          />
          <FileDownloadButton
            style='input'
            isDisabled={!transactionsStore.transactionList.length || disableDownloadReport}
            onClick={handleDownloadPDF}
            type='PDF'
          />
        </div>
        <div className='filter-wrapper-line'>
          <InputDropDown
            className='wallet'
            name='wallets'
            options={walletsOptions}
            value={transactionsStore.filters.wallets}
            onChange={handleSetFilter}
            isMulti
          />
          <InputDropDown
            className='transaction-type'
            name='types'
            options={transactionTypeOptions}
            placeholder={i18n.getMessage('transaction.text.transactionType')}
            value={transactionsStore.filters.types}
            onChange={handleSetFilter}
            isMulti
          />
          <InputDropDown
            className='currency'
            placeholder={i18n.getMessage('transaction.text.currency')}
            name='currencies'
            value={transactionsStore.filters.currencies}
            options={currencyOptions}
            onChange={handleSetFilter}
            isMulti
          />
          <InputDropDown
            className='status'
            placeholder={i18n.getMessage('transaction.text.status')}
            name='statuses'
            value={transactionsStore.filters.statuses}
            options={statusOptions}
            onChange={handleSetFilter}
            isMulti
          />
          {isMobile && (
            <div className='filter-buttons-wrapper'>
              <Button
                className='filter-button'
                size='large'
                type='outline'
                onClick={() => transactionsStore.clearFilters()}
              >
                {i18n.getMessage('transaction.clear')}
              </Button>
              <Button className='filter-button' size='large' onClick={() => setIsOpen(false)}>
                {i18n.getMessage('transaction.apply')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

TransactionsFilter.propTypes = {
  accountNumber: PropTypes.string,
  transactionStatuses: PropTypes.array,
  transactionsStore: MobXPropTypes.observableObject,
  userWallets: PropTypes.array,
  isMobile: PropTypes.bool,
  permissions: PropTypes.any
};

export default inject((stores) => ({
  userWallets: stores.userStore.userWallets,
  accountNumber: stores.userStore.userData.account?.account_number,
  permissions: stores.userStore?.currentAccountPermission,
  transactionStatuses: stores.userStore.appConstants?.transactionStatuses,
  transactionsStore: stores.transactionsStore
}))(observer(TransactionsFilter));
