import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import i18nContext from 'components/i18n-context';
import { parseDate } from 'components/utils';
import { amountFormattedValue } from 'services/utils';
import './PopUpScheme.scss';

const getTransactionDetailsFields = (i18n, authorization) => {
  const updatedAt = parseDate(authorization.updated_at);

  return [
    {
      label: i18n.getMessage('cards.authorizations.details.label.updatedDate'),
      value: `${updatedAt.date} ${updatedAt.time}`
    },
    {
      label: i18n.getMessage('cards.authorizations.details.label.authorizationStatus'),
      value: i18n.getMessage(`cards.authorizations.status.${authorization.status}`)
    },
    {
      label: i18n.getMessage('cards.authorizations.details.label.amount'),
      value: `${amountFormattedValue(authorization.amount)} ${authorization.currency}`
    },
    {
      label: i18n.getMessage('cards.authorizations.details.label.cardNumber'),
      value: authorization.card_number
    },
    {
      label: i18n.getMessage('cards.authorizations.details.label.merchantName'),
      value: authorization.merchant_name
    },
    {
      label: i18n.getMessage('cards.authorizations.details.label.merchantCategoryCode'),
      value: authorization.merchant_category_code
    },
    {
      label: i18n.getMessage('cards.authorizations.details.label.merchantCity'),
      value: authorization.merchant_city
    },
    {
      label: i18n.getMessage('cards.authorizations.details.label.merchantCountry'),
      value: authorization.merchant_country
    }
  ].filter((field) => field.value || field.value === 0);
};

export const PopUpAuthorizationDetailsScheme = ({ selectedAuthorization, onClose }) => {
  const i18n = useContext(i18nContext);
  const transactionDetailsFields = getTransactionDetailsFields(i18n, selectedAuthorization);

  const schemeLine = (label, value) => {
    return (
      <div key={label} className='scheme-line'>
        <p className='left'>{label}:</p>
        <p className='center'>{value}</p>
      </div>
    );
  };

  return (
    <div className='scheme-wrapper'>
      <div className='scheme-header'>
        <p className='account'>{i18n.getMessage('popUp.authorizationDetails')}</p>
        <CloseIcon className='scheme-close-icon' onClick={onClose} />
      </div>
      {transactionDetailsFields.map((field) => schemeLine(field.label, field.value))}
    </div>
  );
};

PopUpAuthorizationDetailsScheme.propTypes = {
  selectedAuthorization: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};
