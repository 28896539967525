/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { openPaydSimpleValidation, openPaydAdvancedValidation } from './ModelValidators';
import openPaydSimpleModel from './Models/openPaydSimpleModel';
import openPaydAdvancedModel from './Models/openPaydAdvancedModel';
import OpenPaydAdvancedForm from './OpenPaydAdvancedForm';
import OpenPaydSimpleForm from './OpenPaydSimpleForm';
import { FORM_TYPES } from 'components/common/constants';

const OpenPaydForm = (tableProps) => {
  const formType = tableProps.formType;
  const validationSchema =
    formType === FORM_TYPES.SIMPLE
      ? openPaydSimpleValidation({ ...tableProps.validationProps, model: openPaydSimpleModel })
      : openPaydAdvancedValidation({ ...tableProps.validationProps, model: openPaydAdvancedModel });
  const form = useFormik({ enableReinitialize: true, ...validationSchema });
  const formProps = { ...tableProps, validationSchema };

  useEffect(() => {
    form.resetForm();
  }, [tableProps.wallet.wallet_number]);

  return (
    <>
      {formType === FORM_TYPES.SIMPLE ? (
        <OpenPaydSimpleForm commonForm={form} {...formProps} />
      ) : (
        <OpenPaydAdvancedForm commonForm={form} {...formProps} />
      )}
    </>
  );
};

OpenPaydForm.propTypes = {
  accountNumber: PropTypes.string,
  getCommission: PropTypes.func.isRequired,
  uploadDocuments: PropTypes.func.isRequired,
  iban: PropTypes.string,
  wallet: PropTypes.object,
  commission: PropTypes.object,
  isCommissionLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  isRepeatPayment: PropTypes.bool,
  error: PropTypes.any,
  uploadedFiles: PropTypes.array,
  removePaymentFile: PropTypes.func,
  commonForm: PropTypes.object,
  currency: PropTypes.string,
  isSuccess: PropTypes.bool,
  // todo: describe validationProps object values
  validationProps: PropTypes.object.isRequired
};

export default OpenPaydForm;
