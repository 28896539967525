import { useContext } from 'react';
import PropTypes from 'prop-types';
import i18nContext from '../i18n-context';
import { ReactComponent as SimpleIcon } from 'assets/simple-icon.svg';
import { ReactComponent as StandardIcon } from 'assets/standard-icon.svg';
import { VERIFICATION_TYPES } from 'components/common/constants';
import Button from 'uikit/Button/Button';

export const Corporate = ({ handleOpenVerification, verificationType, isUnderReview }) => {
  const i18n = useContext(i18nContext);
  return (
    <div className='corporate-wrapper'>
      {isUnderReview && (
        <div className='verification'>
          {verificationType === VERIFICATION_TYPES.SIMPLE ? (
            <SimpleIcon className='svg-simple-icon corporate-icon' />
          ) : (
            <StandardIcon className='svg-standard-icon corporate-icon' />
          )}
          <div className='bank-wrapper'>
            <h1 className='bank-header'>{i18n.getMessage('verification.kyc.underReview.title')}</h1>
            <p className='bank-description'>{i18n.getMessage('verification.kyc.underReview.description')}</p>
            <p className='bank-description'>
              {i18n.getMessage('verification.kyc.underReview.support', {
                appTitle: process.env.REACT_APP_PAYMENT_INSTITUTION
              })}
            </p>
            <Button
              type={'primary'}
              roleType={'submit'}
              className={'btn-size'}
              size={'large'}
              onClick={() => (location.href = 'mailto:' + process.env.REACT_APP_SUPPORT_EMAIL)}
            >
              {i18n.getMessage('verification.button.contactUs')}
            </Button>
          </div>
        </div>
      )}

      {(verificationType === VERIFICATION_TYPES.SIMPLE || !verificationType) && !isUnderReview && (
        <div className='verification'>
          <SimpleIcon className='svg-simple-icon corporate-icon' />
          <div className='bank-wrapper'>
            <h1 className='bank-header'>{i18n.getMessage(process.env.REACT_APP_KYC_TITLE_CORPORATE_SIMPLE)}</h1>
            <p className='bank-description'>
              {i18n.getMessage(process.env.REACT_APP_KYC_DESCRIPTION_CORPORATE_SIMPLE)}
            </p>
            <Button
              type={'primary'}
              roleType={'submit'}
              size={'large'}
              onClick={() => handleOpenVerification(VERIFICATION_TYPES.SIMPLE)}
            >
              {verificationType
                ? i18n.getMessage('button.continueVerification')
                : i18n.getMessage(process.env.REACT_APP_KYC_BUTTON_TEXT)}
            </Button>
          </div>
        </div>
      )}

      {(verificationType === VERIFICATION_TYPES.STANDARD || !verificationType) && !isUnderReview && (
        <div className='verification'>
          <StandardIcon className='svg-standard-icon corporate-icon' />
          <div className='bank-wrapper'>
            <h1 className='bank-header'>{i18n.getMessage(process.env.REACT_APP_KYC_TITLE_CORPORATE_STANDARD)}</h1>
            <p className='bank-description'>
              {i18n.getMessage(process.env.REACT_APP_KYC_DESCRIPTION_CORPORATE_STANDARD)}
            </p>
            <Button
              type={'primary'}
              roleType={'submit'}
              size={'large'}
              onClick={() => handleOpenVerification(VERIFICATION_TYPES.STANDARD)}
            >
              {verificationType
                ? i18n.getMessage('button.continueVerification')
                : i18n.getMessage(process.env.REACT_APP_KYC_BUTTON_TEXT)}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

Corporate.propTypes = {
  handleOpenVerification: PropTypes.func.isRequired,
  verificationType: PropTypes.string,
  isUnderReview: PropTypes.bool
};
