import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import i18nContext from 'components/i18n-context';
import { ReactComponent as PhoneIcon } from 'assets/phone-icon.svg';
import authStore from 'stores/authStore';
import { AuthorizationBackButton } from 'components/common/AuthorizationBackButton/AuthorizationBackButton';
import { getErrorMessageForAlert } from 'services/utils';
import { ConfirmCodeWrapper } from '../common/ConfirmCodeWrapper/ConfirmCodeWrapper';
import { SMS_ERROR_CODE_DISABLE } from '../common/constants';

const Google2FA = ({ sms, handleSms, resendCode, error, authStore }) => {
  const i18n = useContext(i18nContext);
  const [alertState, setAlertState] = useState({ type: '', message: '' });
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (SMS_ERROR_CODE_DISABLE.includes(error?.code)) {
      setIsDisabled(true);
      handleSms(sms, true);
    }
    error && setAlertState({ type: 'warning', message: getErrorMessageForAlert(i18n, error) });
  }, [error]);

  const onComplete = (sms) => {
    handleSms(sms);
    setAlertState({ type: '', message: '' });
  };

  const handleSwitchToSmsAuth = () => authStore.forceSwitchToSmsConfirmation();

  return (
    <div className='authorization-form' id='form1'>
      <PhoneIcon className='svg-phone-icon registration-icon' />
      <h1 className='authorization-title'>{i18n.getMessage('2fa.title')}</h1>
      <ConfirmCodeWrapper
        type={'google'}
        isDisabled={isDisabled}
        error={error}
        onComplete={onComplete}
        alertState={alertState}
        isAuth={true}
        handleSwitchToSmsAuth={handleSwitchToSmsAuth}
      />
      <AuthorizationBackButton
        onClick={() => authStore.setIsLoginSuccess(false)}
        type={'login'}
        isGoogle2FAAuth={authStore.isGoogle2FAEnabled}
        confirmationType={authStore.confirmationActionType}
      />
    </div>
  );
};

Google2FA.propTypes = {
  sms: PropTypes.string.isRequired,
  handleSms: PropTypes.func.isRequired,
  resendCode: PropTypes.func.isRequired,
  error: PropTypes.any,
  authStore: MobXPropTypes.observableObject
};

export default inject('authStore')(observer(Google2FA));
