import { useContext, useEffect, useState } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SimpleIcon } from 'assets/simple-icon.svg';
import i18nContext from 'components/i18n-context';
import {
  WALLET_PROPERTIES,
  WALLET_TOOLTIP_TEXT,
  ACCOUNT_PERMISSIONS,
  PAYMENT_PROVIDERS
} from 'components/common/constants';
import { ContactUs } from 'components/common/ContactUs/ContactUs';
import ComplianceRequests from 'components/common/LatestTransactions/ComplianceRequests';
import LatestTransactionsCompact from 'components/common/LatestTransactions/LatestTransactionsCompact';
import PaymentForm from 'components/common/PaymentForm';
import PopUpScheme from 'components/common/PopUpScheme/PopUpScheme';
import Verification from 'components/Verification/Verification';
import { getWalletTitle } from 'components/utils';
import { Card } from 'uikit/Card/Card';
import { PopUp } from 'uikit/PopUp/PopUp';
import './DashBoard.scss';

const DashBoard = ({ userStore, paymentStore, transactionStore }) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();
  const [popUpState, setPopUpState] = useState({ open: false, account: {} });

  useEffect(() => {
    if (userStore.userData.account?.account_number) {
      transactionStore.getComplianceRequestList(userStore.userData.account?.account_number);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore.userData.account?.account_number]);

  const showPopup = (account) => {
    setPopUpState({ open: true, account: account });
  };

  const closePopUp = () => {
    setPopUpState({ open: false, account: {} });
  };

  const goToTransaction = (wallet) => {
    transactionStore.setWallet(wallet);
    navigate('/transactions');
  };

  const goToPayment = (wallet) => {
    paymentStore.setSelectedWallet(wallet);
    navigate('/new-payment');
  };

  if (userStore.isAccountVerified !== null && !userStore.isAccountVerified) {
    return (
      <section className='right-section verifications'>
        <Verification kycStatus={userStore.userData?.account?.kyc_status} />
      </section>
    );
  }

  const isShowPaymentByPermission = userStore?.currentAccountPermission
    ? userStore?.currentAccountPermission?.find((permission) => permission === ACCOUNT_PERMISSIONS.CREATE_TRANSACTION)
    : true;

  return (
    <>
      {userStore.userWallets.length === 0 ? (
        <ContactUs
          icon={<SimpleIcon />}
          header={i18n.getMessage('dashboard.noWallets.title')}
          description={i18n.getMessage('dashboard.noWallets.description')}
          link={process.env.REACT_APP_SUPPORT_EMAIL}
          button={i18n.getMessage('dashboard.noWallets.button')}
        />
      ) : (
        <section className='right-section dashboard'>
          <div className='card-wrapper'>
            {userStore.userWallets?.map(
              (wallet) =>
                wallet.transfer_provider !== PAYMENT_PROVIDERS.WALLESTER && (
                  <Card
                    key={wallet[WALLET_PROPERTIES.WALLET_NUMBER]}
                    type={'card'}
                    account={getWalletTitle(wallet)}
                    balance={wallet[WALLET_PROPERTIES.AVAILABLE]}
                    currency={wallet[WALLET_PROPERTIES.CURRENCY]}
                    reserved={wallet[WALLET_PROPERTIES.ON_HOLD]}
                    buttonText={'Review account'}
                    onExclaimPointClick={() => showPopup(wallet)}
                    onTransactionClick={() => goToTransaction(wallet)}
                    onPaymentClick={() => goToPayment(wallet)}
                    tooltipText={WALLET_TOOLTIP_TEXT}
                  />
                )
            )}
          </div>
          <PopUp className='wallet-info' show={popUpState.open} onClose={closePopUp}>
            <PopUpScheme activeWallet={popUpState.account} onClose={closePopUp} />
          </PopUp>
          {process.env.REACT_APP_IS_PAYFORM_ON_MAIN === 'true' && isShowPaymentByPermission && <PaymentForm />}
          {transactionStore.complianceRequestList.length > 0 && <ComplianceRequests />}
          <LatestTransactionsCompact />
        </section>
      )}
    </>
  );
};

DashBoard.propTypes = {
  userStore: MobXPropTypes.observableObject,
  paymentStore: MobXPropTypes.observableObject,
  transactionStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  userStore: stores.userStore,
  paymentStore: stores.paymentStore,
  transactionStore: stores.transactionsStore
}))(observer(DashBoard));
