import { useContext } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import { ClipBoard } from 'components/common/ClipBoard/ClipBoard';
import { sortingPaymentMethods } from 'components/common/PaymentForm/utils';
import {
  BEFORE_VALIDATE_PAYMENT_METHODS_SORTING_EXAMPLE,
  CURRENCY_TYPES,
  WALLET_PROPERTIES
} from 'components/common/constants';
import i18nContext from 'components/i18n-context';
import { amountFormattedValue } from 'services/utils';
import './PopUpScheme.scss';

const PopUpScheme = ({ activeWallet, onClose, userStore }) => {
  const i18n = useContext(i18nContext);

  const schemeLine = (left, center) => {
    return (
      <div key={left} className='scheme-line'>
        <p className='left'>{left}</p>
        <p className='center'>{center}</p>
        <ClipBoard copyText={center} />
      </div>
    );
  };

  const getPaymentMethods = () => {
    const sortedMethods = sortingPaymentMethods(
      activeWallet?.payment_methods,
      activeWallet?.currency,
      BEFORE_VALIDATE_PAYMENT_METHODS_SORTING_EXAMPLE
    );

    return sortedMethods.map((method) => i18n.getMessage(`transfer.paymentMethod.${method}`)).join(', ');
  };

  const accountDetailScheme = [
    {
      left: i18n.getMessage('popUp.left.accountHolder'),
      center: userStore.userData.account.common_name
    },
    activeWallet.external_wallet_number
      ? {
          left: i18n.getMessage('popUp.left.externalWalletNumber'),
          center: activeWallet.external_wallet_number
        }
      : null,
    activeWallet.bank_details
      ? {
          left: i18n.getMessage('popUp.left.bankDetails'),
          center: activeWallet.bank_details
        }
      : null,
    {
      left: i18n.getMessage('popUp.left.IBAN'),
      center: activeWallet?.iban?.iban || activeWallet.multi_iban?.iban
    },
    activeWallet.currency === CURRENCY_TYPES.GBP
      ? {
          left: i18n.getMessage('popUp.left.sortCode'),
          center: activeWallet?.iban?.sort_code || activeWallet.multi_iban?.sort_code
        }
      : null,
    activeWallet.currency === CURRENCY_TYPES.GBP
      ? {
          left: i18n.getMessage('popUp.left.accountNumber'),
          center: activeWallet?.iban?.account_number || activeWallet.multi_iban?.account_number
        }
      : null,
    activeWallet.bank_country
      ? {
          left: i18n.getMessage('popUp.left.country'),
          center: activeWallet.bank_country
        }
      : null,
    activeWallet.bank_name
      ? {
          left: i18n.getMessage('popUp.left.bankName'),
          center: activeWallet.bank_name
        }
      : null,
    activeWallet.bank_address
      ? {
          left: i18n.getMessage('popUp.left.bankAddress'),
          center: activeWallet.bank_address
        }
      : null,
    activeWallet.bic
      ? {
          left: i18n.getMessage('popUp.left.BIC'),
          center: activeWallet.bic
        }
      : null,
    activeWallet?.routing_codes?.aba
      ? {
          left: i18n.getMessage('popUp.left.routingCodes.aba'),
          center: activeWallet.routing_codes.aba
        }
      : null,
    activeWallet?.routing_codes?.blz_code
      ? {
          left: i18n.getMessage('popUp.left.routingCodes.blz'),
          center: activeWallet.routing_codes.blz_code
        }
      : null,
    activeWallet?.routing_codes?.bsb_code
      ? {
          left: i18n.getMessage('popUp.left.routingCodes.bsb'),
          center: activeWallet.routing_codes.bsb_code
        }
      : null,
    activeWallet?.routing_codes?.clabe_code
      ? {
          left: i18n.getMessage('popUp.left.routingCodes.clabe'),
          center: activeWallet.routing_codes.clabe_code
        }
      : null,
    activeWallet?.routing_codes?.ctn
      ? {
          left: i18n.getMessage('popUp.left.routingCodes.ctn'),
          center: activeWallet.routing_codes.ctn
        }
      : null,
    activeWallet?.routing_codes?.ifs_code
      ? {
          left: i18n.getMessage('popUp.left.routingCodes.ifs'),
          center: activeWallet.routing_codes.ifs_code
        }
      : null,
    activeWallet?.routing_codes?.sort_code
      ? {
          left: i18n.getMessage('popUp.left.routingCodes.sort'),
          center: activeWallet.routing_codes.sort_code
        }
      : null,
    {
      left: i18n.getMessage('popUp.left.paymentMethod'),
      center: getPaymentMethods()
    }
  ];

  return (
    <div className='scheme-wrapper account-details'>
      <div className='scheme-header'>
        <p className='account'>{i18n.getMessage('tooltip.accountDetails')}</p>
        <CloseIcon className='scheme-close-icon' onClick={onClose} />
      </div>
      <div className='wrapper-balance'>
        <p className='scheme-iban'>{activeWallet.iban?.iban || activeWallet.multi_iban?.iban}</p>
        <div className='flex'>
          <p className='scheme-balance'>{amountFormattedValue(activeWallet[WALLET_PROPERTIES.AVAILABLE])}</p>
          <p className='scheme-currency'>{activeWallet[WALLET_PROPERTIES.CURRENCY]}</p>
        </div>
        <p className='scheme-description'>
          {i18n.getMessage('popUp.description', { appTitle: process.env.REACT_APP_PAYMENT_INSTITUTION })}
        </p>
      </div>
      {accountDetailScheme.map((line) => line && schemeLine(line.left, line.center))}
    </div>
  );
};

PopUpScheme.propTypes = {
  activeWallet: PropTypes.object,
  onClose: PropTypes.func,
  userStore: MobXPropTypes.observableObject
};

export default inject('userStore')(observer(PopUpScheme));
