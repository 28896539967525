import PropTypes from 'prop-types';
import { ReactComponent as ArrowLeft } from 'assets/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/arrow-right.svg';
import './Pagination.scss';
import { classNames } from '../../../uikit/utils';

const Pagination = ({ currentPage, numberOfPages, setCurrentPage, className, isLoading }) => {
  const paginationClasses = classNames({
    'pagination-wrapper': true,
    [className]: true
  });

  if (isLoading) {
    return (
      <div className={paginationClasses}>
        <div className='pagination-pages'>
          <div className={'loader-placeholder'} />
          <div className={'loader-placeholder'} />
          <div className={'loader-placeholder'} />
          <div className={'loader-placeholder'} />
        </div>
        <div className='pagination-counter'>
          <div className={'loader-placeholder'} />
        </div>
      </div>
    );
  }

  return (
    <div className={paginationClasses}>
      <div className='pagination-pages'>
        <button onClick={() => currentPage !== 0 && setCurrentPage(currentPage - 1)}>
          <ArrowLeft />
        </button>
        {numberOfPages !== 1 && (
          <button
            className={currentPage + 1 !== numberOfPages ? 'active' : ''}
            onClick={() => currentPage !== 0 && setCurrentPage(currentPage - 1)}
          >
            {currentPage + 1 === numberOfPages ? currentPage : currentPage + 1}
          </button>
        )}
        <button
          className={currentPage + 1 === numberOfPages ? 'active' : ''}
          onClick={() => currentPage + 1 !== numberOfPages && setCurrentPage(currentPage + 1)}
        >
          {currentPage + 1 === numberOfPages ? currentPage + 1 : currentPage + 2}
        </button>
        <button onClick={() => currentPage + 1 !== numberOfPages && setCurrentPage(currentPage + 1)}>
          <ArrowRight />
        </button>
      </div>
      <p className='pagination-counter'>
        <strong>{currentPage + 1}</strong> / {numberOfPages}
      </p>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  numberOfPages: PropTypes.number,
  setCurrentPage: PropTypes.func.isRequired,
  className: PropTypes.string,
  isLoading: PropTypes.bool
};

export default Pagination;
