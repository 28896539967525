import { makeAutoObservable, runInAction } from 'mobx';
import { MAX_UPLOADED_FILES_SIZE } from 'components/common/constants';
import {
  createMessage,
  createTopic,
  downloadMessageDocument,
  generateSecurityCode,
  getMessageList,
  getTopicMessages,
  resendSecurityCode,
  uploadMessageDocument
} from 'services/requestAgent';

class MessagesStore {
  isInitialized = false;
  isLoading = false;
  isTopicMessagesLoading = false;
  isFileUploading = false;
  isNewTopicCreated = false;
  error = null;
  topics = [];
  currentTopicIndex = -1;
  currentTopicMessages = [];
  securityCode = '';
  uploadedFiles = [];
  newTopic = {
    subject: '',
    message_body: ''
  };
  newMessage = {
    body: ''
  };

  constructor() {
    makeAutoObservable(this);
  }

  async initializeData(account_number) {
    try {
      runInAction(() => {
        this.isLoading = true;
      });
      const { content: topics } = await getMessageList(account_number, { size: 1000 });

      topics.sort((a, b) => new Date(b?.last_message?.create_at) - new Date(a?.last_message?.create_at));

      runInAction(() => {
        this.topics = topics;
        this.isInitialized = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  resetStore() {
    this.isInitialized = false;
    this.isLoading = false;
    this.isTopicMessagesLoading = false;
    this.isNewTopicCreated = false;
    this.error = null;
    this.topics = [];
    this.currentTopicIndex = -1;
    this.currentTopicMessages = [];
    this.securityCode = '';
    this.uploadedFiles = [];
    this.newTopic = {
      subject: '',
      message_body: ''
    };
    this.newMessage = {
      body: ''
    };
  }

  resetForms() {
    this.isLoading = false;
    this.error = null;
    this.securityCode = '';
    this.uploadedFiles = [];
    this.newTopic = {
      subject: '',
      message_body: ''
    };
    this.newMessage = {
      body: ''
    };
  }

  clearError() {
    this.error = null;
  }

  setError(error) {
    this.error = error;
  }

  setIsNewTopicCreated(status) {
    this.isNewTopicCreated = status;
  }

  setCurrentTopicIndex(index) {
    this.currentTopicIndex = index;
  }

  getCurrentTopicIndexById(topicId) {
    return this.topics.findIndex((topic) => topic.id === topicId);
  }

  setSecurityCode(code) {
    this.securityCode = code;
  }

  setNewTopicData = (subject, message) => {
    this.newTopic.subject = subject;
    this.newTopic.message_body = message;
  };

  setNewMessageData = (message) => {
    this.newMessage.body = message;
  };

  setIsFileUploading(status) {
    this.isFileUploading = status;
    this.error = null;
  }

  removeAttachment(fileId) {
    this.uploadedFiles = this.uploadedFiles.filter((file) => file?.id !== fileId);
  }

  uploadAttachments(accountNumber) {
    return async ({ target: { files } }) => {
      if (!Object.keys(files).length) {
        return null;
      }
      this.setIsFileUploading(true);
      try {
        const selectedFiles = [...files];
        const Data = new FormData();
        selectedFiles.forEach((file) => {
          if (file.size > MAX_UPLOADED_FILES_SIZE) {
            throw { code: 'REQUEST_HAS_BEEN_TERMINATED' };
          } else {
            Data.append('file', file, file?.name);
          }
        });
        const documentArray = await uploadMessageDocument(accountNumber, Data);

        runInAction(() => {
          this.isFileUploading = false;
          this.uploadedFiles = [
            ...this.uploadedFiles,
            ...documentArray.map((file) => ({ name: file?.name, id: file?.id }))
          ];
        });
      } catch (err) {
        runInAction(() => {
          this.isFileUploading = false;
          this.error = { type: 'attachDoc', ...err };
        });
      }
    };
  }

  async downloadAttachment(accountNumber, fileId) {
    try {
      runInAction(() => {
        this.isFileUploading = true;
      });
      await downloadMessageDocument(accountNumber, fileId, false);
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isFileUploading = false;
      });
    }
  }

  async generateMessageSecurityCode() {
    try {
      runInAction(() => {
        this.isLoading = true;
      });
      await generateSecurityCode();
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async resendMessageSecurityCode() {
    try {
      runInAction(() => {
        this.isLoading = true;
      });
      await resendSecurityCode();
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async createNewTopic(accountNumber) {
    try {
      runInAction(() => {
        this.isLoading = true;
      });

      const newTopic = await createTopic(accountNumber, this.securityCode, {
        ...this.newTopic,
        documents: this.uploadedFiles.map((file) => file.id)
      });

      runInAction(() => {
        this.topics = [newTopic, ...this.topics];
        this.isNewTopicCreated = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async getCurrentTopicMessagesFilesUrls(accountNumber) {
    try {
      for (const message of this.currentTopicMessages) {
        if (message.documents?.length) {
          for (const document of message.documents) {
            if (!document.url) {
              const blobUrl = await downloadMessageDocument(accountNumber, document.id, true);
              runInAction(() => {
                document.url = blobUrl;
              });
            }
          }
        }
      }
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    }
  }

  async getCurrentTopicMessages(accountNumber, topicId) {
    try {
      runInAction(() => {
        this.isTopicMessagesLoading = true;
      });

      const { content: messages } = await getTopicMessages(accountNumber, topicId);

      runInAction(() => {
        this.currentTopicMessages = messages.reverse();
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isTopicMessagesLoading = false;
      });
    }
  }

  async createNewMessage(accountNumber, topicId) {
    try {
      runInAction(() => {
        this.isLoading = true;
      });

      const newMessage = await createMessage(accountNumber, topicId, this.securityCode, {
        ...this.newMessage,
        documents: this.uploadedFiles.map((file) => file.id)
      });

      runInAction(() => {
        this.currentTopicMessages = [newMessage, ...this.currentTopicMessages];
        this.topics[this.currentTopicIndex].last_message = newMessage;
        this.topics.sort((a, b) => new Date(b?.last_message?.create_at) - new Date(a?.last_message?.create_at));
        this.setCurrentTopicIndex(0);
        this.resetForms();
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
}

export default new MessagesStore();
