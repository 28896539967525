export default class ObjectError extends Error {
  constructor({ status, code, args, message = 'Error' }) {
    super(...arguments);
    this.name = this.constructor.name;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }

    this.status = status;
    this.code = code;
    this.args = {
      objectName: args[0],
      objectPropValue: args[1]
    };
  }
}
