import React, { useContext } from 'react';
import i18nContext from 'components/i18n-context';
import { ReactComponent as LatestIcon } from 'assets/latest-transactions.svg';

export const EmptyAuthorizationsList = () => {
  const i18n = useContext(i18nContext);
  return (
    <div className='empty-authorizations'>
      <LatestIcon className='empty-authorizations-icon' />
      <div className='empty-authorizations-wrapper'>
        <h2 className='empty-authorizations-header'>{i18n.getMessage('cards.authorizations.emptyList.header')}</h2>
        <p className='empty-authorizations-text'>{i18n.getMessage('cards.authorizations.emptyList.description')}</p>
      </div>
    </div>
  );
};
