import PropTypes from 'prop-types';

import { IconButton } from 'uikit/IconButton/IconButton';
import { getNavigationButtons } from 'components/common/MenuButtons';
import './NavigationBar.scss';
import UserPanel from '../Header/UserPanel';

const NavigationBar = ({ compact, isAccountVerified, isMobile, collapseMenu, isFees }) => {
  const onClick = (buttonClick) => {
    buttonClick && buttonClick();
    collapseMenu();
  };

  return (
    <nav id='navbar' className={`navbar ${compact ? 'collapsed' : ''}`}>
      <div className={`navigation-wrapper ${compact && !isMobile ? 'collapsed' : ''}`}>
        {getNavigationButtons(isAccountVerified, isFees)
          .filter((button) => !button.isDisabled)
          .map((button) => (
            <IconButton
              key={button.id}
              navigationType
              onClick={isMobile ? () => onClick(button.onClick) : null}
              path={button.path}
              text={button.text}
              Icon={button.icon}
              active={false}
              isDisabled={button.isDisabled}
            />
          ))}
        {isMobile && <UserPanel isMobile={isMobile} onClick={onClick} />}
      </div>
    </nav>
  );
};

NavigationBar.propTypes = {
  collapseMenu: PropTypes.func,
  isMobile: PropTypes.bool,
  isAccountVerified: PropTypes.bool,
  compact: PropTypes.bool,
  isFees: PropTypes.bool
};

export default NavigationBar;
