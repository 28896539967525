import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import TransactionsList from './TransactionsList';
import { EmptyTransactionsList } from './EmptyTransactionsList';
import { Container } from 'uikit/Container/Container';
import i18nContext from 'components/i18n-context';
import TransactionsFilter from './TransactionsFilter';
import Pagination from '../Pagination/Pagination';
import { useMediaQuery } from 'services/useMediaQUery';
import { MEDIA_QUERY } from 'services/utils';
import './LatestTransactions.scss';

const LatestTransactions = ({ accountNumber, transactionsStore }) => {
  const i18n = useContext(i18nContext);
  useEffect(() => {
    if (accountNumber) {
      transactionsStore.getTransactions(accountNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accountNumber,
    transactionsStore.filters.from_date,
    transactionsStore.filters.to_date,
    transactionsStore.filters.currencies,
    transactionsStore.filters.wallets,
    transactionsStore.filters.types,
    transactionsStore.filters.transfer_types,
    transactionsStore.filters.statuses,
    transactionsStore.transactionSort.sort_column,
    transactionsStore.transactionSort.sort_direction
  ]);

  const handleSortChange = (sortData) => transactionsStore.setTransactionSort(sortData);
  const isMobile = useMediaQuery(MEDIA_QUERY.MOBILE);

  const handleSetCurrentPage = (pageNumber) => {
    transactionsStore.setTransactionPage(pageNumber);
    transactionsStore.getTransactions(accountNumber);
  };

  return (
    <section className='latest-transactions'>
      <Container className='transactions-container' header={i18n.getMessage('container.Transactions')}>
        <TransactionsFilter isMobile={isMobile} />
        {transactionsStore.transactionList.length ? (
          <>
            <TransactionsList
              isLoading={transactionsStore.isLoading}
              onChangeSort={handleSortChange}
              transactions={transactionsStore?.transactionList}
              sortData={transactionsStore?.transactionSort}
            />
            <Pagination
              isLoading={transactionsStore.isLoading}
              currentPage={transactionsStore.pagination.page}
              numberOfPages={transactionsStore.totalPages}
              setCurrentPage={handleSetCurrentPage}
            />
          </>
        ) : (
          <EmptyTransactionsList />
        )}
      </Container>
    </section>
  );
};

LatestTransactions.propTypes = {
  accountNumber: PropTypes.string,
  transactionsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  accountNumber: stores.userStore.userData.account?.account_number,
  transactionsStore: stores.transactionsStore
}))(observer(LatestTransactions));
