import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';
import i18nContext from 'components/i18n-context';
import { CardSettings } from './components/CardSettings';
import { CardExample } from '../CardExample';
import { ReactComponent as DetailsIcon } from 'assets/card-details.svg';
import { ReactComponent as ReplaceIcon } from 'assets/card-replace.svg';
import { ReactComponent as BlockIcon } from 'assets/card-block.svg';
import { ReactComponent as UnlockIcon } from 'assets/card-unlock.svg';
import { CARD_ACTIONS, CARD_BLOCK_TYPE, CARD_CREATION_FORM_TYPE, CARD_STATUS } from 'components/common/constants';
import { PopUpBlockOrUnblockCardConfirmationScheme } from 'components/common/PopUpScheme/PopUpBlockOrUnblockCardConfirmationScheme';
import { PopUpCardDetailsScheme } from 'components/common/PopUpScheme/PopUpCardDetailsScheme';
import { useMediaQuery } from 'services/useMediaQUery';
import { MEDIA_QUERY } from 'services/utils';
import { Container } from 'uikit/Container/Container';
import { PopUp } from 'uikit/PopUp/PopUp';
import '../../Cards.scss';

const VirtualCardSettings = ({ accountType, accountNumber, cardsStore, card }) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(MEDIA_QUERY.MOBILE);
  const { id } = useParams();
  const [isShowBlockOrUnblockCardPopUp, setIsShowBlockOrUnblockCardPopUp] = useState(false);
  const [isShowDetailsPopUp, setIsShowDetailsPopUp] = useState(false);
  const [cardActionType, setCardActionType] = useState(null);
  const isBlocked = card?.status === (CARD_STATUS.SOFT_BLOCK || CARD_STATUS.HARD_BLOCK);
  const isBlockPlug = true;

  useEffect(() => {
    if (id && cardsStore.isInitialized && cardsStore.cards) {
      const cardInfo = cardsStore.getCardById(id);
      if (!cardInfo) {
        navigate('/404');
      } else {
        cardsStore.setSelectedCard(cardsStore.getCardById(id));
      }
    }
  }, [id, cardsStore.cards, cardsStore.isInitialized]);

  const handeDetailsButtonClick = () => {
    cardsStore.resetCardData();
    setIsShowDetailsPopUp(true);
    cardsStore.getCardNumber(accountNumber, id);
    cardsStore.getCardCVV(accountNumber, id);
  };

  const handeDetailsPopUpClose = () => {
    cardsStore.resetCardData();
    setIsShowDetailsPopUp(false);
  };

  const handleBlockCardButtonClick = () => {
    setIsShowBlockOrUnblockCardPopUp(true);
    setCardActionType(CARD_ACTIONS.BLOCK);
  };

  const handleUnblockCardButtonClick = () => {
    setIsShowBlockOrUnblockCardPopUp(true);
    setCardActionType(CARD_ACTIONS.UNBLOCK);
  };

  const onConfirm = async () => {
    if (cardActionType === CARD_ACTIONS.BLOCK) {
      await cardsStore.blockCard(accountNumber, id);
    } else {
      await cardsStore.unblockCard(accountNumber, id);
    }
    setIsShowBlockOrUnblockCardPopUp(false);
    setCardActionType(null);
  };

  return (
    <Container
      className={!isMobile ? 'container-web' : ''}
      header={i18n.getMessage('cards.container.virtualCardDetails', { cardNumber: card?.card_number })}
      isMaxWidthUnset={true}
    >
      <div className='card-settings-wrapper'>
        <div className='card-settings-card-wrapper'>
          <CardExample
            className='card-settings-card-example'
            accountType={accountType}
            cardType={CARD_CREATION_FORM_TYPE.VIRTUAL}
            cardStatus={card?.status}
            cardholderName={card?.name}
          />
          <div className='card-settings-card-actions-wrapper'>
            <div className={`card-settings-card-button-wrapper ${isBlocked ? 'button-disabled' : ''}`}>
              <button className='card-settings-card-button' onClick={handeDetailsButtonClick} disabled={isBlocked}>
                <DetailsIcon />
              </button>
              <span>{i18n.getMessage('cards.details.virtual.button.details')}</span>
            </div>
            <div className={`card-settings-card-button-wrapper ${isBlockPlug ? 'button-disabled' : ''}`}>
              <button className='card-settings-card-button' disabled={isBlockPlug}>
                <ReplaceIcon />
              </button>
              <span>{i18n.getMessage('cards.details.virtual.button.replace')}</span>
            </div>
            {isBlocked ? (
              <div
                className={`card-settings-card-button-wrapper ${
                  card?.block_type !== CARD_BLOCK_TYPE.BLOCKED_BY_CARDHOLDER ? 'button-disabled' : ''
                }`}
              >
                <button
                  className='card-settings-card-button'
                  onClick={handleUnblockCardButtonClick}
                  disabled={card?.block_type !== CARD_BLOCK_TYPE.BLOCKED_BY_CARDHOLDER}
                >
                  <UnlockIcon />
                </button>
                <span>{i18n.getMessage('cards.details.virtual.button.unlock')}</span>
              </div>
            ) : (
              <div className={'card-settings-card-button-wrapper'}>
                <button className='card-settings-card-button' onClick={handleBlockCardButtonClick}>
                  <BlockIcon />
                </button>
                <span>{i18n.getMessage('cards.details.virtual.button.block')}</span>
              </div>
            )}
          </div>
        </div>
        <CardSettings isCardBlocked={isBlockPlug} />
      </div>
      <PopUp
        alignOnCenter
        show={isShowBlockOrUnblockCardPopUp || isShowDetailsPopUp}
        className={isShowDetailsPopUp ? 'cardDetails' : ''}
      >
        {isShowDetailsPopUp ? (
          <PopUpCardDetailsScheme
            onClose={handeDetailsPopUpClose}
            cardholderName={card?.name}
            cardNumber={cardsStore.cardNumber}
            expirationDate={card?.expiration_date}
            cvv={cardsStore.cvv}
          />
        ) : (
          <PopUpBlockOrUnblockCardConfirmationScheme
            type={cardActionType}
            onClose={() => setIsShowBlockOrUnblockCardPopUp(false)}
            onConfirm={onConfirm}
          />
        )}
      </PopUp>
    </Container>
  );
};

VirtualCardSettings.propTypes = {
  accountNumber: PropTypes.string,
  accountType: PropTypes.string,
  card: PropTypes.object,
  cardsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  accountNumber: stores.userStore.userData.account?.account_number,
  accountType: stores.userStore.userData.account?.type,
  cardsStore: stores.cardsStore,
  card: stores.cardsStore.selectedCard,
  userStore: stores.userStore
}))(observer(VirtualCardSettings));
