/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import BottomPartForm from './BottomPartForm';
import { LPBAdvancedModel } from './Models';
import { PAYMENT_METHOD, RECIPIENT_TYPES } from 'components/common/constants';
import i18nContext from 'components/i18n-context';
import { amountFormattedValue, prepareFieldErrors } from 'services/utils';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import { InputDropDownSearch } from 'uikit/InputDropDown/InputDropDownSearch';

const LPBAdvancedForm = ({
  accountNumber,
  getCommission,
  checkIban,
  iban,
  setIban,
  isIbanCheckLoading,
  uploadDocuments,
  validationSchema,
  wallet,
  paymentMethod,
  uploadedFiles,
  commission,
  removePaymentFile,
  error,
  isCommissionLoading,
  isLoading,
  isSuccess,
  ibanCredentials,
  commonForm,
  countries
}) => {
  const i18n = useContext(i18nContext);
  const form = useFormik(validationSchema);
  const serverFieldsErrors = prepareFieldErrors(i18n, error);
  const [disabled, setDisabled] = useState(true);
  const [showMethodCommission, setShowMethodCommission] = useState(false);
  const model = LPBAdvancedModel;

  useEffect(() => {
    handleIbanComplete();
  }, [wallet.wallet_number]);

  useEffect(() => {
    if (!isIbanCheckLoading && ibanCredentials) {
      handleGetCommission();
    }
  }, [isIbanCheckLoading, ibanCredentials]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      setDisabled(true);
    }
  }, [isSuccess]);

  const { values, errors, handleSubmit, handleChange, validateForm, resetForm, setFieldValue } = commonForm || form;

  useEffect(() => {
    if (iban && !values.recipient_wallet) {
      setFieldValue('recipient_wallet', iban);
    }
  }, [iban, values.recipient_wallet]);

  const handleRecipientTypeChange = (name, data) => handleChange({ target: { name, value: data } });

  const handleGetCommission = async () => {
    if (
      !serverFieldsErrors?.iban &&
      values.recipient_wallet &&
      (ibanCredentials?.bank_country || values.bank_country) &&
      (ibanCredentials?.bank_name || values.bank_name) &&
      (ibanCredentials?.account_number || values.account_number) &&
      (ibanCredentials?.bic || values.bic) &&
      values.registration_number &&
      values.recipient_type &&
      (values.recipient_type === RECIPIENT_TYPES.COMPANY
        ? values.company_name
        : values.first_name && values.last_name) &&
      values.registration_number &&
      values.beneficiary_city &&
      values.beneficiary_country &&
      values.beneficiary_post_code &&
      values.beneficiary_address &&
      values.amount &&
      values.comment
    ) {
      setDisabled(false);
      setShowMethodCommission(true);
      const errorsResult = await validateForm();
      if (!Object.keys(errorsResult).length) {
        await getCommission(
          accountNumber,
          model({
            providerTableData: values,
            data: { wallet, paymentMethod, ...ibanCredentials }
          })
        );
      }
    }
  };

  const onAmountIBANChange = (e) => {
    setShowMethodCommission(false);
    handleChange(e);
    if (e.target.name === 'recipient_wallet') {
      setIban(e.target.value);
    }
  };

  const getCommissionValue = () => {
    if (!commission.value && !commission.currency && !commission.type) {
      return '0.00';
    }
    return `${amountFormattedValue(commission.value)} ${commission.currency}`;
  };

  const handleIbanComplete = async () => {
    values.recipient_wallet && (await checkIban(values.recipient_wallet));
  };

  const bottomPartProps = {
    values,
    errors,
    error,
    handleChange,
    handleSubmit,
    serverFieldsErrors,
    onAmountIBANChange,
    handleGetCommission,
    showMethodCommission,
    getCommissionValue,
    paymentMethod,
    isSubmitDisabled:
      disabled ||
      isLoading ||
      isCommissionLoading ||
      !!Object.keys(errors).length ||
      !!Object.keys(serverFieldsErrors).length ||
      !paymentMethod,
    isLoading: isLoading || isCommissionLoading,
    uploadedFiles,
    uploadDocuments,
    removePaymentFile
  };

  return (
    <>
      <Input
        isRequired={true}
        label={i18n.getMessage('transfer.form.recipientIban.label')}
        name={'recipient_wallet'}
        value={values.recipient_wallet}
        error={errors.recipient_wallet || serverFieldsErrors.iban}
        onChange={onAmountIBANChange}
        onBlur={handleIbanComplete}
        subText={
          ibanCredentials?.bank_name &&
          i18n.getMessage('transfer.form.bankName.subText', { bankName: ibanCredentials?.bank_name })
        }
      />
      {paymentMethod === PAYMENT_METHOD.SWIFT && (
        <>
          <div className='inputs-wrapper-evenly'>
            <InputDropDownSearch
              isRequired={true}
              isDisabled={isIbanCheckLoading || !!ibanCredentials?.bank_country}
              label={i18n.getMessage('transfer.form.bankCountry.label')}
              name={'bank_country'}
              value={ibanCredentials?.bank_country || values.bank_country}
              onChange={handleRecipientTypeChange}
              options={countries.map((value) => ({ key: value, value: i18n.getMessage(`country.${value}`) }))}
              error={errors.bank_country || serverFieldsErrors.bank_country}
              onBlur={handleGetCommission}
            />
            <Input
              isRequired={true}
              isDisabled={isIbanCheckLoading || !!ibanCredentials?.bank_name}
              label={i18n.getMessage('transfer.form.bankName.label')}
              name={'bank_name'}
              value={ibanCredentials?.bank_name || values.bank_name}
              onChange={handleChange}
              error={errors.bank_name || serverFieldsErrors.bank_name}
              onBlur={handleGetCommission}
            />
          </div>
          <div className='inputs-wrapper-evenly'>
            <Input
              isRequired={true}
              isDisabled={isIbanCheckLoading || !!ibanCredentials?.account_number}
              label={i18n.getMessage('transfer.form.accountNumber.label')}
              name={'account_number'}
              value={ibanCredentials?.account_number || values.account_number}
              onChange={handleChange}
              error={errors.account_number || serverFieldsErrors.account_number}
              onBlur={handleGetCommission}
            />
            <Input
              isRequired={true}
              isDisabled={isIbanCheckLoading || !!ibanCredentials?.bic}
              label={i18n.getMessage('transfer.form.BIC.label')}
              error={errors?.bic || serverFieldsErrors?.bic}
              name={'bic'}
              value={ibanCredentials?.bic || values.bic}
              onChange={handleChange}
              onBlur={handleGetCommission}
            />
          </div>
        </>
      )}
      <div className={`inputs-wrapper ${values.recipient_type}`}>
        <InputDropDown
          label={i18n.getMessage('transfer.form.recipientType.label')}
          name={'recipient_type'}
          value={values.recipient_type}
          onChange={handleRecipientTypeChange}
          options={Object.values(RECIPIENT_TYPES).map((value) => ({ key: value, value }))}
          isDisabled={isIbanCheckLoading}
        />
        {values.recipient_type === RECIPIENT_TYPES.COMPANY && (
          <Input
            isRequired={true}
            label={i18n.getMessage('transfer.form.companyName.label')}
            name={'company_name'}
            value={values.company_name}
            error={errors.company_name || serverFieldsErrors.companyName}
            onChange={handleChange}
            onBlur={handleGetCommission}
            isDisabled={isIbanCheckLoading}
          />
        )}
        {values.recipient_type === RECIPIENT_TYPES.INDIVIDUAL && (
          <Input
            isRequired={true}
            label={i18n.getMessage('transfer.form.firstName.label')}
            name={'first_name'}
            value={values.first_name}
            error={errors.first_name || serverFieldsErrors.firstName}
            onChange={handleChange}
            onBlur={handleGetCommission}
            isDisabled={isIbanCheckLoading}
          />
        )}
        {values.recipient_type === RECIPIENT_TYPES.INDIVIDUAL && (
          <Input
            isRequired={true}
            label={i18n.getMessage('transfer.form.lastName.label')}
            name={'last_name'}
            value={values.last_name}
            error={errors.last_name || serverFieldsErrors.lastName}
            onChange={handleChange}
            onBlur={handleGetCommission}
            isDisabled={isIbanCheckLoading}
          />
        )}
      </div>
      <div className='inputs-wrapper-evenly'>
        <Input
          isRequired={true}
          label={i18n.getMessage('transfer.form.registrationNumber.label')}
          name={'registration_number'}
          value={values.registration_number}
          onChange={handleChange}
          error={errors.registration_number || serverFieldsErrors.registration_number}
          onBlur={handleGetCommission}
          isDisabled={isIbanCheckLoading}
        />
        <Input
          isRequired={true}
          label={i18n.getMessage('transfer.form.beneficiaryCity.label')}
          name={'beneficiary_city'}
          value={values.beneficiary_city}
          onChange={handleChange}
          error={errors.beneficiary_city || serverFieldsErrors.beneficiary_city}
          onBlur={handleGetCommission}
          isDisabled={isIbanCheckLoading}
        />
      </div>
      <div className='inputs-wrapper-evenly'>
        <InputDropDownSearch
          isRequired={true}
          label={i18n.getMessage('transfer.form.beneficiaryCountry.label')}
          name={'beneficiary_country'}
          value={values.beneficiary_country}
          onChange={handleRecipientTypeChange}
          options={countries.map((value) => ({ key: value, value: i18n.getMessage(`country.${value}`) }))}
          error={errors.beneficiary_country || serverFieldsErrors.beneficiary_country}
          onBlur={handleGetCommission}
          isDisabled={isIbanCheckLoading}
        />
        <Input
          isRequired={true}
          label={i18n.getMessage('transfer.form.beneficiaryPostalCode.label')}
          name={'beneficiary_post_code'}
          value={values.beneficiary_post_code}
          onChange={handleChange}
          error={errors.beneficiary_post_code || serverFieldsErrors.beneficiary_post_code}
          onBlur={handleGetCommission}
          isDisabled={isIbanCheckLoading}
        />
      </div>
      <Input
        isRequired={true}
        label={i18n.getMessage('transfer.form.beneficiaryAddress.label')}
        name={'beneficiary_address'}
        value={values.beneficiary_address}
        onChange={handleChange}
        error={errors.beneficiary_address || serverFieldsErrors.beneficiary_address}
        onBlur={handleGetCommission}
        isDisabled={isIbanCheckLoading}
      />
      <BottomPartForm {...bottomPartProps} />
    </>
  );
};

LPBAdvancedForm.propTypes = {
  accountNumber: PropTypes.string,
  getCommission: PropTypes.func.isRequired,
  uploadDocuments: PropTypes.func.isRequired,
  checkIban: PropTypes.func.isRequired,
  iban: PropTypes.string,
  setIban: PropTypes.func,
  isIbanCheckLoading: PropTypes.bool,
  ibanCredentials: PropTypes.object,
  validationSchema: PropTypes.object,
  wallet: PropTypes.object,
  paymentMethod: PropTypes.string,
  commission: PropTypes.object,
  isCommissionLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.any,
  uploadedFiles: PropTypes.array,
  removePaymentFile: PropTypes.func,
  commonForm: PropTypes.object,
  currency: PropTypes.string,
  isSuccess: PropTypes.bool,
  countries: PropTypes.array
};

export default LPBAdvancedForm;
