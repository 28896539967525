import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import i18nContext from 'components/i18n-context';
import { CardExample } from '../../CardExample';
import { getCardsPasswordValidation } from '../../../utils';
import { ACCOUNT_TYPE, CARD_CREATION_FORM_TYPE, CARD_TYPE, SMS_ERROR_CODE_DISABLE } from 'components/common/constants';
import { ConfirmCodeWrapper } from 'components/common/ConfirmCodeWrapper/ConfirmCodeWrapper';
import { LATIN_LETTERS_REG_EXP } from 'components/common/PaymentForm/ProviderForms/ModelValidators/utils';
import { getSecureUserPhone } from 'services/authUtils';
import { getErrorMessageForAlert, prepareFieldErrors } from 'services/utils';
import Button from 'uikit/Button/Button';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import '../../../Cards.scss';

export const VirtualCardForm = ({
  accountType,
  userFullName,
  getAvailableBalance,
  walletsOptions,
  sendCreateCardRequest,
  isCardCreateConfirmation,
  error,
  clearError,
  onConfirm,
  resendSecurityCode,
  dashboardSelectedWalletNumber
}) => {
  const i18n = useContext(i18nContext);
  const [alertState, setAlertState] = useState({ type: '', message: '' });
  const [showCountDown, setShowCountDown] = useState(false);
  const [securityCode, setSecurityCode] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [countryCode, setCountryCode] = useState(process.env.REACT_APP_DEFAULT_COUNTRY_CODE);

  useEffect(() => {
    if (SMS_ERROR_CODE_DISABLE.includes(error?.code)) {
      setIsDisabled(true);
    }
    error && setAlertState({ type: 'warning', message: getErrorMessageForAlert(i18n, error) });
  }, [error]);

  useEffect(() => {
    setShowCountDown(true);
    setAlertState({ type: 'success', message: i18n.getMessage('pin.alert.sent') });
    return () => {
      clearError();
    };
  }, []);

  const handleResendCode = async () => {
    clearError();
    setShowCountDown(true);
    await resendSecurityCode();
    isDisabled && setIsDisabled(false);
    setAlertState({ type: 'success', message: i18n.getMessage('pin.alert.sent') });
  };

  const onTimerEnd = () => {
    setShowCountDown(false);
  };

  const onComplete = (code) => {
    setSecurityCode(code);
    setAlertState({ type: '', message: '' });
  };

  const handleCardCreationConfirm = () => {
    onConfirm(securityCode);
  };

  const form = useFormik({
    initialValues: {
      wallet: dashboardSelectedWalletNumber || walletsOptions[0].key,
      cardholderName: '',
      securePhoneNumber: accountType === ACCOUNT_TYPE.COMPANY ? process.env.REACT_APP_DEFAULT_DIAL_CODE : '',
      secureCardPassword: '',
      repeatSecureCardPassword: ''
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      wallet: Yup.string().required(i18n.getMessage('cards.creationForm.validation.isRequired')),
      cardholderName:
        accountType === ACCOUNT_TYPE.COMPANY
          ? Yup.string()
              .nullable(true)
              .max(100, i18n.getMessage('cards.error.cardholderName', { size: 100 }))
              .matches(LATIN_LETTERS_REG_EXP, {
                message: i18n.getMessage('client.validation.message.isOnlyLatinLetters')
              })
          : null,
      securePhoneNumber:
        accountType === ACCOUNT_TYPE.COMPANY
          ? Yup.string()
              .required(i18n.getMessage('error.fieldRequired'))
              .phone(countryCode.toUpperCase(), i18n.getMessage('error.fieldError.phone.Phone'))
          : null,
      secureCardPassword: getCardsPasswordValidation(),
      repeatSecureCardPassword: getCardsPasswordValidation().oneOf(
        [Yup.ref('secureCardPassword')],
        i18n.getMessage('error.passwordsDontMatch')
      )
    }),
    onSubmit: (values) => {
      if (accountType === ACCOUNT_TYPE.COMPANY) {
        sendCreateCardRequest({
          walletNumber: values.wallet,
          password: values.secureCardPassword,
          card_type: CARD_TYPE.VIRTUAL,
          type: accountType.toUpperCase(),
          phone: values.securePhoneNumber,
          companyName: values.cardholderName
        });
      } else {
        sendCreateCardRequest({
          walletNumber: values.wallet,
          password: values.secureCardPassword,
          card_type: CARD_TYPE.VIRTUAL,
          type: accountType.toUpperCase()
        });
      }
    }
  });

  const { values, handleSubmit, handleChange, setFieldValue, submitCount, errors } = form;

  const handleWalletChange = (name, value) => {
    setFieldValue(name, value);
  };

  return (
    <div className='virtual-card-form-wrapper'>
      <span className='virtual-card-form-title'>{i18n.getMessage('cards.creationForm.virtual.title')}</span>
      <CardExample
        accountType={accountType}
        cardType={CARD_CREATION_FORM_TYPE.VIRTUAL}
        cardholderName={accountType === ACCOUNT_TYPE.COMPANY ? values.cardholderName : userFullName}
      />
      <form className='virtual-card-form' onSubmit={handleSubmit} autoComplete='off'>
        <span className='virtual-card-form-description'>
          {i18n.getMessage('cards.creationForm.virtual.authorizedPerson')}
        </span>
        <InputDropDown
          isRequired={true}
          className='virtual-card-form-wallet-select'
          name='wallet'
          topLabel={getAvailableBalance()}
          label={i18n.getMessage('cards.creationForm.label.accountNumber')}
          value={values.wallet}
          onChange={handleWalletChange}
          options={walletsOptions}
          isMulti={false}
        />
        {accountType === ACCOUNT_TYPE.COMPANY && (
          <>
            <Input
              className='virtual-card-form-password-input'
              label={i18n.getMessage('cards.creationForm.label.cardholderName')}
              name={'cardholderName'}
              value={values.cardholderName}
              onChange={handleChange}
              initialStatus={submitCount}
              error={errors?.cardholderName || (errors && prepareFieldErrors(i18n, errors)?.cardholderName)}
              isApiError={error}
            />
            <Input
              isRequired={true}
              className='virtual-card-form-password-input'
              type={'phone'}
              label={i18n.getMessage('cards.creationForm.label.securePhoneNumber')}
              name={'securePhoneNumber'}
              value={values.securePhoneNumber}
              setValue={setFieldValue}
              onChange={handleChange}
              setCountryCode={setCountryCode}
              initialStatus={submitCount}
              error={errors?.securePhoneNumber || (errors && prepareFieldErrors(i18n, errors)?.securePhoneNumber)}
              isApiError={error}
            />
          </>
        )}
        <Input
          isRequired={true}
          className='virtual-card-form-password-input'
          type='password'
          label={i18n.getMessage('cards.creationForm.label.createSecureCardPassword')}
          name='secureCardPassword'
          value={values.secureCardPassword}
          onChange={handleChange}
          initialStatus={submitCount}
          error={errors.secureCardPassword}
        />
        <Input
          isRequired={true}
          className='virtual-card-form-password-input'
          type='password'
          label={i18n.getMessage('cards.creationForm.label.repeatSecureCardPassword')}
          name='repeatSecureCardPassword'
          value={values.repeatSecureCardPassword}
          onChange={handleChange}
          initialStatus={submitCount}
          error={errors.repeatSecureCardPassword}
        />
        {isCardCreateConfirmation && (
          <ConfirmCodeWrapper
            PhoneComponent={<strong>{getSecureUserPhone()}</strong>}
            isDisabled={isDisabled}
            error={!error?.type && error}
            onComplete={onComplete}
            showCountDown={showCountDown}
            handleResendCode={handleResendCode}
            onTimerEnd={onTimerEnd}
            alertState={alertState}
            isCardCreationForm={true}
          />
        )}
        {isCardCreateConfirmation ? (
          <Button
            className='virtual-card-form-button'
            type={'primary'}
            roleType={'button'}
            size={'large'}
            fullWidth={true}
            onClick={handleCardCreationConfirm}
          >
            {i18n.getMessage('cards.creationForm.button.confirm')}
          </Button>
        ) : (
          <Button
            className='virtual-card-form-button'
            type={'primary'}
            roleType={'submit'}
            size={'large'}
            fullWidth={true}
            onClick={() => {}}
          >
            {i18n.getMessage('cards.creationForm.button.get')}
          </Button>
        )}
      </form>
    </div>
  );
};

VirtualCardForm.propTypes = {
  accountType: PropTypes.string,
  userFullName: PropTypes.string,
  getAvailableBalance: PropTypes.func,
  walletsOptions: PropTypes.array,
  sendCreateCardRequest: PropTypes.func,
  isCardCreateConfirmation: PropTypes.bool,
  error: PropTypes.any,
  clearError: PropTypes.func,
  onConfirm: PropTypes.func,
  resendSecurityCode: PropTypes.func,
  dashboardSelectedWalletNumber: PropTypes.string
};
