import { useContext } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { getSecureUserPhone } from '../../services/authUtils';
import i18nContext from '../i18n-context';

const UserDetails = ({ userLogin, userId }) => {
  const i18n = useContext(i18nContext);
  return (
    <div className='wrapper'>
      <div className='settings-container-user'>
        <div className='profile-wrapper'>
          <div className='profile-info-wrapper left'>
            <p className='profile-header'>{i18n.getMessage('settings.title.userID')}</p>
            <p className='profile-info'>{userId}</p>
          </div>
        </div>
        <div className='profile-wrapper'>
          <div className='profile-info-wrapper left'>
            <p className='profile-header'>{i18n.getMessage('settings.title.phoneNumber')}</p>
            <p className='profile-info'>{getSecureUserPhone()}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

UserDetails.propTypes = {
  userLogin: PropTypes.string,
  userId: PropTypes.string
};

export default inject((stores) => ({
  userLogin: stores.userStore.userData?.email,
  userId: stores.userStore.userData?.user_id
}))(observer(UserDetails));
