import * as Yup from 'yup';
import i18n from 'i18n';
import { PAYMENT_PROVIDERS } from 'components/common/constants';

export const cardsPasswordRegExp = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[-!+@#$%^&*()_=[\]{};:'\\"|/,<.>?])(?=.*[0-9]).{8,36}$/;

export const getCardsPasswordValidation = () => {
  return Yup.string()
    .nullable(true)
    .min(8, i18n.getMessage('cards.error.password', { amount: '8-36' }))
    .max(36, i18n.getMessage('error.fieldSize', { amount: '36' }))
    .required(i18n.getMessage('register.error.passwordSecurityRequirementsViolation'))
    .matches(cardsPasswordRegExp, { message: i18n.getMessage('cards.error.password', { amount: '8-36' }) });
};

export const filterWallesterWallets = (wallets) => {
  return wallets.filter((wallet) => wallet.transfer_provider === PAYMENT_PROVIDERS.WALLESTER);
};

export const chunkCardNumber = (str) => {
  return str.match(/.{1,4}/g).join(' ');
};

function strToBuffer(str) {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

export async function generateRSAKeyPair() {
  const keyPair = await window.crypto.subtle.generateKey(
    {
      name: 'RSA-OAEP',
      modulusLength: 2048,
      publicExponent: new Uint8Array([0x01, 0x00, 0x01]), // 65537
      hash: { name: 'SHA-256' }
    },
    true,
    ['encrypt', 'decrypt']
  );

  function formatAsPEM(keyData, type) {
    const pemHeader = `-----BEGIN ${type}-----\n`;
    const pemFooter = `\n-----END ${type}-----`;
    const base64Body = arrayBufferToBase64(keyData);

    return pemHeader + base64Body + pemFooter;
  }

  function arrayBufferToBase64(arrayBuffer) {
    const uint8Array = new Uint8Array(arrayBuffer);
    return btoa(String.fromCharCode(...uint8Array));
  }

  const publicKeyDER = await window.crypto.subtle.exportKey('spki', keyPair.publicKey);
  const privateKeyDER = await window.crypto.subtle.exportKey('pkcs8', keyPair.privateKey);

  const publicKeyPEM = formatAsPEM(publicKeyDER, 'PUBLIC KEY');
  const publicKeyBase64 = encodeToBase64(publicKeyPEM);

  return { publicKey: publicKeyBase64, privateKey: privateKeyDER };
}

export function encodeToBase64(data) {
  return window.btoa(data);
}

export function decodeFromBase64(data) {
  return window.atob(data);
}

export function extractContentFromPEM(pemString) {
  const pattern = /-----BEGIN [^\n]*-----([\s\S]*)-----END [^\n]*-----/;
  const matches = pemString.match(pattern);

  return matches ? matches[1].trim() : pemString.trim();
}

export async function decryptCardData(encryptedData, dataType, privateKey) {
  const extractedEncryptedData = extractContentFromPEM(encryptedData);
  const decodedEncryptedData = decodeFromBase64(extractedEncryptedData);
  const bufferData = strToBuffer(decodedEncryptedData);
  const label = strToBuffer(dataType);

  const privateKeyImported = await window.crypto.subtle.importKey(
    'pkcs8',
    privateKey,
    {
      name: 'RSA-OAEP',
      hash: 'SHA-256'
    },
    true,
    ['decrypt']
  );

  const decryptedData = await window.crypto.subtle.decrypt(
    {
      name: 'RSA-OAEP',
      label
    },
    privateKeyImported,
    bufferData
  );

  return new TextDecoder().decode(decryptedData);
}

export function cutCardNumber(cardNumber) {
  return cardNumber ? cardNumber.slice(-8) : cardNumber;
}
