import PropTypes from 'prop-types';
import { classNames } from '../utils';
import './Button.scss';

/**
 * BUTTON. BY DEFAULT SHOW SMALL SIZE WITH PRIMARY TYPE
 * @param { ('primary' | 'dark' | 'tertiary' | 'light') } type
 * @param { ('submit' | 'button' | 'reset') } roleType
 * @param { boolean } isDisabled
 * @param { ('small' | 'medium' | 'large') } size
 * @param { function } onClick
 * @param { boolean } fullWidth
 * @param children
 * @param { string } className
 * @returns { JSX.Element }
 */
const Button = ({ type, roleType, isDisabled, size, onClick, fullWidth, children, className }) => {
  const buttonClasses = classNames({
    button: true,
    disabled: isDisabled,
    [type]: true,
    [size]: true,
    fullWidth,
    [className]: true
  });

  return (
    <button className={buttonClasses} type={roleType || 'button'} onClick={isDisabled ? null : onClick}>
      {children}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  roleType: PropTypes.string,
  isDisabled: PropTypes.bool,
  size: PropTypes.string,
  onClick: PropTypes.func,
  fullWidth: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string
};

export default Button;
