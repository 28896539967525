import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { ROUTE_PATHS } from 'routes/constants';
import { BackButton } from 'components/common/BackButton/BackButton';
import './Messages.scss';

const Messages = ({ userAccount, messagesStore }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    messagesStore.initializeData(userAccount?.account_number).then(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccount?.account_number]);

  useEffect(() => {
    if (id && messagesStore.isInitialized) {
      const topicIndex = messagesStore.getCurrentTopicIndexById(id);
      messagesStore.setCurrentTopicIndex(topicIndex);
      if (topicIndex === -1) {
        navigate('/404');
      } else {
        messagesStore.getCurrentTopicMessages(userAccount?.account_number, id);
      }
    } else {
      messagesStore.setCurrentTopicIndex(-1);
    }
  }, [location.pathname, messagesStore.isInitialized]);

  return (
    <section className='right-section messages'>
      {location.pathname !== ROUTE_PATHS.MESSAGES && <BackButton />}
      <Outlet />
    </section>
  );
};

Messages.propTypes = {
  userAccount: MobXPropTypes.observableObject,
  messagesStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  userAccount: stores.userStore.userData.account,
  messagesStore: stores.messagesStore
}))(observer(Messages));
