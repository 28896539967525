import { useContext } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { WALLET_PROPERTIES } from 'components/common/constants';
import i18nContext from 'components/i18n-context';
import { amountFormattedValue } from 'services/utils';
import Button from 'uikit/Button/Button';
import { Container } from 'uikit/Container/Container';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import '../../../Cards.scss';

const CardAccount = ({ cardsStore }) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();

  const handleSelectWallet = (name, value) => {
    const selectedWallet = cardsStore.wallets.find((wallet) => wallet?.wallet_number === value);
    cardsStore.setSelectedWallet(selectedWallet);
  };

  const openTopUp = () => {
    cardsStore.setTopUpWallet(cardsStore.selectedWallet);
    navigate('/between-accounts');
  };

  const accountsOptions = cardsStore?.wallets.map((wallet) => {
    return {
      key: wallet.wallet_number,
      value: wallet.wallet_number
    };
  });

  return (
    <Container
      className={'container-cards'}
      header={i18n.getMessage('cards.container.cardAccount')}
      isMaxWidthUnset={true}
    >
      <div className='card-account-wrapper'>
        <div className='account-sales-department-wrapper'>
          <span className='account-sales-department-title'>
            {i18n.getMessage('cards.cardAccount.label.accountNumber')}
          </span>
          <InputDropDown
            className='account-number-selector'
            name='card_account'
            value={cardsStore.selectedWallet?.wallet_number}
            onChange={handleSelectWallet}
            options={accountsOptions}
            isMulti={false}
          />
        </div>
        <div className='account-balance-wrapper'>
          <span className='account-balance-title'>{i18n.getMessage('cards.cardAccount.label.availableBalance')}</span>
          <div className='account-balance-data-wrapper'>
            <span className='account-balance-currency'>{cardsStore.selectedWallet[WALLET_PROPERTIES.CURRENCY]}</span>
            <span className='account-balance-value'>
              {amountFormattedValue(cardsStore.selectedWallet[WALLET_PROPERTIES.AVAILABLE])}
            </span>
          </div>
        </div>
        <Button className='top-up-button' size='large' type='outline' onClick={openTopUp}>
          {i18n.getMessage('cards.cardAccount.button.topUp')}
        </Button>
      </div>
    </Container>
  );
};

CardAccount.propTypes = {
  cardsStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  cardsStore: stores.cardsStore
}))(observer(CardAccount));
