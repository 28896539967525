import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { AuthorizationItem } from './AuthorizationItem';
import { AuthorizationItemLoader } from './AuthorizationItemLoader';
import { ReactComponent as ArrowSelect } from 'assets/arrow-select.svg';
import i18nContext from 'components/i18n-context';
import Loader from 'components/common/Loader';
import { CARD_AUTHORIZATIONS_TABLE_HEADER } from 'components/common/constants';
import { PopUpAuthorizationDetailsScheme } from 'components/common/PopUpScheme/PopUpAuthorizationDetailsScheme';
import { useMediaQuery } from 'services/useMediaQUery';
import { MEDIA_QUERY } from 'services/utils';
import { PopUp } from 'uikit/PopUp/PopUp';
import './AuthorizationsList.scss';

const sortedHeaders = {
  date: { sort_column: 'updated_at', sort_direction: '' },
  fromTo: { sort_column: 'recipient_name', sort_direction: '' },
  amount: { sort_column: 'amount', sort_direction: '' }
};

const AuthorizationsTable = ({ compact, authorizations, onChangeSort, sortData, isLoading, isComplianceRequests }) => {
  const i18n = useContext(i18nContext);
  const isMobile = useMediaQuery(MEDIA_QUERY.MOBILE);
  const [popUpState, setPopUpState] = useState({ open: false, authorization: {} });

  const showPopup = (authorization) => {
    setPopUpState({ open: true, authorization: authorization });
  };

  const closePopUp = () => {
    setPopUpState({ open: false, authorization: {} });
  };

  const sortColumn = ({ sort_column }) =>
    onChangeSort({
      sort_column,
      sort_direction: sortData.sort_direction === 'ASC' ? 'DESC' : 'ASC'
    });

  const renderPopUp = () => {
    return (
      <>
        <PopUp className='authorization-info' show={popUpState.open} onClose={closePopUp}>
          <PopUpAuthorizationDetailsScheme selectedAuthorization={popUpState.authorization} onClose={closePopUp} />
        </PopUp>
      </>
    );
  };

  if (isMobile) {
    return (
      <div className={isComplianceRequests ? 'compliance-requests-mobile-wrapper' : 'authorization-mobile-wrapper'}>
        {isLoading
          ? [...Array(7).keys()].map((loadTransaction) => (
              <AuthorizationItemLoader isMobile={isMobile} key={loadTransaction} />
            ))
          : authorizations.map((authorization) => (
              <AuthorizationItem
                isMobile={isMobile}
                key={authorization.authorization_id}
                authorization={authorization}
                onInfoClick={() => showPopup(authorization)}
              />
            ))}
        {renderPopUp()}
      </div>
    );
  }

  return (
    <>
      <div className={isComplianceRequests ? 'compliance-requests-table-wrapper' : 'authorization-table-wrapper'}>
        <table className='authorizations-wrapper'>
          <thead>
            <tr>
              {/* eslint-disable-next-line max-len */}
              {isLoading ? (
                <th colSpan={6} className={'authorization-loader-wrapper'}>
                  <Loader className={'authorization-loader'} />
                </th>
              ) : (
                CARD_AUTHORIZATIONS_TABLE_HEADER.map((header) => (
                  <th className={header} key={header}>
                    {header && i18n.getMessage(`cards.authorizations.header.${header}`)}
                    {!compact && sortedHeaders[header] && (
                      <ArrowSelect
                        onClick={() => sortColumn(sortedHeaders[header])}
                        className={`arrow-icon ${
                          sortData.sort_column === sortedHeaders[header].sort_column && sortData.sort_direction
                        }`}
                      />
                    )}
                  </th>
                ))
              )}
            </tr>
          </thead>
          <tbody>
            {isLoading
              ? [...Array(7).keys()].map((loadTransaction) => <AuthorizationItemLoader key={loadTransaction} />)
              : authorizations.map((authorization) => (
                  <AuthorizationItem
                    key={authorization.authorization_id}
                    authorization={authorization}
                    onInfoClick={() => showPopup(authorization)}
                  />
                ))}
          </tbody>
        </table>
      </div>
      {renderPopUp()}
    </>
  );
};

AuthorizationsTable.propTypes = {
  authorizations: PropTypes.array.isRequired,
  compact: PropTypes.bool,
  onChangeSort: PropTypes.func,
  sortData: PropTypes.object,
  isLoading: PropTypes.bool,
  isComplianceRequests: PropTypes.bool
};

export default AuthorizationsTable;
