import { useContext, useState } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'yup-phone-lite';
import { ReactComponent as PhoneIcon } from 'assets/phone-icon.svg';
import i18nContext from 'components/i18n-context';
import { AuthorizationBackButton } from 'components/common/AuthorizationBackButton/AuthorizationBackButton';
import { getErrorMessageForAlert, prepareFieldErrors } from 'services/utils';
import Alert from 'uikit/Alert/Alert';
import Button from 'uikit/Button/Button';
import Input from 'uikit/Input/Input';
import './PasswordRecovery.scss';
import 'react-phone-input-2/lib/style.css';

const Phone = ({ store }) => {
  const i18n = useContext(i18nContext);
  const [countryCode, setCountryCode] = useState(process.env.REACT_APP_DEFAULT_COUNTRY_CODE);

  const form = useFormik({
    validateOnChange: false,
    initialValues: {
      phone: store.generalFields.phoneNumber
    },
    initialStatus: { password: true, username: true },
    validationSchema: Yup.object({
      phone: Yup.string()
        .nullable(true)
        .phone(countryCode.toUpperCase(), i18n.getMessage('error.fieldError.phone.Phone'))
        .required(i18n.getMessage('login.error.emptyPhone'))
    }),
    onSubmit: (values) => {
      store.setPhoneNumber(values.phone);
      store.sendPhoneNumber(values.phone);
    }
  });

  const { values, errors, handleSubmit, handleChange, submitCount, setFieldValue } = form;

  const serverFieldsErrors = prepareFieldErrors(i18n, store?.error);

  return (
    <div className='password-recovery-form' id='form1'>
      <PhoneIcon className='svg-phone-icon password-recovery-icon' />
      <h1 className='password-recovery-title'>{i18n.getMessage('register.title.phone')}</h1>
      <Alert
        type={store?.error && 'warning'}
        className={'password-recovery-alert'}
        message={(store?.error && getErrorMessageForAlert(i18n, store.error)) || serverFieldsErrors.phone}
      />
      <div>
        <p className='password-recovery-description'>{i18n.getMessage('register.text.phone.description')}</p>
      </div>
      <div className='form-data'>
        <form action='#' id='send' onSubmit={handleSubmit} className='form'>
          <Input
            type={'phone'}
            label={i18n.getMessage('register.label.phone')}
            name={'phone'}
            value={values.phone}
            setValue={setFieldValue}
            onChange={handleChange}
            setCountryCode={setCountryCode}
            initialStatus={submitCount}
            isRequired={true}
            error={errors?.phone || (errors && prepareFieldErrors(i18n, errors)?.phone)}
            isApiError={!!store.error}
          />
          <Button type={'primary'} roleType={'submit'} size={'large'} fullWidth={true} onClick={() => {}}>
            {i18n.getMessage('login.btn.submit')}
          </Button>
        </form>
      </div>
      <AuthorizationBackButton type={'login'} />
    </div>
  );
};

Phone.propTypes = {
  store: MobXPropTypes.observableObject
};

export default inject('passwordRecoveryStore')(observer(Phone));
