import { useContext } from 'react';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import AmountInput from 'components/common/AmountInput';
import { MAX_UPLOADED_FILES_SIZE } from 'components/common/constants';
import Loader from 'components/common/Loader';
import { convertBytesToMegabytes, getErrorMessageForAlert } from 'services/utils';
import { AttachDoc } from 'uikit/AttachDoc/AttachDoc';
import Button from 'uikit/Button/Button';
import Input from 'uikit/Input/Input';
import { getMaxDescriptionSize } from '../utils';

const BottomPartForm = ({
  values,
  errors,
  error,
  handleChange,
  handleSubmit,
  serverFieldsErrors,
  onAmountIBANChange,
  handleGetCommission,
  showMethodCommission,
  getCommissionValue,
  isSubmitDisabled,
  isLoading,
  uploadedFiles,
  uploadDocuments,
  removePaymentFile,
  paymentProvider,
  paymentMethod,
  currency
}) => {
  const i18n = useContext(i18nContext);

  return (
    <>
      <div className='inputs-wrapper-evenly'>
        <AmountInput
          isRequired={true}
          label={i18n.getMessage('transfer.form.amount.label')}
          name={'amount'}
          value={values.amount}
          error={errors.amount || serverFieldsErrors.amount}
          onChange={onAmountIBANChange}
          onBlur={handleGetCommission}
        />
        <Input
          label={i18n.getMessage('transfer.form.commission.label')}
          isDisabled={true}
          value={values.amount && showMethodCommission ? getCommissionValue() : ''}
          onChange={handleChange}
        />
      </div>
      <div className='inputs-wrapper'>
        <Input
          isRequired={true}
          className='transfer-details'
          label={i18n.getMessage('transfer.form.transferDetails.label')}
          name={'comment'}
          value={values.comment}
          error={errors.comment || serverFieldsErrors.comment}
          onChange={handleChange}
          onBlur={handleGetCommission}
          max={getMaxDescriptionSize(paymentProvider, paymentMethod, currency)}
        />
      </div>
      <div className='inputs-wrapper-evenly'>
        <Button
          className={'send-transfer-button'}
          type={'primary'}
          roleType={'submit'}
          size={'large'}
          onClick={handleSubmit}
          isDisabled={isSubmitDisabled}
        >
          {isLoading ? <Loader /> : i18n.getMessage('sendMoney.button.send')}
        </Button>
        <AttachDoc
          label={i18n.getMessage('transfer.form.attachDoc.label')}
          className='hidden'
          type='file'
          size={MAX_UPLOADED_FILES_SIZE}
          files={uploadedFiles}
          onChange={uploadDocuments}
          onRemoveFiles={removePaymentFile}
          error={error?.type === 'attachDoc' && getErrorMessageForAlert(i18n, error)}
          hintText={i18n.getMessage('attachDoc.hint.text', {
            maxFilesSize: convertBytesToMegabytes(MAX_UPLOADED_FILES_SIZE)
          })}
        />
      </div>
    </>
  );
};

BottomPartForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  error: PropTypes.any,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  serverFieldsErrors: PropTypes.object,
  onAmountIBANChange: PropTypes.func,
  handleGetCommission: PropTypes.func,
  showMethodCommission: PropTypes.bool,
  getCommissionValue: PropTypes.func,
  isSubmitDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  uploadedFiles: PropTypes.array,
  uploadDocuments: PropTypes.func.isRequired,
  removePaymentFile: PropTypes.func,
  paymentProvider: PropTypes.string,
  paymentMethod: PropTypes.string,
  currency: PropTypes.string
};

export default BottomPartForm;
