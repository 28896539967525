import i18n from 'i18n';
import {
  CURRENCY_TYPES,
  LIMITED_PAYMENT_DESCRIPTION_SIZE,
  MAX_PAYMENT_DESCRIPTION_SIZE,
  PAYMENT_METHOD,
  PAYMENT_METHODS,
  PAYMENT_PROVIDERS
} from 'components/common/constants';

const getValuesInCorrectOrder = (arr, order) => {
  const values = new Set(arr);
  const result = [];
  order.forEach((v) => values.has(v) && result.push(v));
  return result;
};

export const filteringPaymentMethods = (paymentMethods) => {
  if (!paymentMethods) {
    return paymentMethods;
  } else {
    return PAYMENT_METHODS.filter((method) => paymentMethods.includes(method));
  }
};

export const sortingPaymentMethods = (paymentMethods, currency, exampleArrays) => {
  if (!paymentMethods || paymentMethods.length < 2) return paymentMethods;

  let exampleArr;
  if (currency === CURRENCY_TYPES.EUR) {
    exampleArr = exampleArrays.EUR;
  } else if (currency === CURRENCY_TYPES.GBP) {
    exampleArr = exampleArrays.GBP;
  } else {
    exampleArr = exampleArrays.OTHER;
  }

  return getValuesInCorrectOrder(paymentMethods, exampleArr);
};

export const moveSelectedMethodToFront = (paymentMethods, selectedMethod) => {
  return [selectedMethod, ...paymentMethods.filter((item) => item !== selectedMethod)];
};

export const getPaymentMethodsOptions = (paymentMethods) => {
  return paymentMethods.reduce((results, method) => {
    if (paymentMethods.includes(method)) {
      results.push({ key: method, value: i18n.getMessage(`transfer.paymentMethod.${method}`) });
    }

    return results;
  }, []);
};

export const filterPaymentWallets = (wallets) => {
  return wallets.filter((wallet) => wallet.transfer_provider !== PAYMENT_PROVIDERS.WALLESTER);
};

export const getMaxDescriptionSize = (paymentProvider, paymentMethod, currency) => {
  return (paymentProvider === PAYMENT_PROVIDERS.OPENPAYD && paymentMethod === PAYMENT_METHOD.SWIFT) ||
    (paymentProvider === PAYMENT_PROVIDERS.OPENPAYD && currency === CURRENCY_TYPES.GBP) ||
    (paymentProvider === PAYMENT_PROVIDERS.CLEARJUNCTION && paymentMethod === PAYMENT_METHOD.FASTER_PAYMENTS)
    ? LIMITED_PAYMENT_DESCRIPTION_SIZE
    : MAX_PAYMENT_DESCRIPTION_SIZE;
};
