import { useContext } from 'react';
import { ReactComponent as ExpiredIcon } from 'assets/expired-icon.svg';
import i18nContext from 'components/i18n-context';
import './PasswordRecovery.scss';

export const ExpiredLink = () => {
  const i18n = useContext(i18nContext);

  return (
    <div className='expired-link-wrapper'>
      <ExpiredIcon />
      <p className='expired-link-header'>{i18n.getMessage('recovery.expiredLink.header')}</p>
      <p className='expired-link-message'>{i18n.getMessage('recovery.expiredLink.message')}</p>
    </div>
  );
};
